import * as React from 'react';
import { useCallback } from 'react';
import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material';

interface AlertSnackProps extends SnackbarProps {
    severity: AlertProps['severity'];
}

const AlertSnack = ({ severity, ...props }: AlertSnackProps): JSX.Element => {
    const onAlertClose = useCallback((event: React.SyntheticEvent<Element, Event>) => {
        props.onClose && props.onClose(event, 'escapeKeyDown');
    }, [props]);

    return (
        <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            {...props}
        >
            <Alert onClose={onAlertClose} severity={severity} sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}

export default AlertSnack;