import * as React from 'react';
import { ActionFunctionArgs, LoaderFunctionArgs, redirect, useLoaderData, useNavigate } from 'react-router-dom';
import AppBarPage from '../components/AppBarPage';
import { API } from '../utils/Api';
import { Patient, Measure, Check, Client, Visit, VisitStatus, Trackable, Plan } from '../models/core';
import ListItemText from '@mui/material/ListItemText';
import { IconButton, List, ListItem, ListItemIcon, Typography, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import StraightenIcon from '@mui/icons-material/Straighten';
import { RoutesListItem } from '../components/RoutesList';
import { InfoOutlined } from '@mui/icons-material';
import VisitEditor from '../components/VisitEditor';
import useDialog from '../hooks/useDialog';
import CheckEditor from '../components/CheckEditor';
import MeasurementEditor from '../components/MeasurementEditor';

type LoaderData = [Client, Patient, Plan | null, Visit, Measure[], Check[]];

export const loader = async ({ params, request }: LoaderFunctionArgs):
    Promise<LoaderData> => {
    let clientId = params.clientId;
    if (!clientId) {
        const match = request.url.match(/clients\/(?<clientId>\w+)\//);
        clientId = match && match.groups && match.groups['clientId'] || '';
    }
    if (!clientId) {
        throw Error('Missing clientId');
    }
    if (!params.patientId) {
        throw Error('Missing patientId');
    }
    const visitId = params.visitId == 'all' ? '' : (params.visitId || '');
    const [client, patient, measures, checks] = await Promise.all([
        await API.getClient(clientId),
        await API.getPatient(clientId, params.patientId),
        await API.getMeasures(clientId, params.patientId, visitId),
        await API.getChecks(clientId, params.patientId, visitId),
    ]);
    const [visit, plan] = await Promise.all([
        await API.getVisit(clientId || '', params.patientId, visitId || [...measures.items, ...checks.items][0]?.visitId),
        patient.planId ? await API.getPlan(clientId, patient.planId) : null,
    ]);
    return [client, patient, plan || null, visit, measures.items, checks.items];
}

export const deleteMeasureAction = async ({ params }: ActionFunctionArgs): Promise<Response> => {
    if (!params.clientId) {
        throw Error('Missing clientId param');
    }
    if (!params.patientId) {
        throw Error('Missing patientId param');
    }
    if (!params.measureId) {
        throw Error('Missing measureId param');
    }
    await API.deleteMeasure(params.clientId, params.patientId, '', params.measureId);
    return redirect('../..');
}

export const deleteCheckAction = async ({ params }: ActionFunctionArgs): Promise<Response> => {
    if (!params.clientId) {
        throw Error('Missing clientId param');
    }
    if (!params.patientId) {
        throw Error('Missing patientId param');
    }
    if (!params.checkId) {
        throw Error('Missing checkId param');
    }
    await API.deleteCheck(params.clientId, params.patientId, '', params.checkId);
    return redirect('../..');
}
interface TrackableListItemProps<T> {
    item: T;
    children?: string | JSX.Element | JSX.Element[];
    isConflict?: boolean;
    onEdit?: (item: T) => void;
}

function TrackableListItem<T extends Trackable>({ item, isConflict, children, onEdit, ...props }: TrackableListItemProps<T>): JSX.Element {
    const theme = useTheme();
    const onEditClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        onEdit && onEdit(item);
        event.stopPropagation();
        event.preventDefault();
    }, [onEdit, item]);
    return <RoutesListItem
        to='#'
        sx={{
            backgroundColor: isConflict
                ? theme.palette.error.light + '40'
                : undefined
        }}
        {...props} hideMenu onClick={onEditClick}>
        {children || <></>}
    </RoutesListItem>
}

const MeasuresListItem = ({ item: measure, ...props }: TrackableListItemProps<Measure>): JSX.Element => {
    return <TrackableListItem item={measure} {...props} >
        <ListItemIcon>
            <StraightenIcon />
        </ListItemIcon>
        <ListItemText sx={{ marginRight: '40px' }}
            primary={
                <Typography component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    {measure.name}
                </Typography>
            }
        />
    </TrackableListItem>
}

const CheckListItem = ({ item: check, ...props }: TrackableListItemProps<Check>): JSX.Element => {
    return <TrackableListItem item={check} {...props} >
        <ListItemIcon>
            <CheckIcon />
        </ListItemIcon>
        <ListItemText sx={{ marginRight: '40px' }}
            primary={
                <Typography component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    {check.name}
                </Typography>
            }
        />
    </TrackableListItem>
}

interface CheckItem {
    type: 'check';
    key: string;
    value: Check;
}

interface MeasureItem {
    type: 'measure';
    key: string;
    value: Measure;
}



const MeasuresPage = (): JSX.Element => {
    const [client, patient, plan, visit, measures, checks] = useLoaderData() as LoaderData;
    const [itemToEdit, setItemToEdit] = React.useState<CheckItem | MeasureItem | null>(null);
    const { open: veOpen, show: showVE, hide: hideVE } = useDialog();
    const navigate = useNavigate();
    const hasVisit = React.useMemo(() => !!client.layout.options.visitsSheet, [client]);

    const pageTitle = React.useMemo(() =>
        hasVisit ? (
            visit.status == VisitStatus.SCHEDULED
                ? `Scheduled at ${new Date(visit.scheduled1 || visit.scheduledDate || '').toLocaleDateString()}`
                : visit.status == VisitStatus.COMPLETED
                    ? `Completed at ${new Date(visit.completed1 || visit.scheduledDate || '').toLocaleDateString()}`
                    : visit.status
                    + (patient ? ` (${patient.firstName} ${patient.lastName})` : '')
        ) : (patient ? ` (${patient.firstName} ${patient.lastName})` : ''),
        [hasVisit, patient, visit]);
    const items = React.useMemo(() => (
        new Array<CheckItem | MeasureItem>(
            ...measures.map(m => ({ type: 'measure', key: m.name, value: m } as MeasureItem)),
            ...checks.map(m => ({ type: 'check', key: m.name, value: m } as CheckItem)),
        ).sort((x, y) => x.key < y.key ? -1 : (x.key > y.key ? 1 : 0))
    ), [measures, checks]);

    const onEditCheck = React.useCallback((item: Check) => {
        setItemToEdit({ type: 'check', key: item.name, value: item })
    }, []);

    const onEditMeasure = React.useCallback((item: Measure) => {
        setItemToEdit({ type: 'measure', key: item.name, value: item })
    }, []);

    const onSave = React.useCallback(() => {
        navigate('.');
        setItemToEdit(null);
    }, [navigate]);

    const clearItemToEdit = React.useCallback(() => {
        setItemToEdit(null);
    }, []);


    return (
        <AppBarPage title={pageTitle} backDepth={hasVisit ? 2 : 4} actions={
            hasVisit
                ? <IconButton color='inherit' onClick={showVE}><InfoOutlined /></IconButton>
                : []
        }>
            <>
                <List>
                    {[...items.map((item) => (
                        item.type == 'check'
                            ? <CheckListItem key={item.value._id} item={item.value} onEdit={onEditCheck} />
                            : <MeasuresListItem key={item.value._id} item={item.value} onEdit={onEditMeasure} />
                    )),
                    ...(items.length == 0 ? [
                        <ListItem key='no-items-msg' sx={{
                            height: '102px',
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#1976d210',
                            color: '#1976d2'
                        }}>
                            There is no items in this visit
                        </ListItem>
                    ] : [])
                    ]}
                </List>
            </>
            {
                itemToEdit?.type == 'measure' ?
                    <MeasurementEditor open={true} onClose={clearItemToEdit} measure={itemToEdit.value} client={client} patient={patient} onSave={onSave} visit={visit} plan={plan} existingMeasures={measures.map(m => m.name)} />
                    : itemToEdit?.type == 'check' ?
                        <CheckEditor open={true} onClose={clearItemToEdit} check={itemToEdit.value} client={client} patient={patient} onSave={onSave} visit={visit} plan={plan} />
                        : <></>
            }
            <VisitEditor
                open={veOpen} onClose={hideVE} readOnly={true}
                visit={visit} clientId={visit.clientId} patientId={patient._id} refId={'Visit0'} />
        </AppBarPage >
    );
}

export default MeasuresPage;
