import * as React from 'react';
import { BoxProps, Box, List, ListItem, ListItemText, ToggleButton } from '@mui/material';
import WrapTextIcon from '@mui/icons-material/WrapText';

interface LogListProps extends BoxProps {
    log: string[]
}

export const Logs = ({ log, ...props }: LogListProps): JSX.Element => {
    const [wrapText, setWrapText] = React.useState(true);
    const bottomRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (bottomRef.current && log.length > 0) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [log, bottomRef])
    const toggleWrapText = React.useCallback(() => {
        setWrapText(!wrapText);
    }, [wrapText]);

    return (
        <Box sx={{ width: '720px', height: '512px', padding: '24px', position: 'relative' }} {...props}>
            <Box sx={{ overflow: 'auto', height: '100%' }}>
                <List sx={{
                    background: '#eee',
                    width: 'fit-content',
                    minHeight: '100%',
                    minWidth: '100%',
                    'li': { padding: '0 8px' },
                    '.odd': { background: '#ddd' }
                }}>
                    {log.map((msg, i) => (
                        <ListItem key={i} className={(i % 2 == 1) ? 'odd' : ''}>
                            <ListItemText
                                primary={msg}
                                primaryTypographyProps={{
                                    component: wrapText ? undefined : 'pre',
                                    sx: { fontFamily: 'monospace' }
                                }}
                            />
                        </ListItem>
                    ))}
                    <div ref={bottomRef} />
                </List>
            </Box>
            <ToggleButton
                value='wrap'
                selected={wrapText}
                onClick={toggleWrapText}
                color='primary'
                sx={{ position: 'absolute', top: '40px', right: '40px' }}>
                <WrapTextIcon />
            </ToggleButton>
        </Box>
    );
}