import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { API } from "../utils/Api";
import { Measure, MeasureComplianceStatus, Patient, Client, Plan, Visit } from "../models/core";
import { MeasurementNames } from '../utils/enums';
import enumMenuItems from '../utils/EnumMenuItems';
import { MeasureComplianceStatuses } from '../utils/enums';
import EntityEditor, { EntityEditorProps } from './EntityEditor';
import { GridFormItem } from './GridForm';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';


interface Props extends Omit<EntityEditorProps,'title'|'doSave'> {
    client: Client;
    patient: Patient;
    visit: Visit;
    measure: Measure | null;
    plan: Plan | null;
    existingMeasures: string[];
}

const MeasurementEditor = ({ client, patient, visit, measure, plan, existingMeasures, ...props }: Props): JSX.Element => {
    const measures = useMemo(() => {
        let result = patient.measures || [];
        result.length == 0 && (result = (plan && plan.measures || []));
        result.length == 0 && (result = client.measures || []);
        result.length == 0 && (result = MeasurementNames);
        return result.filter(k =>
            !existingMeasures.includes(k) || k == measure?.name
        );
    }, [patient, plan, client, existingMeasures, measure]);

    const [name, setName] = useState(measure && measure.name || '');
    const [complianceStatus, setComplianceStatus] = useState(measure && measure.complianceStatus || '');
    const [closed, setClosed] = useState(measure?.closed);
    const [errors, setErrors] = useState<{ [k in keyof Measure]?: boolean }>({});

    const onSelectChange = useCallback((event: SelectChangeEvent<string>) => {
        switch (event.target.name) {
            case 'name': setName(event.target.value); break;
            case 'complianceStatus': setComplianceStatus(event.target.value); break;
            default:
                break;
        }
    }, []);
    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'closed': setClosed(event.target.checked); break;
        }
    }, []);

    const saveMeasure = useCallback(async () => {
        const errors = {
            name: !name
        };
        setErrors(errors);
        if (Object.values(errors).reduce((acc, val) => acc || val)) {
            return false;
        }
        const data: Omit<Measure, '_id'> = {
            clientId: visit.clientId,
            patientId: visit.patientId,
            visitId: visit._id,
            name: name,
            closed: !!closed,
            complianceStatus: complianceStatus as MeasureComplianceStatus
        }
        if (measure) {
            await API.updateMeasure({ ...measure, ...data });
        }
        else {
            await API.createMeasure(data);
        }
        return true;
    }, [closed, complianceStatus, measure, name, visit._id, visit.clientId, visit.patientId]);

    return (
        <EntityEditor {...props} title={measure ? 'Edit Measure' : 'New Measure'} doSave={saveMeasure}>
            <GridFormItem xs={12}>
                <FormControl variant='standard' error={errors.name} required fullWidth>
                    <InputLabel>Measure</InputLabel>
                    <Select name='name' label='name' value={name} error={errors.name} onChange={onSelectChange} variant='standard' required>
                        {measures.map((m, i) => (
                            <MenuItem key={`item-${i}`} value={m}>{m}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridFormItem>
            <FormControl variant='standard' error={errors.complianceStatus} required fullWidth>
                <InputLabel>Compliance Status</InputLabel>
                <Select name='complianceStatus' label='complianceStatus' value={complianceStatus} error={errors.complianceStatus} onChange={onSelectChange} variant='standard' required>
                    {enumMenuItems(MeasureComplianceStatuses)}
                </Select>
            </FormControl>
            <GridFormItem xs={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControlLabel sx={{ '& span.MuiButtonBase-root': { paddingBottom: 0, paddingTop: 0 } }}
                    control={
                        <Checkbox
                            name='closed'
                            checked={!!closed}
                            onChange={onTextFieldChange} />
                    }
                    label="Closed" />
            </GridFormItem>
            <input type='hidden' name='visitId' value={measure?.visitId || ''} />
        </EntityEditor>
    )
}

export default MeasurementEditor;