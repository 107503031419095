import * as React from 'react';
import { useEffect, useState } from 'react';
import { IconButton, Link, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useDialog from '../../hooks/useDialog';

export interface EditorProps<T> {
    onChange?: (value: T | null) => void;
}

export interface SelectorProps<T> {
    value: T | null;
    onChange?: (value: T | null) => void;
}

interface MaybeEditorProps<T> {
    name: string;
    onChange?: (value: T | null) => void;
    children: React.ReactElement | React.ReactElement[];
}

export default function MaybeEditor<T>({ name, onChange, children }: MaybeEditorProps<T>): JSX.Element {
    const [selectorValue, setSelectorValue] = useState<T | null>(null);
    const [editorValue, setEditorValue] = useState<T | null>(null);

    const { show: showEditor, hide: hideEditor, open: isEditorOpen } = useDialog();

    useEffect(() => {
        onChange && onChange(selectorValue || (isEditorOpen ? editorValue : null));
        if (selectorValue) {
            hideEditor();
        }
    }, [editorValue, hideEditor, isEditorOpen, onChange, selectorValue]);

    return <>
        {React.Children.map(children, (c, i) => 
            i == 0 
                ? React.cloneElement(c, { value: selectorValue, onChange: setSelectorValue })
                : null
        )}

        <Typography variant='body2' sx={{ visibility: selectorValue == null ? 'visible' : 'hidden' }}>
            No {name} found?
            You may <Link
                component='button'
                variant='body2'
                onClick={showEditor}
            >create</Link> one
        </Typography>

        <Paper sx={{ padding: '16px', marginBottom: '16px', position: 'relative', display: isEditorOpen ? undefined : 'none' }}>
            {React.Children.map(children, (c, i) =>
                i == 1 
                    ? React.cloneElement(c, { onChange: setEditorValue }) 
                    : null
            )}
            <IconButton onClick={hideEditor} sx={{ position: 'absolute', right: '8px', top: '8px' }}><CloseIcon /></IconButton>
        </Paper>
    </>
}