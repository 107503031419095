import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


interface Props {
    open: boolean;
    onSubmit?: () => void;
    onCancel?: () => void;
}

const ConfirmDialog = ({ open, onSubmit, onCancel }: Props): JSX.Element => (
    <Dialog open={open} onClose={onCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
            Delete this item?
        </DialogContent>
        <DialogActions>
            <Button type='button' onClick={onCancel}>Cancel</Button>
            <Button onClick={onSubmit} sx={{ color: 'red' }}>Delete</Button>
        </DialogActions>
    </Dialog>
)

export default ConfirmDialog;
