import * as React from 'react';
import { Cluster, ClusterJobResult, ClusterPoint } from '../models/core';
import cluster from 'cluster';

const ClusterMap = ({ result }: { result: ClusterJobResult }): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    const infoWindow = React.useMemo(() => (
        new google.maps.InfoWindow()
    ), []);

    // Init map
    React.useEffect(() => {
        if (ref.current && !map) {
            const m = new google.maps.Map(ref.current, {
                center: { lat: 40.677, lng: -73.9511 },
                zoom: 12,
            });
            m.data.addListener("click", (event: { feature: google.maps.Data.Feature; latLng: google.maps.LatLng }) => {
                infoWindow.setContent(event.feature.getProperty('text') as string)
                infoWindow.setPosition(event.latLng);
                infoWindow.open({ map: m });
            });
            const colors = ['#4285F4', '#EA4335', '#FBBC05', '#34A853'];
            m.data.setStyle((feature: google.maps.Data.Feature) => {
                const index = feature.getProperty('index') as number;
                const color = colors[index % colors.length];
                const r = feature.getProperty('selected') ? 9 : 6;
                return {
                    strokeColor: color,
                    strokeWeight: (feature.getProperty('selected')) ? 4 : 3,
                    icon: {
                        path: `M0 -${r} a${r},${r} 0 1,0 0.01,0 z`, // a rx ry rot large-arc-flag sweep-flag dx dy
                        labelOrigin: new google.maps.Point(40, 5),
                        fillOpacity: 1,
                        fillColor: color,
                        // strokeColor: '#ffffff',
                        // strokeWeight: 2
                    },
                    fillOpacity: 0.2,
                    fillColor: color,
                    // label: {
                    //     text: new Date(feature.getProperty('text'))
                    //         .toLocaleTimeString([], { timeStyle: 'short' }),
                    //     color: 'white',
                    //     className: 'map-label map-label-color-' + (feature.getProperty('index') + 1)
                    // }
                }
            });
            setMap(m);
        }
    }, [ref, map, infoWindow]);

    const features = React.useMemo(() => {
        const wm: WeakMap<Cluster | ClusterPoint, google.maps.Data.Feature> = new WeakMap();
        result.clusters.forEach((cluster, clusterIndex) => {
            wm.set(cluster, new google.maps.Data.Feature({
                geometry: new google.maps.Data.Polygon(
                    [cluster.borderPoints.map(i => ({
                        lat: cluster.points[i].latitude || 0,
                        lng: cluster.points[i].longitude || 0
                    }))]
                ),
                properties: {
                    type: 'cluster',
                    index: clusterIndex,
                    obj: cluster,
                    text: `${cluster.points.length} points on ${cluster.radius.value?.toFixed(2)}${cluster.radius.units}`
                }
            }));
            cluster.points.forEach((pt) => {
                wm.set(pt, new google.maps.Data.Feature({
                    geometry: new google.maps.Data.Point({
                        lat: pt.latitude || 0,
                        lng: pt.longitude || 0
                    }),
                    properties: {
                        type: 'point',
                        index: clusterIndex,
                        obj: pt,
                        text: pt.address
                    }
                }));
            })
        });
        return wm;
    }, [result]);

    // Add features to the map
    React.useEffect(() => {
        map && map.data && map.data.forEach((feature) => {
            map.data.remove(feature);
        });
        map && result.clusters.forEach(cluster => {
            map.data.add(features.get(cluster));
            cluster.points.forEach((pt) => {
                map.data.add(features.get(pt));
            })
        });
        map?.fitBounds({
            west: Math.min(...result.clusters.flatMap(({ points }) => points).map(pt => pt.longitude || 0)),
            east: Math.max(...result.clusters.flatMap(({ points }) => points).map(pt => pt.longitude || 0)),
            north: Math.max(...result.clusters.flatMap(({ points }) => points).map(pt => pt.latitude || 0)),
            south: Math.min(...result.clusters.flatMap(({ points }) => points).map(pt => pt.latitude || 0)),
        })
    }, [features, map, result]);

    return (
        <div style={{ minHeight: '640px', width: '100%' }} ref={ref} className="map-container" />
    );
}

export default ClusterMap;