import * as React from 'react';
import { Container, CssBaseline, Paper } from '@mui/material';
import 'react-phone-number-input/style.css'
import { Outlet } from 'react-router-dom';


const LoginPage = (): JSX.Element => (
    <React.Fragment>
        <CssBaseline />
        <Container sx={{ maxWidth: '10000px !important', height: '100vh', background: '#eee', display: 'flex' }}>
            <Paper sx={{ width: '400px', height: '330px', margin: 'auto', padding: 4 }}>
                <Outlet />
            </Paper>
        </Container>
    </React.Fragment>
)

export default LoginPage;