import * as React from 'react';
import { Box, BoxProps, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, PaperProps, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { Job, JobStatus } from '../../models/core';
import { DateTime } from 'luxon';
import MessagesBox, { Message } from './MessagesBox';
import { InfoButton } from '../InfoButton';
import { useTheme } from '@mui/material';
import { JobWithMessages } from '../../hooks/useJobsList';

interface JobsListProps<T> extends BoxProps {
    jobs: JobWithMessages<T>[];
    selectedJob: T | null;
    isLoading?: boolean;
    isJobSaving?: boolean;
    onSelectJob?: (job: JobWithMessages<T>) => void;
}

const JobsList = <T extends Job>({ jobs, selectedJob, isLoading, isJobSaving, onSelectJob, ...props }: JobsListProps<T>): JSX.Element => {
    const theme = useTheme();
    const StatusIcons = React.useMemo(() => ({
        [JobStatus.NEW]: <DescriptionOutlinedIcon fontSize='large' titleAccess='New' />,
        [JobStatus.IN_PROGRESS]: <QueryBuilderOutlinedIcon fontSize='large' titleAccess='In Progress' />,
        [JobStatus.TERMINATING]: <HistoryToggleOffOutlinedIcon fontSize='large' titleAccess='Teminating...' />,
        [JobStatus.SUCCESS]: <CheckCircleOutlineIcon fontSize='large' titleAccess='Done' />,
        [JobStatus.FAIL]: <ErrorOutlineIcon fontSize='large' titleAccess='Failed' />
    }), []);

    const onItemClick = React.useCallback((event: React.MouseEvent<HTMLLIElement>) => {
        const attr = event.currentTarget.attributes.getNamedItem('x-index');
        attr && onSelectJob && onSelectJob(jobs[Number.parseInt(attr.value)]);
    }, [jobs, onSelectJob]);
    return (
        <Box {...props} position='relative'>
            <List>
                {jobs.map((job, i) => (
                    <ListItem key={i} x-index={i} disablePadding onClick={onItemClick} sx={{ position: 'relative', width: '100%' }}>
                        <ListItemButton selected={job._id == selectedJob?._id}>
                            <ListItemIcon>
                                {job.status != JobStatus.IN_PROGRESS ? StatusIcons[job.status] : null}
                                <Box sx={{ display: job?.status == JobStatus.IN_PROGRESS ? undefined : 'none', position: 'relative' }}>
                                    <CircularProgress variant='determinate' value={100} sx={{ color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800] }} />
                                    <CircularProgress variant='determinate' value={job?.progress || 0} sx={{ position: 'absolute', left: 0 }} />
                                    <Box sx={{
                                        top: 0, left: 0, height: '40px', right: 0,
                                        position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                    }}
                                    >
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="text.secondary"
                                        >{`${Math.round(job?.progress || 0)}%`}</Typography>
                                    </Box>


                                </Box>
                            </ListItemIcon>
                            <ListItemText primary={job.name} secondary={job.updated && DateTime.fromISO(job.updated).toLocaleString(DateTime.DATETIME_SHORT) || ''} />
                            <ListItemSecondaryAction>
                                {job.messages.filter(m => m.severity == 'error').length > 0
                                    ? <InfoButton sx={{ cursor: 'help', color: theme.palette.error.light }}>
                                        <MessagesBox messages={job.messages} />
                                    </InfoButton>
                                    : null
                                }
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        {isJobSaving && job._id == selectedJob?._id
                            ? <Box sx={{
                                position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'left',
                                background: '#ffffff80',
                                paddingLeft: '16px'
                            }}>
                                <CircularProgress />
                            </Box>
                            : null}
                    </ListItem>
                ))}
            {jobs.length == 0
                ? <ListItem>
                    <ListItemText primary='No jobs' sx={{textAlign: 'center'}} />
                </ListItem>
                : null
            }
            </List>
            {isLoading
                ? <Box sx={{
                    position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    background: '#ffffff80'
                }}>
                    <CircularProgress />
                </Box>
                : null}
        </Box>
    )
}

export default JobsList;