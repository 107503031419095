import mixpanel from 'mixpanel-browser'
import { ZipAnalyticModule } from '../zipAnalytics.interfaces'

export class MixpanelZipAnalyticsBrowser implements ZipAnalyticModule {
  private readonly EVENT_NAV = 'transition'
  private readonly EVENT_ACTION: string = 'action'
  private readonly EVENT_ACTION_COUNT: string = 'action-count'
  private readonly MAX_CACHED_EVENTS = 20
  private readonly MIN_BATCH_SIZE = 4
  private readonly BATCH_TIME_MS = 120000

  private initialized: boolean
  private navPage: string
  private event_list: Array<{ [key: string]: string | number | boolean | string[] }>
  private apiToken: string
  private apiConfig: { [key: string]: string | number | boolean }
  private lastEventsTime: number

  private stateList: Array<string>
  private stateDeltaList: Array<number>

  private lastEventName:string
  private lastEventSent:{ [key: string]: string | number | boolean | string[] }
  private lastEventRepeats: number

  constructor(token: string, config: { [key: string]: string | number | boolean }) {
    this.apiToken = token
    this.apiConfig = config
    this.navPage = ''
    this.initialized = false
    this.lastEventsTime = Date.now()
    this.event_list = []
    this.stateList = []
    this.stateDeltaList = []
    this.lastEventName = ''
    this.lastEventSent = {}
    this.lastEventRepeats = 0
  }

  register(): void {
    if (!this.initialized && this.apiToken) {
      mixpanel?.init(this.apiToken, this.apiConfig)
      this.initialized = true
    }
  }

  track(
    event: string,
    event_data: { [key: string]: string | number | boolean | string[] },
    priority = false,
  ): void {
    if (this.initialized) {
      if (!event_data) {
        event_data = {}
      }
      if (priority) {
        event_data.current = this.navPage ? this.navPage : ''
        mixpanel?.track(event, event_data)
      }
      if (this.navPage && !event_data.current) {
        event_data.c = this.navPage
      }

      let eventsMatch:boolean = this.lastEventName === event
      if(eventsMatch) {
        for (const key in this.lastEventSent) {
          if(key !== 'ev' && key !== 'ts') {
            if(event_data[key] !== this.lastEventSent[key]) {
              eventsMatch = false
              break
            }
          }
        }
        if(eventsMatch) {
          this.lastEventRepeats += 1
          return
        }
      }
      if(!eventsMatch && this.lastEventRepeats != 0) {
        this.lastEventSent.ev = this.lastEventName
        this.lastEventSent.ts = new Date().getTime()
        this.lastEventSent.rp = this.lastEventRepeats
        this.event_list.push(this.lastEventSent)
        this.pushAnalytics(false)
        this.lastEventRepeats = 0
      }
      this.lastEventName = event
      this.lastEventSent = event_data

      event_data.ev = event
      event_data.ts = new Date().getTime()
      this.event_list.push(event_data)
      this.pushAnalytics(false)
    }
  }

  startSession(id: string, profile: { [key: string]: string | number | boolean }): void {
    if (this.initialized) {
      mixpanel?.identify(id)
      this.track('session', profile, true)
      if (profile) {
        mixpanel?.people.set(profile)
        mixpanel?.register({
          auth: id,
        })
      }
    }
  }

  endSession(): void {
    if (this.initialized) {
      this.track('session-end', {}, true)
      mixpanel?.reset()
    }
  }

  error(
    event: string | Error,
    data: { [key: string]: string | number | boolean },
    priority = false,
  ): void {
    if (!data) {
      data = {}
    }
    data.error = event.toString()
    this.track('error', data, priority)
  }

  breadcrumb(event: string, data: { [key: string]: string | number | boolean }): void {
    this.track(event, data)
  }

  flush(): void {
    this.pushAnalytics(true)
  }

  nav(prevPage: string, newPage: string, deltaTime: number): void {
    this.stateList.push(prevPage || 'load')
    this.stateDeltaList.push(deltaTime)
    this.track(this.EVENT_NAV, {
      current: newPage,
      previous: prevPage,
      dt: deltaTime,
    })
    this.navPage = newPage
  }

  private pushAnalytics(force: boolean): void {
    if (!this.initialized) {
      return
    }
    const time = Date.now()
    const size = this.event_list.length
    if (
      (size < this.MAX_CACHED_EVENTS &&
        !force &&
        time - this.lastEventsTime < this.BATCH_TIME_MS) ||
      size < this.MIN_BATCH_SIZE
    ) {
      return
    }
    this.lastEventsTime = time

    const ev: { [key: string]: string | number | boolean | string[] | number[] } = {
      flush: force,
    }
    ev[this.EVENT_ACTION_COUNT] = size
    ev['states'] = this.stateList
    ev['state-times'] = this.stateDeltaList
    let count = 0
    for (let i = 0; i < size; i++) {
      const ev_str = JSON.stringify(this.event_list[i])
      if (ev_str.length <= 250) {
        ev[this.EVENT_ACTION + '-' + count++] = ev_str
      }
    }
    mixpanel?.track(this.EVENT_ACTION, ev)
    this.event_list = []
    this.stateList = []
    this.stateDeltaList = []
  }
}
