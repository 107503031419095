import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback, useState } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import { AxiosError } from 'axios';
import QueryButton from '../QueryButton';


const defaultCode = [
    "// export function run(",
    "//    sheets: { title: string; items: { [k: string]: string; }[]; }[]",
    "//): ScriptResult {",
    "const sheet = sheets.find(s => s.title == 'Master');",
    "const rows = sheet && sheet.items.filter(obj => !!obj['Member First name']) || [];",
    "return { ",
    "  patients: rows.map(obj => ({",
    "    refId: obj['MemberID'],",
    "    firstName: obj['Member First name'],",
    "    lastName: obj['Member Last name']",
    "  })), ",
    "  measures: [], ",
    "  checks: [] };",
    "//}    ",
].join('\n')


interface Props {
    initCode: string;
    open: boolean;
    onTest: (code: string) => Promise<string>;
    onSave: (code: string) => void;
}


const ScriptEditor = ({ onTest, initCode, open, onSave, ...props }: Props): JSX.Element => {
    const [code, setCode] = useState(initCode || defaultCode);
    const [testOutput, setTestOutput] = useState('');

    const onSubmitClick = useCallback(() => {
        onSave && onSave(code);
    }, [code, onSave]);

    const cbHighlight = useCallback((value: string) => {
        return highlight(value, languages.js, 'js');
    }, []);

    const onTestClick = useCallback(async () => {
        try {
            setTestOutput(await onTest(code));
        }
        catch (error) {
            if (error instanceof AxiosError) {
                setTestOutput(JSON.stringify(error.response && error.response?.data, null, 2))
            }
        }
    }, [code, onTest]);

    return (
        <Dialog open={open} maxWidth={false} onClose={onSubmitClick} {...props}>
            <DialogTitle>Edit Script</DialogTitle>
            <DialogContent>
                <Box maxHeight={480} overflow='auto' fontSize='small' minWidth={640}>
                    <Editor
                        value={code}
                        name='importFunctionCode'
                        onValueChange={setCode}
                        highlight={cbHighlight}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 12,
                            // width: '800px'
                        }}
                    />
                </Box>
                <Box sx={{ height: '16px' }}>&nbsp;</Box>
                <Box height={240} overflow='auto' fontSize='small' sx={{ background: '#e0e0e0' }}>
                    <pre>{testOutput || 'Test output will be here...'}</pre>
                </Box>
            </DialogContent>
            <DialogActions>
                <QueryButton doFetch={onTestClick} showSnack={false}>Test</QueryButton>
                <Box flexGrow={1} />
                <Button onClick={onSubmitClick}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ScriptEditor;
