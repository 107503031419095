import * as React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { analytics } from '../../utils/analytics/zipAnalytics';

const WrongRoleFragment = (): JSX.Element => {
    analytics.error('wrong_role', {}, true);
    return (
        <Stack spacing={1} sx={{}}>
            <Typography variant='h3' sx={{ mb: 2 }}>Oops...</Typography>
            <Typography sx={{ mb: 2 }}>
                You were succesfully logged in but something looks wrong
                with your role.
                You need either admin role or practice admin for practice
                with a data.
                Please contact administrator or try login on another account.
            </Typography>
            <Link to='../login' style={{marginTop: '16px'}}>
                <Button variant='contained' fullWidth>Try again</Button>
            </Link>
        </Stack>
    )
}

export default WrongRoleFragment;