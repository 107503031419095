import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Box, BoxProps, Button, CircularProgress, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Job, JobStatus } from '../../models/core';
import { DateTime } from 'luxon';

interface JobControlBoxProps extends BoxProps {
    job: Job & {result?: unknown};
    runEnabled: boolean;
    onRunClick?: () => void;
    onStopClick?: () => void;
    onResultClick?: () => void;
    onRename?: (name: string) => void;
}

const JobControlBox = ({ job, runEnabled, onRunClick, onStopClick, onResultClick, onRename }: JobControlBoxProps): JSX.Element => {
    const [jobName, setJobName] = useState(job.name);
    const theme = useTheme();
    const statusColor = React.useMemo(() => (
        job.status == JobStatus.SUCCESS ? theme.palette.success.main
            : job.status == JobStatus.FAIL ? theme.palette.error.main
                : theme.palette.info.main
    ), [job, theme]);
    const updated = React.useMemo(() => (
        job.updated && DateTime.fromISO(job.updated).toLocaleString(DateTime.DATETIME_SHORT) || ''
    ), [job]);
    const onJobNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setJobName(event.target.value);
    }, []);

    const onJobNameBlur = useCallback(() => {
        onRename && (jobName!=job.name) && onRename(jobName);
    }, [onRename, jobName, job]);

    useEffect(() => {
        setJobName(job.name);
    }, [job]);

    return <Stack direction='row' justifyContent='space-between' alignItems='center' padding='8px 16px'>
        <TextField 
            value={jobName} 
            onChange={onJobNameChange} 
            onBlur={onJobNameBlur}
            variant='standard' 
            sx={{width: '240px'}}
            InputProps={{sx: {
                fontSize: '1.5rem',
                ':before': {
                    borderBottomColor: 'transparent'
                }
            }}} 
        />
        <Typography variant='body2'>
            {
                job.status == JobStatus.NEW ? `Created at ${updated}`
                : job.status == JobStatus.IN_PROGRESS ? 'In progress...'
                : job.status == JobStatus.TERMINATING ? `Terminating`
                : job.status == JobStatus.SUCCESS ? `Done at ${updated}`
                :  `Failed at ${updated}`
            }
        </Typography>
        <Box flexGrow={1} />
        <Button onClick={onResultClick} sx={{ visibility: (job.status==JobStatus.SUCCESS)&&job.result ? 'visible' : 'hidden' }}>Result</Button>
        {job.status == JobStatus.IN_PROGRESS || job.status == JobStatus.TERMINATING
            ? <Button onClick={onStopClick} disabled={job.status==JobStatus.TERMINATING}>Stop</Button>
            : <Button onClick={onRunClick} disabled={!runEnabled}>Run</Button>
        }
    </Stack>
}

export default JobControlBox;