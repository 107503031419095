import * as React from 'react';
import { useMemo } from 'react';
import { FilterField, FilterValue } from '../FiltersPanel';
import { Message } from './MessagesBox';
import { Provider } from '../../models/core';
import { ListItemIcon, Avatar, ListItemText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SelectorTab from './SelectorTab';
import { CheckListItem } from './TransferList';
import { API as ZiphyAPI } from '../../utils/ZiphyAPI';

interface ProvidersTabProps {
    practiceId: number;
    messages?: Message[];
    value: Provider[];
    onChange?: (value: Provider[]) => void;
}

const ProvidersTab = ({ practiceId, messages, value, onChange }: ProvidersTabProps): JSX.Element => {
    const fields = useMemo<FilterField[]>(() => [
        {
            name: 'name', type: 'string', placeholder: 'Name', width: 7
        },
        {
            name: 'gender', type: 'enum',
            items: [['male', 'Male'], ['female', 'Female']],
            emptyLabel: 'Any Gender', width: 3
        },
    ], []);
    const defaultValue = useMemo(() => ({ name: '', gender: ['all'] }), []);

    const itemFn = React.useCallback((item: Provider) => (
        <CheckListItem>
            <ListItemIcon>
                <Avatar>
                    <PersonIcon />
                </Avatar>
            </ListItemIcon>
            <ListItemText primary={item.name} />
        </CheckListItem>
    ), []);

    const onFetch = React.useCallback(async (filter: FilterValue): Promise<Provider[]> => {
            return (await ZiphyAPI.getRoles({
                and: [
                    { eq: ['practice_id', practiceId] },
                    { eq: ['is_active', true] },
                    { eq: ['role', 'provider'] },
                    ...(!filter['name'].includes('all')
                        ? [{ imatch: ['name', filter['name'] as string] as [string, unknown] }]
                        : [])
                ]
            })).roles.items.map<Provider>(role => ({
                id: role.id + '',
                name: role.first_name + ' ' + role.last_name,
            }));
    }, [practiceId]);

    const eqFn = React.useCallback((x: Provider, y: Provider) => (x.id == y.id), []);

    return <>
        <SelectorTab
            choosenItems={value}
            id='providers'
            {...{ fields, defaultValue, messages, onFetch, itemFn, eqFn, onChange }}
        />
    </>
}

export default ProvidersTab;