import * as React from 'react';
import { useCallback } from 'react';
import { Agent, Client, Plan, Provider, ScheduleJob, ScheduleJobParams } from '../../models/core';
import AutoTabs, { AutoTab } from './AutoTabs';
import SettingsTab from './SettingsTab';
import ProvidersTab from './ProvidersTab';
import AgentsTab from './AgentsTab';
import PatientsTab from './PatientsTab';
import { Logs } from './Logs';
import { JobWithMessages } from '../../hooks/useJobsList';


interface JobTabsProps {
    client: Client;
    plans: Plan[];
    job: (JobWithMessages<ScheduleJob>);
    onChange?: (job: JobWithMessages<ScheduleJob>) => void;
}

const JobTabs = ({ client, plans, job, onChange }: JobTabsProps): JSX.Element => {
    const onSettingsChange = useCallback((value: ScheduleJobParams) => {
        job && onChange && onChange({ ...job, params: value });
    }, [job, onChange]);

    const onAgentsChange = useCallback((value: Agent[]) => {
        job && onChange && onChange({ ...job, params: { ...job.params, agents: value } });
    }, [job, onChange]);

    const onProvidersChange = useCallback((value: Provider[]) => {
        job && onChange && onChange({ ...job, params: { ...job.params, providers: value } });
    }, [job, onChange]);

    const onPatientsChange = useCallback((value: ScheduleJobParams['patientsFilter']) => {
        job && onChange && onChange({ ...job, params: { ...job.params, patientsFilter: value } });
    }, [job, onChange]);

    return job && (
        <AutoTabs>
            <AutoTab label='Settings'>
                <SettingsTab
                    value={job.params}
                    messages={job.messages}
                    onChange={onSettingsChange}
                />
            </AutoTab>
            <AutoTab label='Patients'>
                <PatientsTab
                    value={job.params.patientsFilter}
                    onChange={onPatientsChange}
                    messages={job.messages.filter(m => m.subject == 'patients')}
                    client={client}
                    plans={plans}
                />
            </AutoTab>
            <AutoTab label='OCC'>
                <AgentsTab
                    practiceId={client.practiceId}
                    messages={job.messages.filter(m => m.subject == 'agents')}
                    value={job.params.agents}
                    onChange={onAgentsChange}
                />
            </AutoTab>
            <AutoTab label='Providers'>
                <ProvidersTab
                    practiceId={client.practiceId}
                    messages={job.messages.filter(m => m.subject == 'providers')}
                    value={job.params.providers}
                    onChange={onProvidersChange}
                />
            </AutoTab>
            <AutoTab label='Log'>
                <Logs log={job.log || []} />
            </AutoTab>
        </AutoTabs>
    ) || <></>
}

export default JobTabs;