import React from 'react';
import { useLoading } from '../hooks/useLoading';
import { Dialog, DialogContent, CircularProgress, Box,DialogProps } from '@mui/material';

const LoadingDialog = () => {
  const { isLoading } = useLoading();

  return (
    <Dialog open={isLoading}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress size={50} />
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default LoadingDialog;