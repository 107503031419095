import * as React from 'react';
import { useMemo, useCallback, useState } from 'react';
import { DataGrid, DataGridProps, GridCellParams } from '@mui/x-data-grid';
import { PatientVisit, Client } from '../../models/core';
import { useAuth } from '../../hooks/useAuth';
import { DateTime } from 'luxon';
import AssignmentDialog from '../AssignmentDialog'
import { API } from '../../utils/Api';
import { analytics } from '../../utils/analytics/zipAnalytics';

interface PatientsTableProps extends Omit<DataGridProps, 'rows'|'columns'> {
    patients: PatientVisit[];
    client?: Client;
    refresh?:()=>Promise<void>;
}

const PatientsTable = ({ patients, client, refresh, ...props }: PatientsTableProps): JSX.Element => {

    const [assignPatient, setAssignPatient] = useState<PatientVisit|null|undefined>(null);

    const assignmentRenderCell = useCallback((params: GridCellParams) => {
        const handleButtonClick = () => {
            const patient = patients.find(p => p.refId === params.row.id);
            setAssignPatient(patient);
        };
        const assignedName = patients.find(p => p.refId === params.row.id)?.assignment?.assignedTo;

        return (
        <button onClick={handleButtonClick}>
            { assignedName }
        </button>
        );
    },[patients]);
    
    const columns = useMemo(() => [
        {field: 'id', headerName: 'ID', width: 150},
        {field: 'lastName', headerName: 'Last Name', width: 150},
        {field: 'firstName', headerName: 'First Name', width: 150},
        {field: 'gender', headerName: 'Gender', width: 75},
        {field: 'dob', headerName: 'DoB', width: 100},
        {field: 'address', headerName: 'Address', width: 250},
        {field: 'status', headerName: 'Status', width: 100},
        {field: 'assignement', headerName: 'Assignment', width: 150, 
            renderCell: assignmentRenderCell
        },
    ], [assignmentRenderCell])

    const auth = useAuth();
    
    const assignmentDialog = useMemo(() => {
        const onHandleAssignUser = async (value:string) => {

            if(assignPatient == null) {
                console.error('Patient not set for assignment');
                return;
            }

            const ts = DateTime.now().toString();
            const assigner = auth?.user?.name || 'NA';
            const prevAssignment = assignPatient?.assignment?.assignedTo || 'None';
            analytics.track('assign', { type:'single', patient:assignPatient._id, to:value, by: assigner, date:ts, from:prevAssignment }, true);

            await API.updatePatient({ ...assignPatient, ...
                { assignment: { assignedDate: ts, assignedTo: value, assignedBy: assigner } }
            });

            refresh && refresh();
            setAssignPatient(null);
        };

        return (
            (assignPatient && client) ? 
                <AssignmentDialog open={true} showIfBusy={true} client={client} 
                    defaultValue={ auth?.user?.name || undefined } onAssign={onHandleAssignUser} onClose={() => setAssignPatient(null)}/>
                : <></>
        );
    },[assignPatient, client, refresh, auth?.user?.name]);

    const rows = useMemo(() => (
        patients.map(p => ({
            id: p.refId, lastName: p.lastName, firstName: p.firstName, gender: p.gender, 
            dob: p.dob, address: p.address+','+p.city, status: p.visit?.status, 
            assignment: p.assignment?.assignedTo
        }))
    ), [patients]);
    return (
        <>
        <DataGrid {...{rows, columns}} density='compact' rowSelection={false} {...props}/>
        { assignmentDialog }
        </>
    );
}

export default PatientsTable;