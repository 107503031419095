import * as React from 'react';
import { Box, Button, Chip, Collapse, Grid, GridOwnProps, IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SpinnerButton from './SpinnerButton';
import { AutoCloseEnumField, AutocompleteEnumField, DateField, TextF } from './fields';
import debounce from 'lodash.debounce';
import { useCalendarState } from '@mui/x-date-pickers/internals';

export interface BaseField {
    // type: string;
    name: string;
    disabled?: boolean;
    width?: number;
}
interface SearchField extends BaseField {
    type: 'search';
    name: 'search';
    placeholder: string;
}

interface TextFilterField extends BaseField {
    type: 'string';
    placeholder: string;
}

interface EnumFilterField extends BaseField {
    type: 'enum';
    forceSingle?: boolean,
    items: (string | [string, string])[];
    emptyLabel: string;
}

interface AutocompleteFilterField extends BaseField {
    type: 'autocomplete';
    items: string[];
    emptyLabel: string;
}

interface DateField extends BaseField {
    type: 'date';
    placeholder: string;
}

const MaybeCollapse = ({ collapsable, open, children }: { collapsable:boolean, open: boolean, children: React.ReactNode }): JSX.Element => {
    return collapsable
        ? <Collapse in={open} timeout='auto'>{children}</Collapse>
        : <>{children}</>
}

export type FilterField = TextFilterField | EnumFilterField | SearchField | AutocompleteFilterField | DateField;

export interface FilterValue {
    [k: string]: (string | string[]);
}

export interface FiltersPanelProps extends GridOwnProps {
    fields: FilterField[];
    defaultValue: FilterValue;
    value?: FilterValue;
    direction?: 'row' | 'column';
    isBusy?:boolean,
    collapsable?:boolean;
    onChange?: (value: FilterValue) => void;
    onApply?: () => void;
}

const FiltersPanel = ({ fields, value: extValue, defaultValue, direction, isBusy, collapsable=false, onChange, onApply, ...props }: FiltersPanelProps): JSX.Element => {
    const [value, setValue] = React.useState<FilterValue>(extValue || defaultValue);

    React.useEffect(() => {
        extValue && setValue(extValue);
    }, [extValue]);

    const [open, setOpen] = React.useState(fields.filter(f => f.type == 'search').length > 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetch = React.useCallback(debounce((value: FilterValue) => {
        onChange && onChange(value);
    }, 1000), [value]);

    const onStringChange = React.useCallback((label: string, newValue: string | null) => {
        setValue({ ...value, [label]: newValue || '' });
        fetch({ ...value, [label]: newValue || '' });
    }, [fetch, value]);

    const onEnumChange = React.useCallback((label: string, newValue: string[] | null) => {
        if (!value[label].includes('all') && (newValue || []).includes('all')) {
            newValue = ['all'];
        }
        else {
            newValue = newValue?.filter(v => v != 'all') || [];
        }
        setValue({ ...value, [label]: newValue || [] });
        fetch({ ...value, [label]: newValue || [] });
    }, [fetch, value]);

    const onEnumSingleChange = React.useCallback((label: string, newValue: string[] | null) => {
        let workingValue = (newValue && ((typeof newValue == 'string') ? [newValue] : newValue)) || [];
        if (!value[label].includes('all') && workingValue.includes('all')) {
            workingValue = ['all'];
        }
        setValue({ ...value, [label]: workingValue });
        fetch({ ...value, [label]: workingValue });
    }, [fetch, value]);

    const onDateChange = React.useCallback((label: string, newValue: Date | null) => {
        setValue({ ...value, [label]: newValue?.toISOString() || '' });
        fetch({ ...value, [label]: newValue?.toISOString() || '' });
    }, [fetch, value]);

    const onSearchChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({ ...value, search: event.target.value });
        fetch({ ...value, search: event.target.value });
    }, [fetch, value]);

    const dateValue = React.useMemo(() => (
        Object.fromEntries(
            fields.filter(f => f.type == 'date')
                .map(f => [f.name, new Date(value[f.name] as string)])
        )
    ), [fields, value]);

    const enumLabels = React.useMemo(() => (
        Object.fromEntries((fields.filter(f => f.type == 'enum') as EnumFilterField[]).map(f => (
            [f.name, Object.fromEntries(f.items.map(item => (
                item instanceof Array
                    ? item
                    : [item, item]
            )))]
        )))
    ), [fields])

    const onClearSearchClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        let e: HTMLElement | null = event.currentTarget;
        while (e && !e.attributes.getNamedItem('x-field')) {
            e = e.parentElement;
        }
        const idAttr = e && e.attributes.getNamedItem('x-field');
        if (idAttr) {
            setValue({ ...value, [idAttr.value]: '' });
            fetch({ ...value, [idAttr.value]: '' });
        }
    }, [fetch, value]);

    const onClearClick = React.useCallback(() => {
        const empty = Object.fromEntries(fields.map(f => [
            f.name,
            f.type == 'enum' ? ['all']
                : f.type == 'autocomplete' ? []
                    : ''
        ]));
        setValue(empty);
        onChange && onChange(empty);
    }, [fields, onChange]);

    const onApplyClick = React.useCallback(() => {
        onApply && onApply();
    }, [onApply]);

    const toggleOpen = React.useCallback(() => {
        setOpen(!open);
    }, [open]);

    return <Grid container padding='8px 32px 0 32px' sx={{
        '.MuiInputBase-root': {
            minHeight: '41px' // for chips
        },
    }} {...props}>
        {fields
            .filter<SearchField>((f => f.type == 'search') as (f: FilterField) => f is SearchField)
            .slice(0, 1)
            .map((f) => (
                <Grid item xs={12} sx={{ display: 'flex' }} key='search'>
                    <TextField
                        name={f.name}
                        value={value[f.name]}
                        onChange={onSearchChange}
                        variant='standard'
                        hiddenLabel
                        placeholder={f.placeholder}
                        fullWidth
                        disabled={f.disabled}
                        InputProps={{
                            endAdornment: value.search ? <InputAdornment position='end' >
                                <IconButton x-field={f.name} onClick={onClearSearchClick}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment> : undefined
                        }}
                    />
                    <IconButton onClick={toggleOpen} title={open ? 'Search Only' : 'Show Filters'}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
                </Grid>

            ))}

        <MaybeCollapse collapsable={collapsable} open={open}>
            <Grid container sx={{
                alignItems: direction == 'row' ? 'flex-end' : undefined,
                '.MuiInputBase-root': {
                    minHeight: '41px' // for chips
                },
            }} {...props}>

                {fields.filter(f => f.type != 'search').map((f, i) => (
                    <Grid item key={`${i}`} xs={f.width || 6} paddingRight={'16px'}>{
                        f.type == 'autocomplete'
                            ? <AutocompleteEnumField
                                name={f.name}
                                value={value[f.name] as string[]}
                                options={f.items}
                                hiddenLabel
                                onChange={onEnumChange}
                            />
                            : f.type == 'enum'
                                ? <AutoCloseEnumField multiple={!f.forceSingle} name={f.name} value={(value[f.name] as string[]) || ['all']}
                                    options={[['all', f.emptyLabel], ...(f.items || [])]}
                                    valueToClose='all'
                                    hiddenLabel
                                    disabled={f.disabled}
                                    onChange={f.forceSingle ? onEnumSingleChange : onEnumChange}
                                    renderValue={(selected:any) => (
                                        selected.includes('all')
                                            ? f.emptyLabel
                                            : selected.map((value:any) => enumLabels[f.name][value]).join(', ')
                                    )} />
                                : f.type == 'string'
                                    ? <TextF
                                        name={f.name} label={f.placeholder} value={value[f.name] as string}
                                        onChange={onStringChange}
                                        variant='standard'
                                        hiddenLabel
                                        placeholder={f.placeholder}
                                        fullWidth
                                        disabled={f.disabled}
                                        InputProps={{
                                            endAdornment: value[f.name] ? <InputAdornment position='end' >
                                                <IconButton x-field={f.name} onClick={onClearSearchClick}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment> : undefined
                                        }}
                                    />
                                    : f.type == 'date'
                                        ? <DateField
                                            name={f.name}
                                            label={f.placeholder}
                                            value={dateValue[f.name]}
                                            onChange={onDateChange}
                                        />
                                        : null
                    }</Grid>
                ))}
                {direction == 'row'
                    ? <Grid item xs={2} >
                        <Button onClick={onClearClick}>Clear</Button>
                    </Grid>
                    :
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: onApply ? 'flex-start' : 'flex-end' }}>
                        <SpinnerButton onClick={onClearClick} showSpinner={isBusy||false} >Clear</SpinnerButton>
                    </Grid>
                }
            </Grid>
        </MaybeCollapse>
    </Grid>
};

export default FiltersPanel;