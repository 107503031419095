import * as React from 'react';
import { useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { ScheduleItem, ScheduleJobResult } from '../../models/core';
import { capitalize, day2date, slot2time } from '../../utils/slots';
import { DateTime } from 'luxon';
import { Check } from '@mui/icons-material';

interface ResultGridProps {
    result: ScheduleJobResult;
    startDate: DateTime;
    onBook?: (agentId: string, day: number, startTime: number) => void;
}

interface GridItem {
    item: ScheduleItem | null;
    busy: boolean;
}

const ResultGrid = ({ result, startDate, onBook }: ResultGridProps): JSX.Element => {
    const days = useMemo(
        () => [
            ...new Array(1 + Math.max(...(result.schedule?.map(item => item.day) || [-1])))
        ].map((_, i) => i),
        [result])
    const slots = useMemo(
        () => days.map(day => [
            ...new Array(1 + Math.max(...(result.schedule?.filter(item => item.day == day).map(item => item.startTime + item.duration + 1) || [-1])))
        ].map((_, i) => i)),
        [result, days]
    );
    const cells = useMemo<{ [k: string]: GridItem[] }[]>(() => {
        const cs = days.map(_ =>
            Object.fromEntries(result.agents.map(agent => [
                agent.id,
                slots.flatMap(daySlots => daySlots.map(_ => ({ item: null as (ScheduleItem | null), busy: false })))
            ]))); // [agent][day][slot]
        result.schedule.forEach(item => {
            if (item.startTime + item.duration < 40) {
                cs[item.day][item.agentId][item.startTime].item = item;
                for (let i = 1; i < item.duration; i += 1) {
                    cs[item.day][item.agentId][item.startTime + i].busy = true;
                }
            }
            else {
                console.warn('Invalid item time', item);
            }
        })
        return cs;
    }, [result, days, slots]);

    const onBookClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        const agentId = event.currentTarget.getAttribute('x-agent-id') || '';
        const day = Number.parseInt(event.currentTarget.getAttribute('x-day') || '', 10);
        const startTime = Number.parseInt(event.currentTarget.getAttribute('x-start-time') || '', 10);
        onBook && onBook(agentId, day, startTime);
    }, [onBook]);

    return <Box sx={{
        height: '640px',
        overflowY: 'auto',
        table: {
            margin: '8px',
            whiteSpace: 'nowrap',
            borderCollapse: 'collapse',
            'td.visit': {
                background: '#eee',
            },
            'td.visit div': {
                width: '15em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            'td,th': {
                border: `1px solid #c0c0c0`,
                padding: '0 4px'
            },
            'tr.first-in-day td, tr.first-in-day th': {
                borderTopWidth: '2px'
            },
            '.odd': {
                // background: '#eee'
            }
        }
    }}>
        <table cellSpacing='0'>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    {result.agents.map(agent => (
                        <th key={agent.id}>{agent.name}</th>
                    )
                    )}
                </tr>
            </thead>
            <tbody>
                {days.flatMap(day => (
                    slots[day].map(slot => (
                        <tr key={`${day}-${slot}`} className={slot == 0 ? 'first-in-day' : ''}>
                            {slot == 0
                                ? <th rowSpan={slots[day].length} key={`${day}-${slot}-day`}>{day2date(day, startDate)}</th>
                                : null
                            }
                            <th key={`${day}-${slot}-slot`} className={(slot % 8 < 4) ? 'even' : 'odd'}>{slot2time(slot, startDate.hour)}</th>
                            {result.agents.map(agent => cells[day][agent.id][slot]).map((cell,i) => (
                                cell.item
                                    ? <td
                                        key={`${cell.item.agentId}-${day}-${slot}`}
                                        rowSpan={cell.item.duration}
                                        title={`${capitalize(cell.item.address)}\n${capitalize(cell.item.patient.firstName + ' ' + cell.item.patient.lastName)}\n${result.providers.find(p => p.id == cell.item?.providerId)?.name}`}
                                        className='visit'
                                    >
                                        <div>{capitalize(cell.item.address)}</div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{capitalize(cell.item.patient.firstName + ' ' + cell.item.patient.lastName)}</div>
                                            {cell.item.visit.appointmentId
                                                ? <Check titleAccess='Booked' />
                                                : <IconButton
                                                    title='Book'
                                                    color='primary'
                                                    x-agent-id={cell.item.agentId}
                                                    x-day={cell.item.day}
                                                    x-start-time={cell.item.startTime}
                                                    onClick={onBookClick}>
                                                    <ImportContactsIcon />
                                                </IconButton>
                                            }
                                        </div>
                                    </td>
                                    : cell.busy 
                                        ? null 
                                        : <td key={i} className={(slot % 8 < 4) ? 'even' : 'odd'}></td>
                            ))}
                        </tr>
                    ))
                ))}
            </tbody>
        </table>
    </Box>
}

export default ResultGrid;