export function parseAddress(address: string) {
    const building = /^\d*/.exec(address);
    const apartment = /(apt\.?)? *\w*\d+\w*$/i.exec(address);
    return [
            building ? building[0] : '',
            address.slice(
                building ? building[0].length : 0,
                apartment ? -apartment[0].length : undefined
            ),
            apartment ? apartment[0].replace(/^apt\.?/i, '').trim() : ''
    ];
}