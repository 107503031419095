import { DateTime } from "luxon";

/**
 * Converts time slot index to time string
 * @param slot Number of the time slot starting from zero
 * @param startHour Hour corresponding to 0th slot (8 by default)
 */
export function slot2time(slot: number, startHour = 8): string {
    return new Date(1970, 1, 1, startHour + Math.floor(slot / 4), 15 * (slot % 4))
        .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        .replace(' ', '')
}

export function slot2luxon(slot: number, start: DateTime): DateTime {
    return start.plus({
        hour: Math.floor(slot / 4),
        minute: 15 * (slot % 4)
    }).set({
        second: 0, 
        millisecond: 0
    })
}

/**
 * Makes every word to start from capital letter
 * @param str input string
 */
export function capitalize(str: string): string {
    return str.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

/**
 * Converts day index to date string
 * @param day Index of day starting from zero
 * @param start Date corresponding to day=0
 */
export function day2date(day: number, start: Date | DateTime): string {
    return ((start instanceof Date) ? DateTime.fromJSDate(start) : start).plus({ day }).toLocaleString({ month: 'short', day: '2-digit' });
}