import debounce from "lodash.debounce";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";

function useSearch<T>(
    fetch: (text: string) => Promise<T[]>,
    initValue: T | null,
    labelFn: (opt: T) => string): {
        options: T[];
        value: T | null;
        onChange: (_event: SyntheticEvent, value: T | null) => void;
        onInputChange: (event: SyntheticEvent, value: string) => void;
    } {

    const [options, setOptions] = useState<T[]>([]);
    const [value, setValue] = useState<T | null>(initValue || null);
    const [inputValue, setInputValue] = useState(initValue && labelFn(initValue) || '');

    const onChange = useCallback((_event: SyntheticEvent, value: T | null) => {
        setValue(value);
    }, [setValue]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refreshOptions = useCallback(debounce(async (text: string) => {
        if (value && (labelFn(value) == inputValue)) {
            setOptions([value]);
        }
        else {
            setOptions(await fetch(text));
        }
    }, 300), [fetch, value, inputValue]);

    const onInputChange = useCallback((event: SyntheticEvent, value: string) => {
        setInputValue(value);
    }, [setInputValue]);

    useEffect(() => {
        const t = refreshOptions(inputValue);
        t && t.catch(console.error);
    }, [inputValue, refreshOptions])

    return {
        options,
        value,
        onChange,
        onInputChange
    }
}

export default useSearch;