import * as React from 'react';
import { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Paper, Stack, StackProps, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { Client, JobStatus, Plan, ScheduleJob } from '../models/core';
import { Office, Practice, Role, User } from '../models/ziphy';
import JobControlBox from './schedule/JobControlBox';
import useDialog from '../hooks/useDialog';
import ResultDialog from './schedule/ResultDialog';
import JobsList from './schedule/JobsList';
import { JobWithMessages, useJobsList } from '../hooks/useJobsList';
import JobTabs from './schedule/JobTabs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ConfirmDialog from './ConfirmDialog';
import { Message } from './schedule/MessagesBox';

function jobMessages(job: ScheduleJob): Message[] {
    const MATCHED_PATIENTS_WARN = 1000;
    const MATCHED_PATIENTS_ERROR = 2000;

    const { patientsFilter, agents, providers } = job.params;
    return [
        patientsFilter.city.length == 0
            ? { severity: 'error', text: 'Select some patients', subject: 'patients' }
            : { severity: 'info', text: `Patients from ${patientsFilter.city.length} cities are selected.`, subject: 'patients' },
        agents.length == 0
            ? { severity: 'error', text: 'Select some agents', subject: 'agents' }
            : { severity: 'info', text: `${agents.length} agents are selected.`, subject: 'agents' },
        providers.length == 0
            ? { severity: 'error', text: 'Select some providers', subject: 'providers' }
            : { severity: 'info', text: `${providers.length} providers are selected.`, subject: 'providers' },
        ...(job?.params.femaleAgentPreferred && agents.find(a => a.gender == 'male')
            ? [{ severity: 'warn', text: 'Male agent(s) is selected while female is preferred', subject: 'agents' } as Message]
            : []
        )
    ];
}

export interface RoleExt extends Role {
    practice?: Practice;
    office?: Office;
    user?: User;
}

export interface ClusterDialogProps extends DialogProps {
    open: boolean;
    jobs: ScheduleJob[];
    client: Client;
    plans: Plan[];
    onClose?: () => void;
}

interface JobsListHeaderProps extends StackProps {
    job: JobWithMessages<ScheduleJob>|null;
    onRunClick?: () => void;
    onStopClick?: () => void;
    onCreateClick?: () => void;
    onDeleteClick?: () => void;
}

const JobsListHeader = ({ job, onRunClick, onStopClick, onCreateClick, onDeleteClick }: JobsListHeaderProps): JSX.Element => {
    const buttons = React.useMemo(() => {
        const t = [JobStatus.IN_PROGRESS, JobStatus.TERMINATING];
        return [
            ['create', true],
            ['delete', job && !t.includes(job.status)],
            ['run', job && !t.includes(job.status) && job.messages.filter(m => m.severity == 'error').length == 0],
            ['stop', job && job.status == JobStatus.IN_PROGRESS]
        ].filter(([_,v]) => v).map(([k]) => k);
    }, [job]);
    return (
        <Stack direction='row' sx={{ alignItems: 'center', height: '63px' }}>
            <Typography variant='h6' sx={{ flexGrow: 1, paddingLeft: '8px' }}>Jobs</Typography>
            <IconButton
                onClick={onRunClick}
                disabled={!buttons.includes('run')}
                sx={{ display: (buttons.includes('run') || !buttons.includes('stop')) ? undefined : 'none' }}
            >
                <PlayArrowIcon />
            </IconButton>
            <IconButton
                onClick={onStopClick}
                sx={{ display: buttons.includes('stop') ? undefined : 'none' }}
            >
                <StopIcon />
            </IconButton>
            <IconButton onClick={onCreateClick} disabled={!buttons.includes('create')}>
                <AddIcon />
            </IconButton>
            <IconButton onClick={onDeleteClick} disabled={!buttons.includes('delete')}>
                <DeleteIcon />
            </IconButton>
        </Stack>

    );
}

const ScheduleDialog = ({ open, client, plans, jobs, onClose, ...props }: ClusterDialogProps): JSX.Element => {
    const { jobs: allJobs, isLoading, createJob, saveJob, isJobSaving, deleteJob, runJob, stopJob, activeJob, setActiveJob } = useJobsList<ScheduleJob>(client._id, 'schedule', jobMessages);
    const { open: resDlgOpen, hide: hideResDlg, show: showResDlg } = useDialog();
    const { hide: hideConf, open: confOpen, show: showConf } = useDialog();

    const onJobChange = useCallback((value: JobWithMessages<ScheduleJob>) => {
        saveJob(value);
    }, [saveJob]);

    const onRenameJob = useCallback((name: string) => {
        activeJob && saveJob({ ...activeJob, name });
    }, [activeJob, saveJob]);

    const onDeleteClick = useCallback(() => {
        showConf();
    }, [showConf]);

    const onDeleteJobConfirmed = useCallback(async () => {
        activeJob && deleteJob();
        hideConf();
    }, [activeJob, hideConf, deleteJob]);

    return (
        <Dialog {...props} open={open} onClose={onClose} PaperProps={{ sx: { maxWidth: '1600px' } }}>
            <DialogTitle>Schedule</DialogTitle>
            <DialogContent>
                <Stack direction='row' sx={{ height: '640px' }}>
                    <Paper sx={{ marginRight: '8px', display: 'flex', flexDirection: 'column' }}>
                        <JobsListHeader
                            job={activeJob}
                            onCreateClick={createJob}
                            onDeleteClick={onDeleteClick}
                            onRunClick={runJob}
                            onStopClick={stopJob}
                        />
                        <JobsList
                            jobs={allJobs}
                            selectedJob={activeJob}
                            onSelectJob={setActiveJob}
                            isLoading={isLoading}
                            isJobSaving={isJobSaving}
                            sx={{ width: '240px', flexGrow: 1 }}
                        />
                    </Paper>
                    <Paper sx={{ width: '720px' }}>
                        {activeJob
                            ? <>
                                <JobControlBox
                                    job={activeJob}
                                    onResultClick={showResDlg}
                                    runEnabled={activeJob.messages.filter(m => m.severity=='error').length==0}
                                    onRename={onRenameJob}
                                    onRunClick={runJob}
                                    onStopClick={stopJob}
                                />
                                {/* {[].filter(m => m.severity == 'error').length == 0} /> */}
                                <JobTabs
                                    client={client}
                                    plans={plans}
                                    job={activeJob}
                                    onChange={onJobChange} />
                            </>
                            : null
                        }
                    </Paper>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>OK</Button>
            </DialogActions>
            {resDlgOpen && activeJob?.result
                ? <ResultDialog job={activeJob} result={activeJob.result} practiceId={client.practiceId} open onClose={hideResDlg} />
                : null
            }
            <ConfirmDialog open={confOpen} onCancel={hideConf} onSubmit={onDeleteJobConfirmed} />
            <ReactQueryDevtools initialIsOpen={true} />
        </Dialog>
    );
}

export default ScheduleDialog;