import * as React from 'react';
import { useCallback, useState } from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';

interface AutoTabProps {
    label: string;
    visible?: boolean;
    children: React.ReactNode;
}

export const AutoTab = ({ visible, children }: AutoTabProps): JSX.Element => (
    <div hidden={!!(visible == false)}>
        {children}
    </div>
)

interface AutoTabsProps extends TabsProps {
    tabsPosition?: 'top' | 'bottom';
    children: React.ReactElement[];
}

const AutoTabs = ({ tabsPosition, children, ...props }: AutoTabsProps): JSX.Element => {
    const [index, setIndex] = useState(0);
    const onChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setIndex(newValue);
    }, []);
    const TabsFn = useCallback(() => (
        <Tabs
            value={index}
            onChange={onChange}
            TabIndicatorProps={tabsPosition == 'bottom' ? { sx: { top: 0 } } : undefined}
            {...props}
            >
            {React.Children.map(children, c => (
                <Tab label={c.props['label']} />
            ))}
        </Tabs>
    ), [tabsPosition, children, index, onChange, props]);
    return <>
        {(tabsPosition || 'top') == 'top' && <>
            <TabsFn />
            <hr style={{ marginTop: '-1px' }} />
        </>}
        {React.Children.map(children, (c, i) => (
            i==index ? c : null
        ))}
        {(tabsPosition || 'top') == 'bottom' && <>
            <hr style={{ marginBottom: '-1px' }} />
            <TabsFn />
        </>}
    </>
};

export default AutoTabs;