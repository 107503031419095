import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Place } from '../../models/ziphy';
import { Filter, API as ZiphyAPI } from '../../utils/ZiphyAPI';
import { useQuery } from '@tanstack/react-query';
import FetchAutocomplete from '../schedule/FetchAutocomplete';
import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import { parseAddress } from '../../utils/address';


async function getZiphyPlaces(practiceId: number, inputValue: string): Promise<Place[]> {
    if (inputValue) {
        const parts = inputValue.toLowerCase().split(',').map(word => word.trim());
        return (await ZiphyAPI.getPlaces({
            and: [
                { eq: ['is_hidden', false] },
                { contains: ["practice_ids", [practiceId]] },
                {
                    or: [
                        ...parts.flatMap<Filter>(part => {
                            if (/\d\d\d\d\d\d*/.test(part)) {
                                return [{ eq: ['zip', part] }];
                            }
                            if (/\d+/.test(part)) {
                                const [building, street, _apartment] = parseAddress(part);
                                return [
                                    ...(building ? [{ eq: ['building', building] } as Filter] : []),
                                    { imatch: ['street', street] }
                                ];
                            }
                            return { imatch: ['city', part] };
                        })
                    ]
                }
            ]
        })).places.items;
    }
    return [];

}

interface ZiphyPlaceInputProps {
    practiceId: number;
    initialInput: string;
    value: Place | null;
    onChange?: (value: Place | null) => void;
}

const ZiphyPlaceInput = ({ practiceId, initialInput, value, onChange }: ZiphyPlaceInputProps): JSX.Element => {
    const [placeInput, setPlaceInput] = useState('');
    const firstFetch = useRef(true);

    const placesQuery = useQuery({
        queryKey: ['places', practiceId, placeInput, initialInput],
        queryFn: async () =>
            await getZiphyPlaces(practiceId, placeInput + ' ' + initialInput)
    });
    const getPlaceLabel = useCallback((place: Place) => (
        `${place.building} ${place.street}, ${place.city}, ${place.state}, ${place.zip}`
    ), []);

    const optEqual = useCallback((x: Place, y: Place) => x.id == y.id, []);

    useEffect(() => {
        if (value == null && placeInput == '' && placesQuery.data && placesQuery.data.length > 0 && firstFetch.current) {
            onChange && onChange(placesQuery.data[0]);
            firstFetch.current = false;
        }
    }, [value, placesQuery, onChange, firstFetch, placeInput])

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => (
        <TextField {...params} label='Place' fullWidth variant='standard' />
    ), []);

    return (
        <FetchAutocomplete
            options={placesQuery.data || []}
            value={value}
            onChange={onChange}
            onInputChange={setPlaceInput}
            getOptionLabel={getPlaceLabel}
            isOptionEqualToValue={optEqual}
            renderInput={renderInput}
        />
    );
}
export default ZiphyPlaceInput;