import * as React from 'react';
import { useCallback, useState } from 'react';
import SpinnerButton from './SpinnerButton';
import { Alert, ButtonProps, Snackbar } from '@mui/material';


interface QueryButtonProps extends ButtonProps {
    doFetch: () => Promise<void>;
    showSnack?: boolean;
}

const QueryButton = ({ doFetch, showSnack, ...props }: QueryButtonProps): JSX.Element => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [snackVisible, setShowSnack] = useState(false);

    const onClick = useCallback(async () => {
        try {
            setShowSpinner(true);
            await doFetch();
            (showSnack!=false) && setShowSnack(true);
        }
        finally {
            setShowSpinner(false);
        }
    }, [doFetch, showSnack]);

    const onSnackClose = useCallback(() => {
        setShowSnack(false);
    }, []);

    return <>
        <SpinnerButton {...props} showSpinner={showSpinner} onClick={onClick} />
        <Snackbar open={snackVisible} autoHideDuration={2000} onClose={onSnackClose}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
            <Alert onClose={onSnackClose} severity="success" sx={{ width: '100%' }}>
                Done
            </Alert>
        </Snackbar>
    </>
};

export default QueryButton;