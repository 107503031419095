import * as React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, ListProps, useTheme } from '@mui/material';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

export interface Message {
    severity: 'info' | 'warn' | 'error';
    text: string;
    subject?: string;
}

interface MessagesBoxProps extends ListProps {
    messages: Message[]
}

const MessagesBox = ({ messages, ...props }: MessagesBoxProps): JSX.Element => {
    const theme = useTheme();
    const colors: { [k: string]: string } = {
        info: theme.palette.success.main,
        warn: theme.palette.warning.main,
        error: theme.palette.error.main
    }
    return <List dense {...props}>
        {messages.map((msg, i) => (
            <ListItem key={i} sx={{color: colors[msg.severity as string]}}>
                <ListItemIcon sx={{color: 'inherit', minWidth: '32px'}}>
                    {msg.severity == 'info'
                        ? <CheckCircleOutlinedIcon />
                        : msg.severity == 'warn'
                            ? <WarningAmberOutlinedIcon />
                            : <ErrorOutlineOutlinedIcon />
                    }
                </ListItemIcon>
                <ListItemText primary={msg.text} />
            </ListItem>
        ))}
    </List>
}

export default MessagesBox;