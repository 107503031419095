import { useCallback } from "react";

export const useLocalStorage = <T,>(
    keyName: string,
    defaultValue: T
): [() => T, (newValue: T) => void] => {
    const getValue = useCallback(() => {
        try {
            const value = window.localStorage.getItem(keyName);

            if (value) {
                return JSON.parse(value) as T;
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    }, [keyName, defaultValue]);

    const setValue = useCallback((newValue: T) => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));
        } catch (err) { console.error(err); }
    }, [keyName]);

    return [getValue, setValue];
};
