import * as React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface SpinnerButtonProps extends ButtonProps {
    showSpinner: boolean;
}

const SpinnerButton = ({ showSpinner, children, onClick, ...props }: SpinnerButtonProps): JSX.Element => {
    const maybeOnClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        !showSpinner && onClick && onClick(event)
    }, [onClick, showSpinner]);
    return (
        <Button onClick={maybeOnClick} {...props}>
            {showSpinner 
                ? <CircularProgress size={24} color={props.color || (props.variant=='contained' ? 'inherit' : 'primary')} /> 
                : children
            }
        </Button>
    );
};

export default SpinnerButton;