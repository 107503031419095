import * as React from 'react';
import { useCallback, useState } from 'react';
import { useLoaderData } from "react-router";
import { ActionFunctionArgs, Outlet, redirect, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import AppBarPage from "../components/AppBarPage";
import ClientEditor from '../components/ClientEditor';
import AccessEditor from '../components/AccessEditor';
import ConfirmDialog from '../components/ConfirmDialog';
import { RoutesListItem } from "../components/RoutesList";
import StickyFab from '../components/StickyFab';
import useDialog from '../hooks/useDialog';
import { Client } from '../models/core';
import { AuthData, Practice } from '../models/ziphy';
import { API } from '../utils/Api';
import { API as ZiphyAPI } from '../utils/ZiphyAPI';
import { analytics } from '../utils/analytics/zipAnalytics';
import { useLoading } from '../hooks/useLoading';

export const loader = async (): Promise<[Client[], Practice[]]> => {
    const authItem = window.localStorage.getItem('auth');
    const auth = authItem ? (JSON.parse(authItem) as AuthData) : null;
    API.token = auth?.accessToken?.value || '';
    ZiphyAPI.token = API.token;

    const practices = await ZiphyAPI.getPractices();
    if (auth?.roles?.find(r => r.role == 'admin')) {
        return [(await API.getClients(undefined, [['lastSync', 1]])).items, practices];
    }
    const seenPracticeIds = new Set();
    const filteredRoles = (auth?.roles || []).filter(r => {
        if ((r.role == 'practice_admin' || r.role == 'dispatcher') && !seenPracticeIds.has(r.practice_id)) {
            seenPracticeIds.add(r.practice_id);
            return true;
        }
        return false;
    });

    return [(
        await Promise.all(
            filteredRoles.map(r =>
                API.getClients({ practiceId: r.practice_id + '' })
            )
        )
    ).reduce((acc, r) => [...acc, ...r.items], [] as Client[]),

        practices
    ];
}

export const action = async ({ params }: ActionFunctionArgs): Promise<Response> => {
    if (!params.clientId) {
        throw Error('Missing clientId param');
    }
    await API.deleteClient(params.clientId);
    return redirect('../..');
}

const ClientListItem = ({ client: item, onMore }: { client: Client, onMore: (client: Client, target: HTMLElement) => void }): JSX.Element => {
    const loading = useLoading();
    const onSheetLinkClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const sheetId = event.currentTarget.attributes.getNamedItem('x-sheet-id')?.value;
        const href = `https://docs.google.com/spreadsheets/d/${sheetId}`;
        analytics.track('client_open_sheet', { sheetId:sheetId || ''}, true);
        window.open(href, '_blank', 'noreferrer');
    }, []);

    const onMoreClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        onMore && onMore(item, event.currentTarget)
        event.stopPropagation();
    }, [item, onMore]);

    const onClientClick = useCallback(() => {
        loading.showLoading();
        analytics.breadcrumb("client_select", { clientId: item._id});
    }, [item, loading]);

    return (
        <RoutesListItem key={item._id} to={item._id + '/patients'} 
            onMoreClick={onMoreClick}
            onClick={onClientClick}>
            <ListItemIcon>
                <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={item.name} secondary={
                <>
                    {item.sheetId}
                    <IconButton x-sheet-id={item.sheetId} onClick={onSheetLinkClick} >
                        <LaunchIcon sx={{
                            fontSize: 'large',
                            verticalAlign: 'text-bottom',
                            color: 'text.secondary'
                        }} />
                    </IconButton>
                </>
            } />
        </RoutesListItem>
    )
}

const ClientsPage = (): JSX.Element => {
    const [items, practices] = useLoaderData() as [Client[], Practice[]];
    const [clientToEdit, setClientToEdit] = useState<Client | null>(null);
    const [duplicate, setDuplicate] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const {hide: hideCE, open: ceOpen, show: shoeCE} = useDialog();
    const {hide: hideConf, open: confOpen, show: showConf} = useDialog();
    const {hide: hideAE, open: aeOpen, show: showAE} = useDialog();
    const navigate = useNavigate();
    const loading = useLoading();

    React.useEffect(() => {loading.hideLoading();}, [])


    const onMore = useCallback((client: Client, target: HTMLElement) => {
        setClientToEdit(client);
        setAnchorEl(target);
    }, []);

    const onMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onCreateClick = useCallback(() => {
        setDuplicate(false);
        setClientToEdit(null);
        shoeCE();
    }, [shoeCE]);

    const onEditClick = useCallback(() => {
        setAnchorEl(null);
        setDuplicate(false);
        shoeCE();
    }, [shoeCE]);

    const onDuplicateClick = useCallback(() => {
        setAnchorEl(null);
        setDuplicate(true);
        shoeCE();
    }, [shoeCE]);

    const onAccesEditClick = useCallback(() => {
        setAnchorEl(null);
        setDuplicate(false);
        showAE();
    }, [showAE]);

    const onDeleteClick = useCallback(() => {
        setAnchorEl(null);
        showConf();
    }, [showConf]);

    const onSave = useCallback(() => {
        hideCE();
        navigate('.');
    }, [hideCE, navigate]);

    const onSaveUser = useCallback(() => {
        hideAE();
        navigate('.');
    }, [hideAE, navigate]);

    const deleteClient = useCallback(async () => {
        clientToEdit && await API.deleteClient(clientToEdit._id);
        setClientToEdit(null);
        hideConf();
        navigate('.');
    }, [clientToEdit, hideConf, navigate]);

    return (
        <AppBarPage title='Clients'>
            <List>
                {[...items.map((item) => (
                    <ClientListItem key={item._id} client={item} onMore={onMore} />
                )),
                ...(items.length == 0 ? [
                    <ListItem key='no-items-msg' sx={{
                        height: '102px',
                        display: 'flex',
                        justifyContent: 'center',
                        background: '#1976d210',
                        color: '#1976d2'
                    }}>
                        There is no clients now. Lets create one!
                    </ListItem>
                ] : [])
                ]}
            </List>
            <StickyFab onClick={onCreateClick} title='Create New Client'>
                <AddIcon />
            </StickyFab>
            <ClientEditor
                open={ceOpen}
                onClose={hideCE}
                client={clientToEdit || undefined}
                duplicate={duplicate}
                practices={practices}
                onSave={onSave}
            />
            <AccessEditor 
                open={aeOpen}
                onClose={hideAE}
                onSave={onSaveUser}
                client={clientToEdit||undefined}
                practices={practices}
                />
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={onMenuClose}
            >
                <MenuItem onClick={onEditClick}>Edit</MenuItem>
                <MenuItem onClick={onDuplicateClick}>Duplicate</MenuItem>
                <MenuItem onClick={onAccesEditClick}>Users</MenuItem>
                <MenuItem sx={{ color: 'red' }} onClick={onDeleteClick}>Delete</MenuItem>
            </Menu>
            <ConfirmDialog open={confOpen} onCancel={hideConf} onSubmit={deleteClient} />
            <Outlet />
        </AppBarPage>
    );
}
//<MenuItem onClick={onAccesEditClick}>Users</MenuItem>

export default ClientsPage;