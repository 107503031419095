import * as React from 'react';
import { useCallback } from 'react';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps} from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { IconButton, ListItemButton, ListItemSecondaryAction } from '@mui/material';

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
    return (
        <RouterLink ref={ref} {...itemProps} role={undefined} style={{ color: 'inherit' }} />
    );
});


interface ListItemLinkProps extends ListItemProps<typeof RouterLink> {
    to: string;
    hideMenu?: boolean;
    onMoreClick?: (event: React.MouseEvent<HTMLElement>, rootUrl: string) => void;
    children: string | JSX.Element | JSX.Element[];
}

export const RoutesListItem = ({ to, onMoreClick, hideMenu, children, ...props }: ListItemLinkProps): JSX.Element => {
    const _onMoreClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        onMoreClick && onMoreClick(event, to);
        event.preventDefault();
    }, [onMoreClick, to]);
    return (
        <li>
            <ListItem component={Link} to={to} {...props}>
                <ListItemButton>
                    {children}
                    {hideMenu ? null :
                        <ListItemSecondaryAction>
                            <IconButton onClick={_onMoreClick}>
                                <MoreVertIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    }
                </ListItemButton>
            </ListItem>
        </li>
    );
}

