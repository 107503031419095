import { DateTime } from 'luxon';
import { User } from '../models/ziphy';

export function findNewestDate(dates: (DateTime | null)[]) {
    const dateObjects = dates?.map(date => date ? date.toUnixInteger() : 0) || [];
    const idx = dateObjects.indexOf(Math.max(...dateObjects));
    return idx != -1 ? dates[idx] : undefined;
}

export function stringToDateTime(dateString: string|undefined) {
    return dateString && DateTime.fromISO(dateString) || null;
}

export function parseStringArray(key: string | undefined, strArr: string[]) {
    const lck = key?.toLocaleLowerCase();
    return strArr.find(item => item.toLocaleLowerCase() === lck) || key;
}

export function createDocumentInfo(user:string, userId:number, comment?:string) {
    return {
        lastEditedDate:DateTime.now().toString(),
        lastEditedBy:user,
        lastEditedById:userId,
        lastEditReason:comment
    }
}

export function authDocumentInfo(user?:User, comment?:string) {
    return {
        lastEditedDate:DateTime.now().toString(),
        lastEditedBy:user?.name || 'unknown',
        lastEditedById:user?.id || -1,
        lastEditReason:comment
    }
}