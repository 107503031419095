import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { AuthProvider } from './hooks/useAuth';
import RoutesRoot from './Routes';


// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <AuthProvider>
      <RoutesRoot />
    </AuthProvider>
); 