import * as React from 'react';
import { Grid, GridProps, GridSize } from '@mui/material';
import { Children } from 'react'

interface Props {
    children: string | JSX.Element | JSX.Element[];
}

const GridForm = ({ children }: Props): JSX.Element => (
    <Grid container spacing={2}>
        {
            Children.map(children, child => (                
                (child as JSX.Element).type == GridFormItem
                    ? child
                    : <Grid item xs={6}>{child}</Grid>
            ))
        }
    </Grid >
)
interface ItemProps extends GridProps {
    xs: GridSize;
}

export const GridFormItem = ({ xs, children, ...props }: ItemProps): JSX.Element => (
    <Grid item xs={xs} {...props}>{children}</Grid>
)

export default GridForm;