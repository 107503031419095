import { DateTime } from "luxon";
import { DTime } from "../models/ziphy";

export function jsToDtime(dt: Date): DTime {
    return {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate(),
        hour: dt.getHours(),
        minute: dt.getMinutes(),
        second: dt.getSeconds(),
        utcoffset: 0, //dt.getTimezoneOffset(),
        _type: 'datetime'
    }
}

export function luxonToDtime(dt: DateTime): DTime {
    return {
        year: dt.year,
        month: dt.month,
        day: dt.day,
        hour: dt.hour,
        minute: dt.minute,
        second: dt.second,
        utcoffset: 0, //dt.offset
        _type: 'datetime'
    };
}

export function dtimeToJs(dt: DTime): Date {
    return new Date(dt.year, dt.month - 1, dt.day, dt.hour, dt.minute, dt.second);
}

export function dtimeToLuxon(dt: DTime): DateTime {
    return DateTime.fromObject({
        year: dt.year,
        month: dt.month,
        day: dt.day,
        hour: dt.hour,
        minute: dt.minute,
        second: dt.second
    })
}

export function dtimeLe(a: DTime, b: DTime) {
    return a.year < b.year || a.year == b.year && (
        a.month < b.month || a.month == b.month && (
            a.day < b.day || a.day == b.day && (
                a.hour < b.hour || a.hour == b.hour && (
                    a.minute < b.minute || a.minute == b.minute && (
                        a.second <= b.second
                    )
                )
            )
        )
    )
}