import * as React from 'react';
import { useCallback, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import GridForm, { GridFormItem } from '../GridForm';
import { Patient } from '../../models/core';
import { Date as ZiphyDate, Patient as ZiphyPatient } from '../../models/ziphy';
import { DateTime } from 'luxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import PhoneInputWithCountrySelect, { Value } from 'react-phone-number-input';
import SpinnerButton from '../SpinnerButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API as ZiphyAPI } from '../../utils/ZiphyAPI';
import AlertSnack from '../ErrorSnack';
import useSnackbar from '../../hooks/useSnackbar';
import { capitalize } from '../../utils/slots';

function str2date(date: Date): ZiphyDate {
    return { _type: 'date', year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
}

const PhoneInput = React.forwardRef(function PhoneInput({ ...props }, ref) {
    return (
        <TextField
            {...props}
            name='phone'
            variant='standard'
            label='Phone'
            inputRef={ref} />
    );
})

interface Props {
    patient: Patient;
    practiceId?: number | null;
    placeId?: number | null;
    onChange?: (patient: ZiphyPatient) => void;
}

const ZiphyPatientForm = ({ patient, practiceId, placeId, onChange }: Props): JSX.Element => {
    const [firstName, setFirstName] = useState(capitalize(patient.firstName.trim() || ''));
    const [lastName, setLastName] = useState(capitalize(patient.lastName.trim() || ''));
    const [gender, setGender] = useState(patient.gender?.toLowerCase() || 'na');
    const [email, setEmail] = useState(patient.email || '');
    const [phone, setPhone] = useState<Value | undefined>(
        patient.phone?.startsWith('+1')
            ? patient.phone
            : patient.phone?.startsWith('1')
                ? ('+' + patient.phone)
                : patient.phone
                    ? '+1' + patient.phone
                    : undefined
    );
    const [dob, setDob] = useState<Date | null>(patient.dob ? new Date(patient.dob) : null)
    const [errors, setErrors] = useState<{ [k in keyof ZiphyPatient]?: boolean }>({});
    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {

            case 'firstName': setFirstName(event.target.value); break;
            case 'lastName': setLastName(event.target.value); break;
            case 'gender': setGender(event.target.value); break;
            case 'email': setEmail(event.target.value); break;
            case 'phone': setPhone(event.target.value); break;
        }
    }, []);

    const onDateChange = useCallback((value: string | null) => {
        setDob(value && DateTime.fromISO(value.toString()).toJSDate() || null);
    }, []);

    const onSelectChange = useCallback((event: SelectChangeEvent) => {
        setGender(event.target.value);
    }, []);

    const { showSuccess: snackSuccess, showError: snackError, ...snackProps } = useSnackbar();
    const queryClient = useQueryClient();
    const savePatientMutation = useMutation({
        mutationFn: async () =>
            practiceId && placeId && firstName && lastName && dob
            && await ZiphyAPI.createPatient({
                first_name: firstName,
                last_name: lastName,
                gender,
                email: email || undefined,
                phone: phone?.replaceAll(/[^0-9+]/g, ''),
                dob: str2date(dob),
                code: '',
                place_id: placeId
            }, practiceId),
        onSuccess: (patient) => {
            snackSuccess();
            patient && onChange && onChange(patient);
            queryClient.invalidateQueries({ queryKey: ['patients'] });
        },
        onError: snackError
    });

    const onCreateClick = useCallback(() => {
        const errors = {
            first_name: !firstName,
            last_name: !lastName,
            dob: !dob,
            email: !email
        };
        setErrors(errors);
        if (!Object.values(errors).reduce((acc, val) => acc || val)) {
            savePatientMutation.mutate();
        }
    }, [dob, email, firstName, lastName, savePatientMutation]);

    return (
        <GridForm>
            <GridFormItem xs={12}>
                <TextField
                    name='firstName' label='First Name' value={firstName}
                    onChange={onTextFieldChange} variant='standard'
                    error={errors.first_name} required fullWidth />
            </GridFormItem>
            <GridFormItem xs={12}>
                <TextField
                    name='lastName' label='Last Name' value={lastName}
                    onChange={onTextFieldChange} variant='standard'
                    error={errors.last_name} required fullWidth />
            </GridFormItem>
            <GridFormItem xs={6}>
                <FormControl fullWidth variant='standard'>
                    <InputLabel>Gender</InputLabel>
                    <Select name='gender' value={gender} onChange={onSelectChange} >
                        <MenuItem value='male'>Male</MenuItem>
                        <MenuItem value='female'>Female</MenuItem>
                        <MenuItem value='na'>Prefer not to say</MenuItem>
                    </Select>
                </FormControl>
            </GridFormItem>
            <GridFormItem xs={6}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                        value={dob}
                        onChange={onDateChange}
                        label='DoB'
                        disableMaskedInput
                        renderInput={(params) =>
                            <TextField name='dob' variant='standard' {...params} required error={errors.dob} />
                        }
                    />
                </LocalizationProvider>
            </GridFormItem>
            <GridFormItem xs={6}>
                <TextField
                    name='email' label='Email' value={email}
                    onChange={onTextFieldChange} variant='standard'
                    error={errors.email} required />
            </GridFormItem>
            <GridFormItem xs={6}>
                <PhoneInputWithCountrySelect
                    value={phone}
                    onChange={setPhone}
                    defaultCountry='US'
                    inputComponent={PhoneInput}
                />
            </GridFormItem>
            <GridFormItem xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <SpinnerButton showSpinner={savePatientMutation.isPending} onClick={onCreateClick}>Create</SpinnerButton>
            </GridFormItem>
            <AlertSnack {...snackProps} />
        </GridForm>
    );
}

export default ZiphyPatientForm;
