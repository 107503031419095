import { AlertProps } from "@mui/material";
import { useCallback, useState } from "react";

type Severity = AlertProps['severity'];

export default function useSnackbar() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState<Severity>('info');
    const onClose = useCallback((event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason != 'clickaway') {
            setOpen(false);
        }
    }, []);
    const show = useCallback((msg: string, severity: Severity) => {
        setMessage(msg);
        setSeverity(severity);
        setOpen(true)
    }, []);
    const showSuccess = useCallback((msg = 'Done') => show(msg, 'success'), [show]);

    const showError = useCallback((error: Error) =>
        show(
            error.message.slice(0, 100) + (error.message.length > 100 ? '...' : ''),
            'error')
        , [show])
    return {
        open, onClose, message, showSuccess, showError, severity
    }
}