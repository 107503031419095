import { Layout } from "../../models/core";

export function id(): string {
    return [...new Array(24)].map(() => '0123456789abcdef'[Math.floor(Math.random() * 16)]).join('');
}

export const defaultLayout: Layout = {
    options: {
        isMultiPlan: true,
        measuresSheet: 'Data',
        patientsSheet: 'Patients',
        clientMeasuresSheet: 'Measures',
        planMeasuresSheet: 'Plans',
        planMeasuresFormat: 'rows',
        // patientMeasuresSheet: null,
        patientMeasuresFormat: 'columns',
        // programsSheet: null,
        // checksSheet: null
        planChecksFormat: 'rows',
        patientChecksFormat: 'columns',
        numCallAttempts: 6,
        numEmailAttempts: 1,
        numSMSAttempts: 1,
        numMailAttempts: 1,
    },
    patientFields: [
        { label: "Ref ID", defaultLabel: "Ref ID", field: "refId", id: id(), state: "required", forChild: true },
        { label: "Medicaid ID", defaultLabel: "Medicaid ID", field: "medicaidId", id: id(), state: "enabled", forChild: false},
        { label: "Medicare ID", defaultLabel: "Medicare ID", field: "medicareId", id: id(), state: "enabled", forChild: false },
        { label: "First Name", defaultLabel: "First Name", field: "firstName", id: id(), state: "required", forChild: true },
        { label: "Last Name", defaultLabel: "Last Name", field: "lastName", id: id(), state: "required", forChild: true },
        { label: "Dob", defaultLabel: "Dob", field: "dob", id: id(), state: "required", forChild: true },
        { label: "Age", defaultLabel: "Age", field: "age", id: id(), state: "required", forChild: true },
        { label: "Gender", defaultLabel: "Gender", field: "gender", id: id(), state: "required", forChild: true },
        { label: "Language", defaultLabel: "Language", field: "language", id: id(), state: "required", forChild: false },
        { label: "Address", defaultLabel: "Address", field: "address", id: id(), state: "required", forChild: false },
        { label: "Address 2", defaultLabel: "Address 2", field: "address2", id: id(), state: "required", forChild: false },
        { label: "City", defaultLabel: "City", field: "city", id: id(), state: "required", forChild: false },
        { label: "County", defaultLabel: "County", field: "county", id: id(), state: "enabled", forChild: false },
        { label: "State", defaultLabel: "State", field: "state", id: id(), state: "required", forChild: false },
        { label: "Zip", defaultLabel: "Zip", field: "zip", id: id(), state: "required", forChild: false },
        { label: "Phone", defaultLabel: "Phone", field: "phone", id: id(), state: "required", forChild: false },
        { label: "Alt Phone", defaultLabel: "Alt Phone", field: "phone2", id: id(), state: "enabled", forChild: false },
        { label: "Email", defaultLabel: "Email", field: "email", id: id(), state: "enabled", forChild: false },
        { label: "Guardian Name", defaultLabel: "Guardian Name", field: "guardianName", id: id(), state: "enabled", forChild: false },
        { label: "Guardian Phone", defaultLabel: "Guardian Phone", field: "guardianPhone", id: id(), state: "enabled", forChild: false },
        { label: "Guardian Email", defaultLabel: "Guardian Email", field: "guardianEmail", id: id(), state: "enabled", forChild: false },
        { label: "Case Manager", defaultLabel: "Case Manager", field: "caseManager", id: id(), state: "enabled", forChild: false },
        { label: "Case Manager Phone", defaultLabel: "Case Manager Phone", field: "caseManagerPhone", id: id(), state: "enabled", forChild: false },
        { label: "Practice Name", defaultLabel: "Practice Name", field: "practiceName", id: id(), state: "enabled", forChild: false },
        { label: "Group Name", defaultLabel: "Group Name", field: "groupName", id: id(), state: "enabled", forChild: false },
        { label: "Provider NPI", defaultLabel: "Provider NPI", field: "pcpNPI", id: id(), state: "enabled", forChild: false },
        { label: "Provider Name", defaultLabel: "Provider Name", field: "pcpName", id: id(), state: "enabled", forChild: false },
        { label: "Provider Phone", defaultLabel: "Provider Phone", field: "pcpPhone", id: id(), state: "enabled", forChild: false },
        { label: "Line Of Business", defaultLabel: "Line Of Business", field: "lineOfBusiness", id: id(), state: "enabled", forChild: false },
        { label: "Sub-Program", defaultLabel: "Sub-Program", field: "subProgram", id: id(), state: "enabled", forChild: false },
        { label: "Risk", defaultLabel: "Risk", field: "risk", id: id(), state: "enabled", forChild: false },
        { label: "Risk Category", defaultLabel: "Risk Category", field: "riskCategory", id: id(), state: "enabled", forChild: false }
    ],
    visitFields: [
        { label: "Ref ID", defaultLabel: "Ref ID", field: "refId", id: id(), state: "required", forChild: true },
        { label: "Appointment ID", defaultLabel: "Appointment ID", field: "appointmentId", id: id(), state: "enabled", forChild: false },
        { label: "Status", defaultLabel: "Status", field: "status", id: id(), state: "required",forChild: false },
        { label: "Refusal Reason", defaultLabel: "Refusal Reason", field: "refusalReason", id: id(), state: "enabled", forChild: false },
        { label: "UTR Referal", defaultLabel: "UTR Referal", field: "utrDate", id: id(), state: "enabled", forChild: false },
        { label: "Number of Reschedules", defaultLabel: "Number of Reschedules", field: "numReschedules", id: id(), state: "enabled", forChild: false },
        { label: "Member Info Update", defaultLabel: "Member Info Update", field: "memberInfoUpdate", id: id(), state: "enabled", forChild: false },
        { label: "Comments", defaultLabel: "Comments", field: "comments", id: id(), state: "enabled", forChild: false },
        { label: "Client Notes", defaultLabel: "Client Notes", field: "clientNotes", id: id(), state: "enabled", forChild: false },
        { label: "Client Data Entry", defaultLabel: "Client Data Entry", field: "clientDataEntry", id: id(), state: "enabled", forChild: false },
        { label: "Language", defaultLabel: "Language", field: "language", id: id(), state: "enabled", forChild: false },
        { label: "Number of Calls", defaultLabel: "Number of Calls", field: "numCalls", id: id(), state: "enabled", forChild: false },
        { label: "Phone Outcome", defaultLabel: "Phone Outcome", field: "phoneOutcome", id: id(), state: "enabled", forChild: false },
        { label: "Call: Call Attempt %d", defaultLabel: "Call: Call Attempt %d", type: "date", id: id(), state: "enabled", forChild: false},
        { label: "Call: Phone Type %d", defaultLabel: "Call: Phone Type %d", type: "enum", id: id(), state: "enabled", forChild: false, 
            items:["Primary Phone", "Secondary Phone","Alt Phone","TLO Phone","Tevix Phone","Other"]},
        { label: "Call: Call Rep Name %d", defaultLabel: "Call: Call Rep Name %d", type: "autocomplete", id: id(), state: "enabled", forChild: false},
        { label: "SMS: Date of Text Attempt", defaultLabel: "SMS: Date of Text Attempt", type: "date", id: id(), state: "enabled", forChild: false},
        { label: "SMS: Text Rep Name", defaultLabel: "SMS: Text Rep Name", type: "autocomplete", id: id(), state: "enabled", forChild: false},
        { label: "SMS: Outcome of Text Attempt", defaultLabel: "SMS: Outcome of Text Attempt", type: "enum", id: id(), state: "enabled", forChild: false, 
            items:["No Cell #","Message Failed","No Response","Scheduled","Other"]},
        { label: "Email: Date of Email Attempt", defaultLabel: "Email: Date of Email Attempt", type: "date", id: id(), state: "enabled", forChild: false},
        { label: "Email: Email Rep Name", defaultLabel: "Email: Email Rep Name", type: "autocomplete", id: id(), state: "enabled", forChild: false},
        { label: "Email: Outcome of Email Attempt", defaultLabel: "Email: Outcome of Email Attempt", type: "enum", id: id(), state: "enabled", forChild: false, 
            items:["No Email","Message Failed","No Response","Scheduled","Other"]},
        { label: "Scheduled 1st", defaultLabel: "Scheduled 1st", field: "scheduled1", id: id(), state: "enabled", forChild: false },
        { label: "Scheduled Reason 1", defaultLabel: "Scheduled Reason 1", field: "scheduledReason1", id: id(), state: "enabled", forChild: false },
        { label: "Scheduled 2nd", defaultLabel: "Scheduled 2nd", field: "scheduled2", id: id(), state: "enabled", forChild: false },
        { label: "Scheduled Reason 2", defaultLabel: "Scheduled Reason 2", field: "scheduledReason2", id: id(), state: "enabled", forChild: false },
        { label: "Scheduled 3rd", defaultLabel: "Scheduled 3rd", field: "scheduled3", id: id(), state: "enabled", forChild: false },
        { label: "Scheduled Reason 3", defaultLabel: "Scheduled Reason 3", field: "scheduledReason3", id: id(), state: "enabled", forChild: false },
        { label: "Scheduled 4th", defaultLabel: "Scheduled 4th", field: "scheduled4", id: id(), state: "enabled", forChild: false },
        { label: "Scheduled Reason 4", defaultLabel: "Scheduled Reason 4", field: "scheduledReason4", id: id(), state: "enabled", forChild: false },
        { label: "Sent to PCP", defaultLabel: "Sent to PCP", field: "sentToPCP", id: id(), state: "enabled", forChild: false},
        { label: "Notes", defaultLabel: "Notes", field: "notes", id: id(), state: "enabled", forChild: false},
    ],
    measureFields: [
        { label: 'Name', defaultLabel: 'Name', field: 'name', id: id(), state: 'required' },
        { label: 'ComplianceStatus', defaultLabel: 'ComplianceStatus', field: 'complianceStatus', id: id(), state: 'enabled' },
        { label: 'Closed', defaultLabel: 'Closed', field: 'closed', id: id(), state: 'enabled' },
    ],
    checkFields: [
        { label: 'Name', defaultLabel: 'Name', field: 'name', id: id(), state: 'required' },
        { label: 'Done', defaultLabel: 'Done', field: 'done', id: id(), state: 'enabled' },
        { label: 'Positive', defaultLabel: 'Positive', field: 'positive', id: id(), state: 'enabled' },
    ]
}
