import * as React from 'react';
import { useCallback, useState } from 'react';
import { TextField } from '@mui/material';
import GridForm, { GridFormItem } from '../GridForm';
import { LatLng, Patient } from '../../models/core';
import { Place } from '../../models/ziphy';
import SpinnerButton from '../SpinnerButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API as ZiphyAPI } from '../../utils/ZiphyAPI';
import { EnumField } from '../fields';
import { TIME_ZONES } from '../../utils/timezone';
import useSnackbar from '../../hooks/useSnackbar';
import AlertSnack from '../ErrorSnack';
import { capitalize } from '../../utils/slots';
import { parseAddress } from '../../utils/address';


interface Props {
    patient: Patient;
    coords: LatLng;
    practiceId: number;
    onChange?: (place: Place) => void;
}

const ZiphyPlaceForm = ({ patient, coords, practiceId, onChange }: Props): JSX.Element => {

    const [initBuilding, initStreet, initAprt] = React.useMemo(
        () => parseAddress(patient.address?.trim()||''), 
        [patient]
    );

    const [apartment, setApartment] = useState(initAprt.trim());
    const [building, setBuilding] = useState(initBuilding)
    const [city, setCity] = useState(capitalize(patient.city?.trim() || ''));
    const [country, setCountry] = useState('US');
    const [floor, setFloor] = useState('');
    const [latitude, setLatitude] = useState(coords.latitude + '');
    const [longitude, setLongitude] = useState(coords.longitude + '');
    const [state, setState] = useState(patient.state?.trim() || '');
    const [street, setStreet] = useState(capitalize(initStreet.trim()));
    const [timezone, setTimezone] = useState('America/New_York');
    const [zip, setZip] = useState(patient.zip?.trim() || '');
    const [errors, setErrors] = useState<{ [k in keyof Place]?: boolean } & { latitude?: boolean; longitude?: boolean }>({});

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'apartment': setApartment(event.target.value); break;
            case 'building': setBuilding(event.target.value); break;
            case 'city': setCity(event.target.value); break;
            case 'country': setCountry(event.target.value); break;
            case 'floor': setFloor(event.target.value); break;
            case 'latitude': setLatitude(event.target.value); break;
            case 'longitude': setLongitude(event.target.value); break;
            case 'state': setState(event.target.value); break;
            case 'street': setStreet(event.target.value); break;
            case 'zip': setZip(event.target.value); break;
        }
    }, []);

    const onEnumChange = useCallback((label: string, value: string | null) => {
        switch (label) {
            case 'timezone': setTimezone(value || ''); break;
        }
    }, []);

    const { showSuccess: snackSuccess, showError: snackError, ...snackProps } = useSnackbar();
    const queryClient = useQueryClient();
    const savePlaceMutation = useMutation({
        mutationFn: async () =>
            await ZiphyAPI.createPlace(
                {
                    building, street, apartment, floor, city, zip, state, country, timezone,
                    name: `${building} ${street}${apartment ? ' Apt ' + apartment : ''}`
                        + `${floor ? ' Floor ' + floor : ''} ${city} ${state} ${zip}`,
                    coords: [Number.parseFloat(latitude), Number.parseFloat(longitude)]
                },
                practiceId),
        onSuccess: (place) => {
            snackSuccess();
            place && onChange && onChange(place);
            queryClient.invalidateQueries({ queryKey: ['places'] });
        },
        onError: snackError
    });

    const onCreateClick = useCallback(() => {
        const errors = {
            city: !city,
            country: !country,
            state: !state,
            street: !street,
            timezone: !timezone,
            zip: !zip,
            latitude: !latitude,
            longitude: !longitude
        };
        setErrors(errors);
        if (!Object.values(errors).reduce((acc, val) => acc || val)) {
            savePlaceMutation.mutate();
        }
    }, [city, country, latitude, longitude, savePlaceMutation, state, street, timezone, zip]);

    return (
        <GridForm>
            <GridFormItem xs={3}>
                <TextField name='building' label='building' value={building} onChange={onTextFieldChange} variant='standard' required fullWidth />
            </GridFormItem>
            <GridFormItem xs={9}>
                <TextField name='street' label='street' value={street}
                    onChange={onTextFieldChange} variant='standard'
                    error={errors.street} fullWidth />
            </GridFormItem>
            <GridFormItem xs={3}>
                <TextField name='apartment' label='apartment' value={apartment} onChange={onTextFieldChange} variant='standard' fullWidth />
            </GridFormItem>
            <GridFormItem xs={3}>
                <TextField name='floor' label='floor' value={floor} onChange={onTextFieldChange} variant='standard' fullWidth />
            </GridFormItem>
            <GridFormItem xs={6}>
                <TextField name='zip' label='zip' value={zip}
                    onChange={onTextFieldChange} variant='standard'
                    error={errors.zip} required fullWidth />
            </GridFormItem>
            <GridFormItem xs={6}>
                <TextField name='city' label='city' value={city}
                    onChange={onTextFieldChange} variant='standard'
                    error={errors.city} required fullWidth />
            </GridFormItem>
            <GridFormItem xs={3}>
                <TextField name='state' label='state' value={state}
                    onChange={onTextFieldChange} variant='standard'
                    error={errors.state} required fullWidth />
            </GridFormItem>
            <GridFormItem xs={3}>
                <TextField name='country' label='country' value={country}
                    onChange={onTextFieldChange} variant='standard'
                    error={errors.country} required fullWidth />
            </GridFormItem>
            <TextField name='latitude' label='latitude' value={latitude}
                onChange={onTextFieldChange} variant='standard'
                error={errors.latitude} required fullWidth />
            <TextField name='longitude' label='longitude' value={longitude}
                onChange={onTextFieldChange} variant='standard'
                error={errors.longitude} required fullWidth />
            <GridFormItem xs={12}>
                <EnumField
                    name='timezone' label='Time Zone' value={timezone}
                    required onChange={onEnumChange}
                    options={TIME_ZONES}
                />
            </GridFormItem>
            <GridFormItem xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <SpinnerButton showSpinner={savePlaceMutation.isPending} onClick={onCreateClick}>Create</SpinnerButton>
            </GridFormItem>
            <AlertSnack {...snackProps} />
        </GridForm>
    );
}

export default ZiphyPlaceForm;
