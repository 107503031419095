import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Outlet, useLocation } from 'react-router-dom';
import { SheetProvider } from './hooks/useSheet';
import ProtectedRoute from './components/ProtectedRoute';
import LoadingDialog from './components/LoadingDialog';
import {LoadingProvider} from './hooks/useLoading';
import rg4js from 'raygun4js';
import ZipAnalytics, { analytics } from './utils/analytics/zipAnalytics';
import { MixpanelZipAnalyticsBrowser } from './utils/analytics/mixpanel/mixpanelZipAnalyticsBrowser';
import { BuildDateTime, DateTimeStamp } from './utils/PrevalMacros';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError(error) {
        console.warn(error);
        return false;
      },
    }
  }
});

const App = (): JSX.Element => {

  React.useEffect(() => {
    if (window.location.hostname != 'localhost') {
      const host = window.location.hostname.toLowerCase();
      const isStage = (host.indexOf('dev') != -1 || host.indexOf('stage') != -1);
      rg4js('options', { debugMode: false, captureUnhandledRejections: true });
      rg4js('enableCrashReporting', true);
      rg4js('enablePulse', true);
      rg4js('withTags', ['web', (isStage ? 'stage' : 'production')]);
      rg4js('apiKey', 'Ie7Ifb8xwFOL1VGcgUaAvA');
      rg4js('withCustomData', { buildTime: DateTimeStamp });

      analytics.registerModule(ZipAnalytics.MIXPANEL_KEY,
        new MixpanelZipAnalyticsBrowser(
          isStage ? 'f8be12ef894e00f9223028a2b065cb38'
            : '344acd33202a33d68412b9dc3dabdf23', {
          ip: true
        }));
    }
  }, []);

  const location = useLocation();
  React.useEffect(() => {
    analytics.nav(location.pathname + location.search, false);
  }, [location]);

  return (
    <React.Fragment>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <ProtectedRoute>
          <LoadingProvider>
          <SheetProvider>
            <React.StrictMode>
              <Container maxWidth="sm">
                <Outlet />
                {BuildDateTime}
                <LoadingDialog/>
              </Container>
            </React.StrictMode>
          </SheetProvider>
          </LoadingProvider>
        </ProtectedRoute>
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
