import * as React from 'react';
import { useCallback, useState } from 'react';
import { Box, Checkbox, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemProps, ListItemText, Menu, MenuItem, Stack, TextField, ToggleButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StraightenIcon from '@mui/icons-material/Straighten';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NumbersIcon from '@mui/icons-material/Numbers';
import TitleIcon from '@mui/icons-material/Title';
import UndoIcon from '@mui/icons-material/Undo';
import { DragDropContext, Draggable, DropResult, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot, DraggableRubric } from 'react-beautiful-dnd';
import StrictModeDroppable from './StrictModeDroppable';
import { Field, FieldType, ParentField, } from '../../models/core';



type ItemAction = 'up' | 'down' | 'remove';

interface FieldsListItemProps extends ListItemProps {
    field: Field;
    actionClickId: string;
    onTypeClick?: React.MouseEventHandler<HTMLElement>;
    onActionClick?: (id: string, action: ItemAction) => void;
    onRestore?: (id: string) => void;
    onLabelChange?: (id: string, label: string) => void;
    onEnabledChange?: (id: string, value: boolean) => void;
    onforChildChange?: (id: string, value: boolean) => void;
    onEnumItemsChange?: (id: string, value: string) => void;
}
const FieldsListItem = ({
    field,
    actionClickId,
    onTypeClick,
    onActionClick,
    onRestore,
    onLabelChange,
    onEnabledChange,
    onforChildChange,
    onEnumItemsChange,
    ...props
}: FieldsListItemProps): JSX.Element => {
    const [value, setValue] = useState(field.label);
    const [enumItems, setEnumItems] = useState((field.items||[]).join('|'));
    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }, []);
    const onItemsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEnumItems(event.target.value);
    }, []);

    const callActionClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        const attr = event.currentTarget.attributes.getNamedItem('x-action');
        attr && onActionClick && onActionClick(actionClickId, attr.value as ItemAction);
    }, [actionClickId, onActionClick]);

    const callEnabledChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onEnabledChange && onEnabledChange(actionClickId, checked);
    }, [actionClickId, onEnabledChange]);

    const callforChild = useCallback(() => {
        onforChildChange && onforChildChange(actionClickId, !(field as ParentField).forChild);
    }, [actionClickId, field, onforChildChange]);

    const callRestoreClick = useCallback(() => {
        onRestore && onRestore(actionClickId);
    }, [onRestore, actionClickId]);

    const onTextBlur = useCallback(() => {
        onLabelChange && onLabelChange(actionClickId, value);
    }, [actionClickId, onLabelChange, value]);

    const onItemsBlur = useCallback(() => {
        onEnumItemsChange && onEnumItemsChange(actionClickId, enumItems);
    }, [actionClickId, enumItems, onEnumItemsChange]);

    React.useEffect(() => {
        setValue(field.label);
    }, [field.label])

    return <ListItem {...props} >
        <ListItemIcon sx={{ minWidth: 0 }}><DragIndicatorIcon /></ListItemIcon>
        <Stack direction='row' sx={{ maxWidth: '420px' }}>
            <TextField
                variant='standard'
                placeholder={field.label}
                value={value}
                disabled={field.state == 'disabled'}
                onChange={onChange}
                onBlur={onTextBlur}
                sx={{maxWidth: '160px'}}
                InputProps={field.type ? undefined : {
                    endAdornment: <InputAdornment position='end' sx={{ visibility: (value && value != field.defaultLabel) ? 'visible' : 'hidden' }}>
                        <IconButton sx={{ borderRadius: 1 }} title='Restore Default' onClick={callRestoreClick}>
                            <UndoIcon fontSize='small' />
                        </IconButton>
                    </InputAdornment>
                }}
            />
            {field.type
                ? <IconButton sx={{ borderRadius: 1 }} title='Field Type' onClick={onTypeClick}>
                    {{
                        string: <TitleIcon />,
                        number: <NumbersIcon />,
                        boolean: <CheckIcon />,
                        date: <CalendarMonthIcon />,
                        enum: <FormatListBulletedIcon />,
                        autocomplete: <FormatListBulletedIcon />
                    }[field.type]}
                    <ArrowDropDownIcon />
                </IconButton>
                : <Box sx={{ width: '64px' }} />}
            {Object.hasOwn(field, 'forChild')
                ? <ToggleButton
                    value='check'
                    color='primary'
                    title='Use in Measures sheet'
                    selected={(field as ParentField).forChild}
                    disabled={field.state == 'disabled'}
                    sx={{ border: 'none', padding: '0 6px', '&.Mui-disabled': { border: 'none' } }}
                    onChange={callforChild}>
                    <StraightenIcon fontSize='small' />
                </ToggleButton>
                : null
            }
            {(field.type == 'enum' || field.type == 'autocomplete')
                ? <TextField
                    variant='standard'
                    placeholder='Enum Items'
                    value={enumItems}
                    disabled={field.state == 'disabled'}
                    onChange={onItemsChange}
                    onBlur={onItemsBlur}
                    sx={{maxWidth: '160px'}}
                />
                : null
            }
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        {field.state == 'required'
            ? null
            : field.defaultLabel
                ? <Checkbox checked={field.state == 'enabled'} title='Use field' onChange={callEnabledChange} />
                : <IconButton
                    onClick={callActionClick}
                    title='Remove'
                    x-action='remove'
                ><ClearIcon /></IconButton>
        }
    </ListItem>
}

interface FieldListProps<T extends Field> {
    fields: T[];
    factory: () => T;
    onChange?: (fields: T[]) => void;
}

const FieldsList = <T extends Field,>({ fields, factory, onChange }: FieldListProps<T>): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const onActionClick = useCallback((id: string, action: ItemAction) => {
        const index = fields.findIndex(f => f.id == id);
        switch (action) {
            case 'up':
                onChange && onChange(fields.map((f, i) => i == index ? fields[i - 1] : (i == index - 1 ? fields[i + 1] : f)))
                break;

            case 'down':
                onChange && onChange(fields.map((f, i) => i == index ? fields[i + 1] : (i == index + 1 ? fields[i - 1] : f)))
                break;

            case 'remove':
                onChange && onChange(fields.filter((_, i) => i != index))
                break;
        }
    }, [fields, onChange]);

    const onRestoreClick = useCallback((id: string) => {
        onChange && onChange(fields.map(f => f.id == id ? { ...f, label: f.defaultLabel || '' } : f))
    }, [fields, onChange]);

    const onLabelChange = useCallback((id: string, label: string) => {
        onChange && onChange(fields.map(f => f.id == id ? { ...f, label: label } : f))
    }, [fields, onChange])

    const onEnabledChange = useCallback((id: string, value: boolean) => {
        onChange && onChange(fields.map(f => f.id == id ? { ...f, state: value ? 'enabled' : 'disabled' } : f))
    }, [fields, onChange]);

    const onforChildChange = useCallback((id: string, value: boolean) => {
        onChange && onChange(fields.map(f => f.id == id ? { ...f, forChild: value } : f))
    }, [fields, onChange]);

    const onEnumItemsChange = useCallback((id: string, value: string) => {
        onChange && onChange(fields.map(f => f.id == id ? { ...f, items: value.split('|') } : f))
    }, [fields, onChange]);

    const getAttr = useCallback((el: HTMLElement, name: string): Attr | null => {
        const attr = el.attributes.getNamedItem(name);
        return attr || el.parentElement && getAttr(el.parentElement, name);
    }, []);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        const typeAttr = event.currentTarget.attributes.getNamedItem('x-value');
        const idAttr = anchorEl && getAttr(anchorEl, 'x-id');
        typeAttr && idAttr && onChange
            && onChange(fields.map((f) => (
                f.id == idAttr.value
                    ? { ...f, type: typeAttr.value as FieldType }
                    : f
            )));
        setAnchorEl(null);
    }, [anchorEl, fields, getAttr, onChange]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onFieldTypeClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onDragEnd = useCallback((result: DropResult) => {
        if (onChange && result.destination) {
            const list = Array.from(fields);
            const [removed] = list.splice(result.source.index, 1);
            list.splice(result.destination.index, 0, removed);
            onChange(list);
        }
    }, [onChange, fields]);

    const onAddFieldClick = useCallback(() => {
        onChange && onChange([...fields, factory()])
    }, [fields, factory, onChange]);

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId='list'>
                    {(provided: DroppableProvided, _snapshot: DroppableStateSnapshot) => (

                        <List dense {...provided.droppableProps} ref={provided.innerRef} sx={{ pb: 0 }}>
                            {fields.map((field, index) => (
                                <Draggable
                                    key={field.id} draggableId={field.id} index={index}>
                                    {(provided: DraggableProvided, _snapshot: DraggableStateSnapshot, _rubric: DraggableRubric) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >

                                            <FieldsListItem
                                                field={field}
                                                onActionClick={onActionClick}
                                                actionClickId={field.id}
                                                onRestore={onRestoreClick}
                                                onTypeClick={onFieldTypeClick}
                                                onLabelChange={onLabelChange}
                                                onEnabledChange={onEnabledChange}
                                                onforChildChange={onforChildChange}
                                                onEnumItemsChange={onEnumItemsChange}
                                                x-id={field.id}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </StrictModeDroppable>
            </DragDropContext>

            <Box display='flex' justifyContent='end' paddingRight='18px'>
                <IconButton color='primary' title='Add field' onClick={onAddFieldClick}>
                    <AddIcon />
                </IconButton>
            </Box>

            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} >
                <MenuItem x-value='string' onClick={handleClick}>
                    <ListItemIcon><TitleIcon /></ListItemIcon>
                    <ListItemText>Text</ListItemText>
                </MenuItem>
                <MenuItem x-value='number' onClick={handleClick}>
                    <ListItemIcon><NumbersIcon /></ListItemIcon>
                    <ListItemText>Number</ListItemText>
                </MenuItem>
                <MenuItem x-value='boolean' onClick={handleClick}>
                    <ListItemIcon><CheckIcon /></ListItemIcon>
                    <ListItemText>Boolean</ListItemText>
                </MenuItem>
                <MenuItem x-value='date' onClick={handleClick}>
                    <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
                    <ListItemText>Date</ListItemText>
                </MenuItem>
                <MenuItem x-value='enum' onClick={handleClick}>
                    <ListItemIcon><FormatListBulletedIcon /></ListItemIcon>
                    <ListItemText>Enum</ListItemText>
                </MenuItem>
                <MenuItem x-value='autocomplete' onClick={handleClick}>
                    <ListItemIcon><FormatListBulletedIcon /></ListItemIcon>
                    <ListItemText>Autocomplete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}

export const PatientFieldsList = React.memo(FieldsList<ParentField>);
export const MeasureFieldsList = React.memo(FieldsList<Field>);
