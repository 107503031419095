import * as React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

interface Props {
    children: JSX.Element | JSX.Element[];
}

const ProtectedRoute = ({ children }: Props): JSX.Element | null => {
    const auth = useAuth();
    const location = useLocation();
    const [isActive, setIsActive] = React.useState<boolean | null>(null);

    React.useEffect(() => {
        const fetch = async () => {
            setIsActive(auth && (await auth.isActive()) || false);
        };
        // 45 sec assuming 1 min token lifetimr
        const interval = setInterval(fetch, 45000); 
        fetch().catch(console.error);
        return () => {
            clearInterval(interval);
        }
    }, [auth]);


    return (isActive)
        ? <>{children}</>
        : (isActive == false
            ? <Navigate to="/auth/login" replace state={{ from: location }} />
            : null
        )
};

export default ProtectedRoute;