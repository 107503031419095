import React, { useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, FormHelperText,
    TableHead, TableRow, Paper, TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddIcon from '@mui/icons-material/Add';


interface TextTableProps<T> {
  rows: T[];
  columns: { key: keyof T; label: string, visible?:boolean, entries?:{key:string,value?:string}[], type?:'text'|'bool'|'enum' }[];
  addRowHeader?:string|JSX.Element|JSX.Element[]
  onAddRow: (row: T) => void;
  canAddRow?: (row:T, cbk:()=>void) => {key:string;label:string}[]|undefined|null;
  onUpdateRow?: (row: T) => void;
  onDeleteRow?: (row: T) => void;
}

const TextTable = <T extends Record<string, any>>({ rows, columns, addRowHeader, onAddRow, 
    onUpdateRow, onDeleteRow, canAddRow }: TextTableProps<T>) => {
    const [newRow, setNewRow] = useState<T>(() => {
        const initialRow: any = {};
        columns.forEach(column => {
        initialRow[column.key as string] = '';
        });
        return initialRow as T;
    });

    const [unlockedRows, setUnlockedRows] = useState<boolean[]>([]);
    const [errorState, setErrorState] = useState<{key:string;label:string}[]|null|undefined>(null);
    const spanSize = 85;
    const actionSize = 15;

    const onEditChangeNewRow = React.useCallback((value:string, fieldName: keyof T) => {
        setNewRow({ ...newRow, [fieldName]: value });
        setErrorState(undefined);
    }, [setNewRow,setErrorState,newRow]);

    const handleUpdateRow = React.useCallback((value: string, fieldName: keyof T, entry:T) => {
        onUpdateRow && onUpdateRow({...entry,[fieldName]: value })
    }, [onUpdateRow]);

    const pushNewRow = React.useCallback((newRow:T) => {
        onAddRow(newRow);
        setUnlockedRows([...unlockedRows, false]);
        setNewRow(() => {
            const newRow: any = {};
            columns.forEach(column => { newRow[column.key as string] = ''; });
            return newRow as T;
        });
    },[onAddRow,setUnlockedRows,setNewRow,columns,unlockedRows]);

    const handleAddRow = React.useCallback(() => {
        canAddRow ? setErrorState(canAddRow(newRow, () => { pushNewRow(newRow); })) : pushNewRow(newRow); 
    }, [canAddRow, pushNewRow, newRow]);

    const handleDeleteRow = (entry:T, index:number) => {
        onDeleteRow && onDeleteRow(entry);
        setUnlockedRows(unlockedRows.filter((r,i) => i !== index));
    };

    const handleToggleRow = (index:number) => {
        setUnlockedRows(prevArray => {
            const newArray = [...prevArray];
            newArray[index] = !unlockedRows[index];
            return newArray;
        });
    };

    const addContentList = React.useMemo(() => {
            return columns.map(column => {
                const st = errorState?.find((i) => i.key == column.key);
                const sizePer = `${(actionSize / columns.length)+'%'}`;
                if(column.type == 'enum') {
                  return (
                  <TableCell key={column.key as string} sx={{width:sizePer}}>
                    <FormControl variant='standard' error={st != null} fullWidth>
                        <InputLabel>{column.label}</InputLabel>
                        <Select name={column.key as string} value={newRow[column.key]}
                          sx={{
                            width:'100%', maxWidth:'100%', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'
                          }}
                          onChange={(e) => onEditChangeNewRow(e.target.value, column.key)}>
                              {
                                column?.entries?.map((entry, i) => (
                                  <MenuItem key={`item-${i}`} value={entry.value||entry.key}>{entry.key}</MenuItem>
                                ))
                              }
                        </Select>
                        { st?.label ? <FormHelperText>{st?.label}</FormHelperText> : <></> }
                    </FormControl>
                    </TableCell>);
                } else if(column.type == 'bool') {
                  return <></>
                }
                return (<TableCell key={column.key as string} sx={{width:sizePer}}>
                  <TextField
                      error={st != null}
                      helperText={st?.label}
                      value={newRow[column.key]}
                      onChange={(e) => onEditChangeNewRow(e.target.value, column.key)}
                      label={column.label}
                      fullWidth
                  />
                </TableCell>);
          })
    }, [columns, errorState, onEditChangeNewRow, newRow])

  return (
    <div>
        <TableContainer component={Paper}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              {
                columns.map(column => {
                  const sizePer = `${(spanSize / columns.length)+'%'}`;
                  return <TableCell key={column.key as string} sx={{width:sizePer}}><b>{column.label}</b></TableCell>
                })
              }
              <TableCell key='tc-aheader' sx={{width:`${actionSize+'%'}`}}/>
            </TableRow>
          </TableHead>
          </Table>
          </TableContainer>

    <div style={{ minHeight: '10px', maxHeight:'300px', overflow: 'auto' }}>
      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {
                    columns.map(column => {
                        const sizePer = `${(spanSize / columns.length)+'%'}`;
                        const isLocked = !unlockedRows[rowIndex];
                        if(!column.type || column.type == 'text') {
                            return (<TableCell align='left' key={column.key as string} sx={{width:sizePer}}>{
                                <TextField value={row[column.key]} onChange={(e) => handleUpdateRow(e.target.value, column.key, row)} variant="standard" 
                                    fullWidth multiline inputProps={{ readOnly: isLocked }}/>
                            }</TableCell>)
                        } else if(column.type == 'enum') {
                          if(isLocked) {
                            return (<TableCell align='left' key={column.key as string} sx={{width:sizePer}}>{
                              <TextField value={column.entries?.find(entry => row[column.key] === entry.value)?.key || row[column.key]} 
                                onChange={(e) => handleUpdateRow(e.target.value, column.key, row)} variant="standard" 
                                  fullWidth multiline inputProps={{ readOnly: true }}/>
                              }</TableCell>)
                          }
                          return (<TableCell key={column.key as string} sx={{width:sizePer}}>
                            <FormControl variant='standard' fullWidth>
                                <Select name={column.key as string} value={row[column.key]}
                                    onChange={(e) => handleUpdateRow(e.target.value, column.key, row)} fullWidth>
                                    {
                                      column?.entries?.map((entry, i) => (
                                        <MenuItem key={`et-${i}`} value={entry.value || entry.key}>{entry.key}</MenuItem>
                                      ))
                                    }
                                </Select>
                            </FormControl>
                            </TableCell>);
                        } 
                    })
                }
                <TableCell align='right' key='r-ac' sx={{width:`${actionSize+'%'}`}}>
                    {
                        onUpdateRow ? 
                            <IconButton onClick={() => handleToggleRow(rowIndex)}>{unlockedRows[rowIndex] ? <LockOpenIcon/> : <LockIcon/>}</IconButton>
                            : null
                    }
                    {
                        onDeleteRow ?
                            <IconButton onClick={() => handleDeleteRow(row, rowIndex)}><DeleteIcon/></IconButton>
                            : null
                    }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

    <TableContainer component={Paper}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableBody>
            {
                addRowHeader != undefined ? ( 
                <TableRow key={'ttssax'}>
                    <TableCell colSpan={columns.length} sx={{width:`${spanSize+'%'}`}} align='left' key='ttssax'>
                        {addRowHeader}
                    </TableCell>
                    <TableCell sx={{width:`${actionSize+'%'}`}}/>
                </TableRow> )
                : <></>
            }
            <TableRow>
              { addContentList }
              <TableCell align='right' sx={{width:`${actionSize+'%'}`}}>
                <IconButton onClick={handleAddRow}><AddIcon/></IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TextTable;