import * as React from 'react';
import { AccordionDetailsProps, AccordionProps, AccordionSummaryProps, Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const Accordion = (props: AccordionProps): JSX.Element => (
    <MuiAccordion {...props} disableGutters elevation={0} square />
)

export const AccordionSummary = (props: AccordionSummaryProps): JSX.Element => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0 }} {...props}/>
)

export const AccordionDetails = (props: AccordionDetailsProps): JSX.Element => (
    <MuiAccordionDetails sx={{ padding: 0 }} {...props} />
)