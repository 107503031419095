import * as React from 'react';
import { useCallback, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, MenuItem, Select, SelectChangeEvent, Snackbar, Stack, SvgIconTypeMap, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExploreIcon from '@mui/icons-material/Explore';
import SearchIcon from '@mui/icons-material/Search';
import TableRowsIcon from '@mui/icons-material/TableRows';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Client, ClusterJob, ClusterJobParams, ClusterJobResult, JobStatus, Plan } from '../models/core';
import { DateTime } from 'luxon';
import useJob from '../hooks/useJob';
import { API } from '../utils/Api';
import { useTheme } from '@mui/material';
import ClusterMap from './ClusterMap';
import { Wrapper } from '@googlemaps/react-wrapper';
import { VisitStatuses } from '../utils/enums';
import FiltersPanel, { FilterField } from './FiltersPanel';
import cluster from 'cluster';
import ConfirmDialog from './ConfirmDialog';
import debounce from 'lodash.debounce';
import { InfoButton } from './InfoButton';
import { useJobsList } from '../hooks/useJobsList';

const ClustersTableView = ({ result }: { result?: ClusterJobResult }): JSX.Element => {
    const tableColumns = React.useMemo(() =>
        ['INSURANCE ID', 'Member First Name', 'Member Last Name', 'Member Address 1', 'MemberCity', 'MemberState', 'MemberZip', 'MemberPhone 1', 'MemberPhone 2', 'Cluster Index', 'Points in Cluster', `Cluster Size (${result?.clusters[0]?.radius?.units})`]
        , [result]);
    const tableContent = React.useMemo(() =>
        result?.clusters?.flatMap(({ points, radius }, i) =>
            points.map(pt =>
                [
                    pt.patient?.refId, pt.patient?.firstName,
                    pt.patient?.lastName, pt.patient?.address,
                    pt.patient?.city, pt.patient?.state, pt.patient?.zip,
                    pt.patient?.phone, pt.patient?.phone2, i + 1, points.length,
                    `${radius.value?.toFixed(2)}`
                ] as string[]
            )
        ) || []
        , [result]);
    return <Box sx={{
        maxHeight: '640px',
        overflowY: 'auto',
        table: {
            margin: '8px',
            whiteSpace: 'nowrap',
            'td,th': {
                borderTop: `1px dotted gray`,
                borderLeft: `1px dotted gray`,
                padding: '0 4px'
            },
            'td:last-child, th:last-child': {
                borderRight: `1px dotted gray`,
            },
            'tr:last-child td': {
                borderBottom: `1px dotted gray`,
            }
        }
    }}>
        <table>
            <thead>
                <tr>
                    {tableColumns.map((col, i) =>
                        <th key={i}>{col}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {tableContent.map((row, i) =>
                    <tr key={i}>
                        {row.map((cell, j) =>
                            <td key={j}>{cell}</td>
                        )}
                    </tr>

                )}
            </tbody>
        </table>
    </Box>
}

const ResultView = ({ result, time }: { result: ClusterJobResult, time?: string }): JSX.Element => {
    const [viewMode, setViewMode] = useState('table');

    const onViewModeChange = useCallback((event: React.MouseEvent<HTMLElement>, mode: string | null) => {
        setViewMode(mode || 'table');
    }, []);

    return <>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '4px' }}>
            {
                time && <Typography variant='body1'>{time}</Typography>
            }
            <div style={{ flexGrow: 1 }}></div>
            <ToggleButtonGroup value={viewMode} exclusive onChange={onViewModeChange}>
                <ToggleButton value={'table'}><TableRowsIcon /></ToggleButton>
                <ToggleButton value={'map'}><ExploreIcon /></ToggleButton>
            </ToggleButtonGroup>
        </Box>
        <Box sx={{ width: '800px', height: '640px' }}>
            {viewMode == 'table'
                ? <ClustersTableView result={result} />
                :
                <Wrapper
                    id='google-maps'
                    apiKey='AIzaSyCyZ3l5G1h3MX75YMbxH0_fkt_G5fyKa8A'
                    libraries={['places']}
                >
                    <ClusterMap result={result} />
                </Wrapper>
            }
        </Box>
    </>
}

export interface ClusterDialogProps extends DialogProps {
    open: boolean;
    jobs: ClusterJob[];
    client: Client;
    plans: Plan[];
    onClose?: () => void;
}
const NoContentBox = ({ children }: { children?: React.ReactNode }): JSX.Element => (
    <Box sx={{
        height: '692px',
        border: '2px solid #cad8ff',
        color: '#7d94d3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        'svg': {
            fontSize: '3em',
            marginBottom: '0.3em'
        }
    }}>
        {children}
    </Box>
)

const MATCHED_PATIENTS_WARN = 1000;
const MATCHED_PATIENTS_ERROR = 2000;

const ClusterDialog = ({ open, client, plans, jobs, onClose, ...props }: ClusterDialogProps): JSX.Element => {
    const { lastJob, lastSuccJob, restart } = useJob<ClusterJob>(client._id, open, jobs);
    const [minSize, setMinSize] = useState(lastJob?.params.minSize || 5);
    const [maxSize, setMaxSize] = useState(lastJob?.params.maxSize || 25);
    const [minPoints, setMinPoints] = useState(lastJob?.params.minPoints || 5);
    const [maxPoints, setMaxPoints] = useState(lastJob?.params.maxPoints || 25);
    const [units, setUnits] = useState(lastJob?.params.units || 'mi');
    const [snackOpen, setSnackOpen] = useState(false);
    const [filteredCount, setFilteredCount] = useState<number | null>(null);
    const theme = useTheme();
    const [filterValue, setFilterValue] = useState<{ [k: string]: string | string[] }>(() => ({
        'city': '',
        'customFields.County': ['all'],
        'planId': ['all'],
        'customFields.RiskCategory': ['all'],
        'visit.status': ['all'],
    }));
    const [cities, setCities] = useState<string[]>([]);
    const [counties, setCounties] = useState<string[]>([]);

    React.useEffect(() => {
        setMinSize(lastJob?.params.minSize || 5);
        setMaxSize(lastJob?.params.maxSize || 25);
        setMinPoints(lastJob?.params.minPoints || 5);
        setMaxPoints(lastJob?.params.maxPoints || 25);
        setUnits(lastJob?.params.units || 'mi');
    }, [lastJob]);

    React.useEffect(() => {
        const fetch = async () => {
            const [cities, counties] = await Promise.all([
                API.getPatientValues(client._id, 'city'),
                API.getPatientValues(client._id, 'customFields.county'),
            ]);
            setCities(cities.filter(Boolean));
            setCounties(counties.filter(Boolean));
        };
        if (open) {
            fetch().catch(console.error);
        }
    }, [client, open])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case 'min-size': setMinSize(Number.parseFloat(event.target.value)); break;
            case 'max-size': setMaxSize(Number.parseFloat(event.target.value)); break;
            case 'min-points': setMinPoints(Number.parseInt(event.target.value)); break;
            case 'max-points': setMaxPoints(Number.parseInt(event.target.value)); break;
        }
    }, []);

    const onSelectChange = useCallback((event: SelectChangeEvent) => {
        setUnits(event.target.value);
    }, []);

    const onRunClick = useCallback(async () => {
        const params = {
            maxPoints, maxSize, minPoints, minSize, units,
            filter: Object.entries({
                ...filterValue,
                'planId': filterValue['planId'].includes('all')
                    ? ['all']
                    : plans.filter(p => filterValue['planId'].includes(p.name)).map(p => p._id),
            })
                .filter(([_, values]) => !['', 'all'].includes((values as string[]).join('')))
                .map(([field, values]) => ({ field, values }))
        } as ClusterJobParams;
        let jobId = '';
        if (lastJob) {
            await API.updateJob({ ...lastJob, params } as ClusterJob)
            jobId = lastJob._id;
        }
        else {
            jobId = (await API.createJob({
                clientId: client._id,
                type: 'cluster',
                name: "New Job",
                params
            } as Omit<ClusterJob, '_id'>)).insertedId;
        }
        await API.runJob(client._id, jobId);
        restart(false);
    }, [maxPoints, maxSize, minPoints, minSize, units, filterValue, plans, lastJob, client._id, restart]);

    const onStopClick = useCallback(async () => {
        lastJob && await API.terminateJob(client._id, lastJob);
    }, [client, lastJob]);

    const tableColumns = React.useMemo(() =>
        ['INSURANCE ID', 'Member First Name', 'Member Last Name', 'Member Address 1', 'MemberCity', 'MemberState', 'MemberZip', 'MemberPhone 1', 'MemberPhone 2', 'Cluster Index', 'Points in Cluster', `Cluster Size (${lastSuccJob?.result?.clusters ? lastSuccJob?.result?.clusters[0]?.radius?.units : 'None'})`]
        , [lastSuccJob]);
    const tableContent = React.useMemo(() => {
        return lastSuccJob?.result?.clusters?.flatMap(({ points, radius }, i) =>
            points.map(pt =>
                [
                    pt.patient?.refId, pt.patient?.firstName,
                    pt.patient?.lastName, pt.patient?.address,
                    pt.patient?.city, pt.patient?.state, pt.patient?.zip,
                    pt.patient?.phone, pt.patient?.phone2, i + 1, points.length,
                    radius.value?.toFixed(2)
                ] as string[]
            )
        ) || [];
    }, [lastSuccJob])

    const onExportClick = useCallback(async () => {
        navigator.clipboard.writeText(
            '"' + tableColumns.join('","') + '"\n' +
            tableContent?.map(row => '"' + row.join('","') + '"').join('\n')
        );
        setSnackOpen(true);
    }, [tableColumns, tableContent]);

    const onSnackClose = useCallback((event: React.SyntheticEvent | Event, reason: string) => {
        if (reason != 'clickaway') {
            setSnackOpen(false);
        }
    }, []);

    React.useEffect(() => {
        if (!open) {
            setSnackOpen(false);
        }
    }, [open]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetch = React.useCallback(debounce(async (filterValue: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string {
            return x == 'all' ? '' : (x instanceof Array) ? (x.includes('all') ? 'all' : x.join('|')) : x;
        }
        const patients = await API.getPatients(
            client._id,
            Object.entries({
                ...filterValue,
                'planId': filterValue['planId'].includes('all')
                    ? ['all'] // for maybeJoin
                    : plans.filter(p => filterValue['planId'].includes(p.name)).map(p => p._id)
            }).map<[string, string]>(([k, v]) => [k, maybeJoin(v)]).filter(([_, v]) => !!v),
            10, 0, [['name', 1]]
        );
        setFilteredCount(patients.count);
    }, 250), [client]);

    React.useEffect(() => {
        fetch(filterValue);
    }, [fetch, filterValue]);

    const onFiltersChange = React.useCallback((value: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string | string[] {
            return (x instanceof Array && x.includes('all')) ? ['all'] : x;
        }
        setFilterValue({
            'city': maybeJoin(value['city']) || ['all'],
            'customFields.County': maybeJoin(value['customFields.County']) || ['all'],
            'planId': maybeJoin(value['planId']) || ['all'],
            'customFields.RiskCategory': maybeJoin(value['customFields.RiskCategory']) || ['all'],
            'visit.status': maybeJoin(value['visit.status']) || ['all'],
        })
    }, []);

    const filterFields = React.useMemo<FilterField[]>(() => [
        { name: 'city', type: 'autocomplete', items: cities, emptyLabel: 'Any City', width: 6 },
        {
            name: 'customFields.County', type: 'enum', emptyLabel: 'Any County',
            items: counties,
            disabled: !client.layout.patientFields.find(f => f.label == 'County'), width: 6
        },
        { name: 'planId', type: 'enum', items: plans.map(p => p.name), emptyLabel: 'Any Plan', width: 6 },
        {
            name: 'customFields.RiskCategory', type: 'enum', items: ['Top 1%', 'Top 2-5%', 'Top 6-20%', 'Bottom 80%'], emptyLabel: 'Any Risk',
            disabled: !client.layout.patientFields.find(f => f.label == 'RiskCategory'), width: 6
        },
        {
            name: 'visit.status', type: 'enum', items: VisitStatuses,
            emptyLabel: 'Any Status', width: 6
        },
    ], [cities, client.layout.patientFields, counties, plans]);

    return (
        <Dialog {...props} open={open} onClose={onClose} PaperProps={{ sx: { maxWidth: '1200px' } }}>
            <DialogTitle>Cluster</DialogTitle>
            <DialogContent>
                <Stack direction='row' width='800px'>
                    <Stack direction='column'>
                        <FiltersPanel padding={0} marginBottom='16px' direction='row' fields={filterFields} defaultValue={filterValue} onChange={onFiltersChange} />
                        <Typography sx={{
                            visibility: filteredCount == null ? 'hidden' : 'visible',
                            marginBottom: '16px',
                            color: (filteredCount || 0) > MATCHED_PATIENTS_ERROR
                                ? theme.palette.error.main
                                : (filteredCount || 0) > MATCHED_PATIENTS_WARN
                                    ? theme.palette.warning.main
                                    : undefined
                        }}>Matched {filteredCount || 0} patients{
                                (filteredCount || 0) > MATCHED_PATIENTS_ERROR
                                    ? '. Apply more filters to reduce match count.'
                                    : (filteredCount || 0) > MATCHED_PATIENTS_WARN
                                        ? '. Clustering will be slow.'
                                        : undefined

                            }</Typography>
                        <Stack direction='row' sx={{
                            alignItems: 'baseline',
                            '.MuiFormControl-root': {
                                width: '3em'
                            },
                            'span': {
                                marginLeft: '4px',
                                marginRight: '4px'
                            },
                            '.MuiGrid-item': {
                                display: 'flex',
                                alignItems: 'baseline'
                            },
                            'input[type="text"]': {
                                textAlign: 'right',
                                paddingRight: '4px'
                            }
                        }}>
                            <Typography>Cluster size</Typography>
                            <InfoButton sx={{ alignSelf: 'center', cursor: 'help', color: theme.palette.text.secondary }}>
                                <Typography sx={{ p: 1 }}>Diameter of cluster</Typography>
                            </InfoButton>
                            <TextField name='min-size' type='text' inputMode='numeric' value={minSize} variant='standard' onChange={onTextFieldChange} />
                            <Typography margin='0 4px'>to</Typography>
                            <TextField name='max-size' type='text' inputMode='numeric' value={maxSize} variant='standard' onChange={onTextFieldChange} />
                            <Select name='unit' value={units} variant='standard' onChange={onSelectChange} sx={{ marginLeft: '8px' }}>
                                <MenuItem value='mi'>mi</MenuItem>
                                <MenuItem value='km'>km</MenuItem>
                            </Select>
                            <Typography marginLeft='16px'>Points in cluster</Typography>
                            <InfoButton sx={{ alignSelf: 'center', cursor: 'help', color: theme.palette.text.secondary }}>
                                <Typography sx={{ p: 1 }}>Number of points in cluster</Typography>
                            </InfoButton>
                            <TextField name='min-points' type='text' inputMode='numeric' value={minPoints} variant='standard' onChange={onTextFieldChange} />
                            <Typography margin='0 4px'>to</Typography>
                            <TextField name='max-points' type='text' inputMode='numeric' value={maxPoints} variant='standard' onChange={onTextFieldChange} />
                        </Stack>
                    </Stack>
                    <Stack direction='column' width='15em' justifyContent='center'>
                        <Box display='flex' sx={{ visibility: lastJob ? 'visible' : 'hidden' }}>
                            <CalendarMonthIcon />
                            <Typography variant='body1' component='span'>{lastJob?.updated && DateTime.fromISO(lastJob.updated).toLocaleString(DateTime.DATETIME_SHORT) || ''}</Typography>
                        </Box>
                        <Box color={
                            lastJob?.status == JobStatus.SUCCESS ? theme.palette.success.main
                                : lastJob?.status == JobStatus.FAIL ? theme.palette.error.main
                                    : theme.palette.info.main
                        } display='flex' sx={{ visibility: lastJob ? 'visible' : 'hidden' }}>
                            {lastJob?.status == JobStatus.SUCCESS ? <CheckCircleOutlineIcon />
                                : lastJob?.status == JobStatus.FAIL ? <ErrorOutlineIcon />
                                    : <AccessTimeIcon />
                            }
                            <Typography variant='body1' component='span'>{lastJob?.status || ''}</Typography>
                        </Box>
                        {lastJob == null || lastJob?.status == JobStatus.FAIL || lastJob?.status == JobStatus.SUCCESS
                            ? <Button onClick={onRunClick} disabled={(filteredCount || 0) >= MATCHED_PATIENTS_ERROR}>Run</Button>
                            : <Button onClick={onStopClick} disabled={lastJob?.status == JobStatus.TERMINATING} >Stop</Button>
                        }
                        <Button onClick={onExportClick} disabled={(lastSuccJob?.result?.clusters?.length || 0) == 0}>Export</Button>
                    </Stack>
                </Stack>

                <Box sx={{ height: '16px' }}></Box>
                {lastSuccJob && lastSuccJob.result?.clusters?.length > 0
                    ? <ResultView
                        result={lastSuccJob?.result}
                        time={
                            lastSuccJob?.updated
                            && lastSuccJob.updated != lastJob?.updated
                            && DateTime.fromISO(lastSuccJob.updated).toLocaleString(DateTime.DATETIME_SHORT)
                            || undefined
                        }
                    />
                    : <NoContentBox>
                        {!lastSuccJob?.result?.clusters || lastSuccJob?.result?.clusters?.length == 0
                            ? <>
                                <SearchIcon />
                                No visits match specified filters.
                                <br />Try to relax conditions.
                            </>
                            : <>
                                <WorkspacesIcon />
                                There is no clustering for this client.
                                <br />Let&apos;s create one!
                            </>
                        }
                    </NoContentBox>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>OK</Button>
            </DialogActions>
            <Snackbar
                open={snackOpen}
                autoHideDuration={3000}
                onClose={onSnackClose}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                message='Table was copied to clipboard'
            />
        </Dialog >
    )
}

export default ClusterDialog;