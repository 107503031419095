import React from 'react';
import { Dialog, DialogContent, CircularProgress, Box,DialogProps } from '@mui/material';

export interface BusyDialogProps extends DialogProps {
    open: boolean;
}


const BusyDialog = ({ open }: BusyDialogProps): JSX.Element => {
    return (
      <Dialog open={open}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress size={50} />
          </Box>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default BusyDialog;