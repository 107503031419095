import * as React from 'react';
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Checkbox } from '@mui/material';
import { useCallback, useState } from 'react';
import { Check, Client, Patient, Plan, Visit } from '../models/core';
import { API } from "../utils/Api";
import CustomFields from './CustomFields';
import EntityEditor, { EntityEditorProps } from './EntityEditor';
import { GridFormItem } from './GridForm';


interface Props extends Omit<EntityEditorProps, 'title'|'doSave'> {
    client: Client;
    patient: Patient;
    visit: Visit;
    check: Check | null;
    plan: Plan | null;
}

const CheckEditor = ({ client, patient, visit, check, plan, ...props }: Props): JSX.Element => {
    const checks = React.useMemo(() => {
        let result = patient.checks || [];
        result.length == 0 && (result = (plan && plan.checks || []));
        result.length == 0 && (result = client.checks || []);
        if (check && !result.includes(check.name)) {
            result = [check.name, ...result];
        }
        return result;
    }, [patient.checks, plan, client.checks, check]);
    const [name, setName] = useState(check && check.name || '');
    const [done, setDone] = useState(check?.done);
    const [positive, setPositive] = useState(check?.positive);
    const [customFields, setCustomFields] = useState(check && check.customFields || {});

    const [errors, setErrors] = useState<{ [k in keyof Check]?: boolean }>({});
    const onSelectChange = useCallback((event: SelectChangeEvent<string>) => {
        switch (event.target.name) {
            case 'name': setName(event.target.value); break;
            default:
                break;
        }
    }, []);
    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'done': setDone(event.target.checked); break;
            case 'positive': setPositive(event.target.checked); break;
            default:
                break;
        }
    }, []);

    const onCustomFiendsChange = useCallback((value: { [k: string]: string | number | Date | boolean | undefined }) => {
        setCustomFields(Object.fromEntries(Object.entries(value).filter(([, v]) => v).map(([k, v]) => [k, v || ''])));
    }, [setCustomFields]);

    const saveCheck = useCallback(async () => {
        const errors = {
            name: !name
        };
        setErrors(errors);
        if (Object.values(errors).reduce((acc, val) => acc || val)) {
            return false;
        }
        const data: Omit<Check, '_id'> = {
            clientId: visit.clientId,
            patientId: visit.patientId,
            visitId: visit._id,
            name,
            done: !!done,
            positive: !!positive,
            customFields
        }

        if (check) {
            await API.updateCheck({ ...check, ...data });
        }
        else {
            await API.createCheck(data);
        }
        return true;
    }, [check, customFields, done, name, positive, visit]);

    return (
        <EntityEditor {...props} title={check ? 'Edit Check' : 'New Check'} doSave={saveCheck} >
            <GridFormItem xs={12}>
                <FormControl variant='standard' error={errors.name} required fullWidth>
                    <InputLabel>check</InputLabel>
                    <Select name='name' label='name' value={name} error={errors.name} onChange={onSelectChange} variant='standard' required>
                        {checks.map((m, i) => (
                            <MenuItem key={`item-${i}`} value={m}>{m}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridFormItem>
            <GridFormItem xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControlLabel sx={{ '& span.MuiButtonBase-root': { paddingBottom: 0, paddingTop: 0 } }}
                    control={
                        <Checkbox
                            name='done'
                            checked={!!done}
                            onChange={onTextFieldChange} />
                    }
                    label="Done" />
            </GridFormItem>
            <GridFormItem xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControlLabel sx={{ '& span.MuiButtonBase-root': { paddingBottom: 0, paddingTop: 0 } }}
                    control={
                        <Checkbox
                            name='positive'
                            checked={!!positive}
                            onChange={onTextFieldChange} />
                    }
                    label="Positive" />
            </GridFormItem>

            <Box sx={{ height: '3em', display: 'block' }} />
            <CustomFields value={customFields || {}} fields={client.layout.checkFields.filter(f => !f.field)} onChange={onCustomFiendsChange} />

            <input type='hidden' name='visitId' value={check?.visitId || ''} />
        </EntityEditor>
    )
}

export default CheckEditor;