import * as React from 'react';
import { useCallback, useState } from 'react';
import { Popover, SvgIconOwnProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const InfoButton = ({ children, ...props }: SvgIconOwnProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
    const openPopover = useCallback((event: React.MouseEvent<SVGElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const closePopover = useCallback(() => {
        setAnchorEl(null);
    }, []);
    return <>
        <InfoOutlinedIcon
            {...props}
            sx={{ cursor: 'help', ...(props.sx) }}
            onMouseEnter={openPopover}
            onMouseLeave={closePopover} />
        <Popover
            sx={{ pointerEvents: 'none', padding: '8px' }}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            disableRestoreFocus
        >{children}</Popover>
    </>;
};
