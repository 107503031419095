import * as React from 'react';
import { createContext, useContext, useMemo, useState } from "react";

interface SheetValue {
    sheetId: string;
}
const SheetContext = createContext<SheetValue | null>(null);

interface Props {
    children: string | JSX.Element | JSX.Element[];
}

export const SheetProvider = ({ children }: Props): JSX.Element => {
    const [sheetId, setSheetId] = useState('');
    const value = useMemo(() => (
        { sheetId, setSheetId }
    ), [sheetId]
    );

    return <SheetContext.Provider value={value}>
        {children}
    </SheetContext.Provider>;
}

export const useSheet = (): SheetValue | null => {
    return useContext(SheetContext);
};
