import { useCallback, useEffect, useRef, useState } from 'react';
import { Job, JobStatus, ScheduleJob } from '../models/core';
import { API } from '../utils/Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Message } from '../components/schedule/MessagesBox';
import debounce from 'lodash.debounce';

export type JobWithMessages<T> = T & { messages: Message[]; }

export function useJobsList<T extends Job>(clientId: string, type: string, msgFn: (job: T) => Message[]) {
    const [activeJob, setActiveJob] = useState<JobWithMessages<T> | null>(null);
    const jobIdToFocus = useRef<string | null>(null);
    const refetchCount = useRef(0);

    const queryClient = useQueryClient();
    const { data: jobs, isLoading, refetch } = useQuery({
        queryKey: ['jobs', clientId],
        queryFn: ({ queryKey: [__, clientId] }) => API.getJobs(clientId, type),
        select: (data) => (data.items as T[]).map<T & { messages: Message[] }>(job => ({ ...job, messages: msgFn(job) }))
    });

    useEffect(() => {
        if (jobs) {
            // Focus to new job after creation or previous after deletion
            if (jobIdToFocus.current != null) {
                setActiveJob((jobs || []).find(j => j._id == jobIdToFocus.current) || null)
                jobIdToFocus.current = null;
            }

            // Schedule re-fetch if any jb is in progress
            if (refetchCount.current > 0 || jobs?.find(job => [JobStatus.IN_PROGRESS, JobStatus.TERMINATING].includes(job.status))) {
                setTimeout(async () => {
                    await refetch();
                }, 5000);
                refetchCount.current = Math.max(0, refetchCount.current - 1);
            }
        }
    }, [jobs, refetch]);

    useEffect(() => {
        // Update active job if it was changed on back
        const job = (jobs || []).find(j => j._id == activeJob?._id) || null;
        if (job?.updated && job?.updated != activeJob?.updated) {
            setActiveJob(job);
        }
    }, [jobs, activeJob]);

    const saveJobMutation = useMutation({
        mutationFn: (job: Job) => API.updateJob(job),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobs', clientId] })
        }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveJobDebounce = useCallback(debounce((job: JobWithMessages<T>) => {
        saveJobMutation.mutate(job);
    }, 1000), []); // yes, [] - 

    const saveJob = useCallback((job: JobWithMessages<T>) => {
        setActiveJob(job);
        jobIdToFocus.current = null; // prevent reload from the current query
        saveJobDebounce(job);
    }, [saveJobDebounce]);

    const createJobMutation = useMutation({
        mutationFn: () => API.createJob({
            clientId,
            type: 'schedule',
            name: 'New Job',
            status: JobStatus.NEW,
            params: {
                visitDuration: 2, // 30min
                providerLoad: 50,
                driveSpeed: 9.5,
                maxIterations: 15,
                maxTimeSeconds: 120,
                femaleAgentPreferred: false,
                patients: [],
                patientsFilter: {
                    city: [],
                    county: [],
                    plan: [],
                    risk: [],
                    status: [],
                },
                agents: [],
                providers: []
            }
        } as Omit<ScheduleJob, '_id'>),
        onSuccess: (result) => {
            jobIdToFocus.current = result.insertedId;
            queryClient.invalidateQueries({ queryKey: ['jobs', clientId] })
        }
    });

    const deleteJobMutation = useMutation({
        mutationFn: () => API.deleteJob(clientId, activeJob?._id || ''),
        onSuccess: () => {
            // Activate another available job after query success
            if (jobs) {
                const deletedIndex = jobs.findIndex(job => job._id == activeJob?._id);
                if (deletedIndex > 0) {
                    jobIdToFocus.current = jobs[deletedIndex - 1]._id;
                }
                else if (deletedIndex == 0 && jobs.length >= 2) {
                    jobIdToFocus.current = (jobs || [])[1]._id;
                }
                else {
                    jobIdToFocus.current = ''; // clear selection
                }
            }
            queryClient.invalidateQueries({ queryKey: ['jobs', clientId] })
        }
    });

    const runJobMutation = useMutation({
        mutationFn: async () => activeJob ? await API.runJob(clientId, activeJob) : 0,
        onSuccess: () => {
            // Re-fetch three times to guarantee in_progress state will be catched
            refetchCount.current = 3;
            queryClient.invalidateQueries({ queryKey: ['jobs', clientId] })
        }
    });

    const stopJobMutation = useMutation({
        mutationFn: async () => activeJob ? await API.terminateJob(clientId, activeJob) : 0,
        onSuccess: () => {
            refetchCount.current = 3;
            queryClient.invalidateQueries({ queryKey: ['jobs', clientId] })
        }
    });

    return {
        jobs: jobs || [],
        isLoading,
        createJob: () => createJobMutation.mutate(),
        saveJob,
        isJobSaving: saveJobMutation.isPending || runJobMutation.isPending || stopJobMutation.isPending,
        deleteJob: deleteJobMutation.mutate,
        runJob: runJobMutation.mutate,
        stopJob: stopJobMutation.mutate,
        activeJob, setActiveJob
    };
}