import * as React from 'react';
import { useCallback, useState, useMemo, useEffect } from 'react';
import { ScheduleJobResult, ScheduleItem, Agent, Provider, LatLng } from '../../models/core';
import { capitalize, day2date, slot2time } from '../../utils/slots';
import { DateTime } from 'luxon';
import { Box, Button, useTheme } from '@mui/material';
import FiltersPanel, { FilterField, FilterValue } from '../FiltersPanel';

interface Route {
    agent: Agent;
    day: number;
    points: ScheduleItem[];
}

interface Office {
    coords: LatLng;
    address: string;
    agents: Agent[];
}

function pointPopup(pt: ScheduleItem, startDate: DateTime, agent?: Agent, provider?: Provider): string {
    const age = pt.patient.dob
        ? `, ${(new Date().getFullYear() - Number.parseInt(pt.patient.dob.slice(0, 4)))} y.o.`
        : '';
    return `<div class="popup">
        <p class="agent" title="OCC">
            <span>
                <svg viewBox="0 0 24 24">
                    <path d="M20 6h-4V4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2M10 4h4v2h-4zm6 11h-3v3h-2v-3H8v-2h3v-3h2v3h3z"></path>
                </svg>
                ${capitalize(agent?.name || "unknown")}
            </span>
            <span>${day2date(pt.day, startDate)}, ${slot2time(pt.startTime, startDate.hour)}</span>
        </p>
        <p class="provider" title="Provider">
            <svg viewBox="0 0 24 24">
                <path d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62">
                </path>
                <circle cx="9" cy="13" r="1"></circle>
                <circle cx="15" cy="13" r="1"></circle>
                <path d="M18 11.03C17.52 8.18 15.04 6 12.05 6c-3.03 0-6.29 2.51-6.03 6.45 2.47-1.01 4.33-3.21 4.86-5.89 1.31 2.63 4 4.44 7.12 4.47"></path>
            </svg>
            ${capitalize(provider?.name || "unknown")}
        </p>
        <p class="address" title="Address">
            <svg viewBox="0 0 24 24">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5"></path>
            </svg>
            ${capitalize(pt.address)}
        </p>
        <p class="patient-name" title="Patient">
            <svg viewBox="0 0 24 24">
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"></path>
            </svg>
            ${capitalize(pt.patient.firstName + ' ' + pt.patient.lastName)} (${pt.patient.gender?.toLowerCase()}${age})
        </p>
        <button onClick="{
            const button = document.getElementById('book-button');
            button.setAttribute('x-agent-id', ${pt.agentId});
            button.setAttribute('x-day', ${pt.day});
            button.setAttribute('x-start-time', ${pt.startTime});
            button.click();
        }">Book</button>
    </div>`
}

function officePopup(office: Office): string {
    return `<div class="popup">
        <p class="address" title="Address">
            <svg viewBox="0 0 24 24">
            <path d="M12 7V3H2v18h20V7zM6 19H4v-2h2zm0-4H4v-2h2zm0-4H4V9h2zm0-4H4V5h2zm4 12H8v-2h2zm0-4H8v-2h2zm0-4H8V9h2zm0-4H8V5h2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8zm-2-8h-2v2h2zm0 4h-2v2h2z"></path>
            </svg>
            ${capitalize(office.address)}
        </p>` +
        office.agents.map(agent => `
        <p class="agent" title="OCC">
            <span>
            <svg viewBox="0 0 24 24">
                <path d="M20 6h-4V4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2M10 4h4v2h-4zm6 11h-3v3h-2v-3H8v-2h3v-3h2v3h3z"></path>
            </svg>
            ${capitalize(agent?.name || "unknown")}
            </span>
        </p>`).join(' ') +
    '</div>'
}

const ResultMap = ({ result, startDate }: { result: ScheduleJobResult; startDate: DateTime;}): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const filterFields = useMemo<FilterField[]>(() => [
        { 
            name: 'OCC', 
            type: 'enum', 
            items: Object.values(result.schedule.reduce((acc,x) => {
                acc[x.agentId] = [x.agentId, result.agents.find(a => a.id==x.agentId)?.name||''] as [string,string];
                return acc;
            }, {} as {[k:string]: [string,string]})), 
            width: 6, 
            emptyLabel: 'Any OCC'
        },
        { 
            name: 'date', 
            type: 'enum', 
            items: Object.values(result.schedule.reduce((acc,x) => {
                acc[x.day+''] = [x.day+'', day2date(x.day, startDate)] as [string,string];
                return acc;
            }, {} as {[k:string]: [string,string]})), 
            width: 4,
            emptyLabel: 'Any Date'
        },
    ], [result, startDate]);
    const [filterValue, setFilterValue] = useState<FilterValue>({
        OCC: ['all'],
        date: ['all'],
    });

    const infoWindow = useMemo(() => (
        new google.maps.InfoWindow()
    ), []);
    

    useEffect(() => {
        if (ref.current && !map) {
            const m = new google.maps.Map(ref.current, {
                center: { lat: 40.677, lng: -73.9511 },
                zoom: 12,
            });
            m.data.addListener("click", (event: { feature: google.maps.Data.Feature; latLng: google.maps.LatLng }) => {
                infoWindow.setContent(event.feature.getProperty('text') as string)
                infoWindow.setPosition(event.latLng);
                infoWindow.open({ map: m });
            });
            const colors = ['#4285F4', '#EA4335', '#FBBC05', '#34A853'];
            m.data.setStyle((feature: google.maps.Data.Feature) => {
                const index = feature.getProperty('index') as number;
                const color = colors[index % colors.length];
                const r = feature.getProperty('selected') ? 9 : 6;
                const icon = feature.getProperty('type') == 'office'
                    ? {
                        path: "M12 7V3H2v18h20V7zM6 19H4v-2h2zm0-4H4v-2h2zm0-4H4V9h2zm0-4H4V5h2zm4 12H8v-2h2zm0-4H8v-2h2zm0-4H8V9h2zm0-4H8V5h2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8zm-2-8h-2v2h2zm0 4h-2v2h2z",
                        anchor: new google.maps.Point(12, 12),
                        fillOpacity: 1,
                        fillColor: '#EA4335',
                        strokeWeight: 0
                    }
                    : {
                        path: `M0 -${r} a${r},${r} 0 1,0 0.01,0 z`, // a rx ry rot large-arc-flag sweep-flag dx dy
                        labelOrigin: new google.maps.Point(40, 5),
                        fillOpacity: 1,
                        fillColor: color,
                    }
                return {
                    strokeColor: color,
                    strokeWeight: (feature.getProperty('selected')) ? 4 : 3,
                    icon,
                    fillOpacity: 0.2,
                    fillColor: color,
                    // label: {
                    //     text: new Date(feature.getProperty('text'))
                    //         .toLocaleTimeString([], { timeStyle: 'short' }),
                    //     color: 'white',
                    //     className: 'map-label map-label-color-' + (feature.getProperty('index') + 1)
                    // }
                }
            });
            setMap(m);
        }
    }, [ref, map, infoWindow]);

    const routes = useMemo(() => {
        return Object.values(result.schedule
            .filter(item => 
                (filterValue['OCC'].includes('all') || filterValue['OCC'].includes(item.agentId))
                && (filterValue['date'].includes('all') || filterValue['date'].includes(item.day+''))
            )
            .reduce((acc, item) => {
                acc[`${item.agentId}-${item.day}`] = [...(acc[`${item.agentId}-${item.day}`] || []), item];
                return acc;
            }, {} as { [k: string]: ScheduleItem[] })
        ).map(points => ({
            agent: result.agents.find(agent => agent.id == points[0].agentId),
            day: points[0].day,
            points: points.sort((a, b) => a.startTime - b.startTime)
        } as Route))

    }, [filterValue, result]);

    const offices = useMemo(() => (
        Object.values(result.agents
            .filter(agent => result.schedule.find(pt => pt.agentId == agent.id))
            .reduce((acc, agent) => {
                acc[agent.address] = [...(acc[agent.address] || []), agent];
                return acc;
            }, {} as { [k: string]: Agent[] })
        ).map(agents => {
            return { coords: agents[0].coords, address: agents[0].address, agents } as Office
        })
    ), [result]);

    const features = useMemo(() => {
        const wm: WeakMap<ScheduleItem | Route | Office, google.maps.Data.Feature> = new WeakMap();
        routes.forEach((route, routeIndex) => {
            wm.set(route, new google.maps.Data.Feature({
                geometry: new google.maps.Data.LineString(
                    [route.agent, ...route.points, route.agent].map(pt => ({
                        lat: pt.coords?.latitude||0,
                        lng: pt.coords?.longitude||0
                    }))
                ),
                properties: {
                    type: 'route',
                    index: routeIndex,
                    obj: route,
                    text: `${capitalize(route.agent?.name || '')}, ${day2date(route.day, startDate)}`
                }
            }));
            route.points.forEach((pt) => {
                wm.set(pt, new google.maps.Data.Feature({
                    geometry: new google.maps.Data.Point({
                        lat: pt.coords.latitude,
                        lng: pt.coords.longitude
                    }),
                    properties: {
                        type: 'point',
                        index: routeIndex,
                        obj: pt,
                        text: pointPopup(
                            pt,
                            startDate,
                            result.agents.find(agt => agt.id == pt.agentId),
                            result.providers.find(prv => prv.id == pt.providerId)
                        )
                    }
                }));
            })
        });
        offices.forEach(office => {
            wm.set(office, new google.maps.Data.Feature({
                geometry: new google.maps.Data.Point({
                    lat: office.coords.latitude,
                    lng: office.coords.longitude
                }),
                properties: {
                    type: 'office',
                    index: 0,
                    obj: office,
                    text: officePopup(office)
                }
            }));
        })
        return wm;
    }, [routes, offices, startDate, result]);

    // Add features to the map
    useEffect(() => {
        map && map.data && map.data.forEach((feature) => {
            map.data.remove(feature);
        });
        map && routes.forEach(route => {
            map.data.add(features.get(route));
            route.points.forEach((pt) => {
                map.data.add(features.get(pt));
            })
        });
        map && offices.forEach(office => {
            map.data.add(features.get(office));
        });
        const bounds = {
            west: NaN,
            east: NaN,
            north: NaN,
            south: NaN,
        }
        map?.data.forEach(ft => ft.getGeometry()?.forEachLatLng(latlng => {
            bounds.north = !(bounds.north > latlng.lat()) ? latlng.lat() : bounds.north;
            bounds.east = !(bounds.east > latlng.lng()) ? latlng.lng() : bounds.east;
            bounds.south = !(bounds.south < latlng.lat()) ? latlng.lat() : bounds.south;
            bounds.west = !(bounds.west < latlng.lng()) ? latlng.lng() : bounds.west;
        }))
        map?.fitBounds(bounds);
    }, [features, map, offices, result, routes]);

    const makeFilterValue = useCallback((value: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string | string[] {
            return (x instanceof Array && x.includes('all')) ? ['all'] : x;
        }
        return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, maybeJoin(v) || ['all']]));
    }, []);

    const onBookClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        const agentId = event.currentTarget.getAttribute('x-agent-id')
        const day = event.currentTarget.getAttribute('x-day')
        const startTime = event.currentTarget.getAttribute('x-start-time')
        console.log(agentId, day, startTime);
    }, []);

    const onFiltersChange = useCallback((value: FilterValue) => {
        setFilterValue(makeFilterValue(value));
    }, [makeFilterValue]);
    return <>
        <style>{`
            .popup {
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                letter-spacing: 0.00938em;
            }
            .popup p {margin: 0.25em}
            .popup .agent { display: flex; justify-content: space-between; }
            .popup svg { width: 1em; height: 1em; fill: rgba(0,0,0,0.87); }
            .popup button {
                margin: 0;
                border: 0;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.75;
                letter-spacing: 0.02857em;
                text-transform: uppercase;
                min-width: 64px;
                padding: 6px 8px;
                border-radius: 4px;
                background: #1976d2;
                color: white;
                width: 100%;
                cursor: pointer;
            }
            
        `}</style>
        <div style={{ minHeight: '640px', width: '100%' }} ref={ref} className="map-container" />
        <FiltersPanel 
            sx={{ 
                position: 'absolute', 
                right: '24px', 
                top: '0', 
                width: '480px', 
                alignItems: 'flex-end',
            }} 
            direction='row'
            fields={filterFields} 
            defaultValue={filterValue} 
            onChange={onFiltersChange} 
        />
        <Button id='book-button' onClick={onBookClick} hidden >Book</Button>
    </>;
}

export default ResultMap;