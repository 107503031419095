import React, { createContext, useContext, useState, ReactNode } from 'react';

const defaultLoadingContext = {
    isLoading: false,
    showLoading: () => console.warn("showLoading is not implemented"),
    hideLoading: () => console.warn("hideLoading is not implemented"),
};

const LoadingContext = createContext(defaultLoadingContext);

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = () => setIsLoading(true);
  const hideLoading = () => setIsLoading(false);

  const contextValue = {
    isLoading,
    showLoading,
    hideLoading
  };

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};

export {};