import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Checkbox, Grid, MenuItem, Select, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import { InfoButton } from '../InfoButton';
import MessagesBox, { Message } from './MessagesBox';
import { ScheduleJobParams } from '../../models/core';

export interface Settings {
    visitDuration: number;
    providerLoad: number;
    femaleAgentPreferred: boolean;
    quality: string;
}

export interface SettingsTabProps {
    messages: Message[];
    value: ScheduleJobParams;
    onChange?: (value: ScheduleJobParams) => void;
}

const QualityProfiles: { [k: string]: { maxIterations: number, maxTimeSeconds: number } } = {
    fast: { maxIterations: 15, maxTimeSeconds: 2 * 60 },
    medium: { maxIterations: 50, maxTimeSeconds: 5 * 60 },
    best: { maxIterations: 500, maxTimeSeconds: 14 * 60 },
};

const SettingsTab = ({ value, messages, onChange }: SettingsTabProps): JSX.Element => {
    const theme = useTheme();
    const [duration, setDuration] = useState(value.visitDuration*15+'');
    const [load, setLoad] = useState(value.providerLoad+'');
    const [femalePref, setFemalePref] = useState(value.femaleAgentPreferred);
    const [quality, setQuaity] = useState(
        Object.keys(QualityProfiles).find(k => 
            QualityProfiles[k].maxIterations==value.maxIterations
        ) || 'fast');

    useEffect(() => {
        setDuration((value.visitDuration*15)+'');
        setLoad(value.providerLoad+'');
        setFemalePref(value.femaleAgentPreferred);
        setQuaity(Object.keys(QualityProfiles).find(k => 
            QualityProfiles[k].maxIterations==value.maxIterations
        ) || 'fast');
    }, [value]);

    const onSelectChange = useCallback((event: SelectChangeEvent) => {
        if (event.target) {
            switch ((event.target as HTMLInputElement).name) {
                case 'duration': 
                    onChange && onChange({...value, visitDuration: Number.parseInt(event.target.value)/15}); 
                    break;
                case 'load': 
                    onChange && onChange({...value, providerLoad: Number.parseInt(event.target.value)}); 
                    break;
                case 'quality':
                    onChange && onChange({...value, ...QualityProfiles[event.target.value]}); 
                    break;
            }
        }
    }, [value, onChange]);

    const onCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onChange && onChange({...value, femaleAgentPreferred: checked});
    }, [value, onChange]);

    return <Stack sx={{width: '720px', height: '512px', padding: '0 24px', display: 'flex', flexDirection: 'column', justifyContent:'space-between' }}>
        <Grid container sx={{
            marginTop: '16px',
            alignItems: 'center',
            '.MuiInput-root': {
                width: '7em'
            },
            '.MuiGrid-item': {
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                paddingRight: '8px'
            },
            'input[type="text"]': {
                textAlign: 'right',
                paddingRight: '4px'
            }
        }}>
            <Grid item xs={3} justifyContent='space-between'>
                <Typography>Visit duration</Typography>
                <InfoButton sx={{ alignSelf: 'center', cursor: 'help', color: theme.palette.text.secondary }}>
                    <Typography sx={{ p: 1 }}>Diameter of cluster</Typography>
                </InfoButton>
            </Grid>
            <Grid item xs={9}>
                <Select name='duration' value={duration} variant='standard' onChange={onSelectChange} sx={{ marginLeft: '8px' }}>
                    <MenuItem value='15'>15 min</MenuItem>
                    <MenuItem value='30'>30 min</MenuItem>
                    <MenuItem value='45'>45 min</MenuItem>
                    <MenuItem value='60'>1 hour</MenuItem>
                    <MenuItem value='90'>1.5 hours</MenuItem>
                    <MenuItem value='120'>2 hours</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={3} justifyContent='space-between'>
                <Typography>Provider load</Typography>
                <InfoButton sx={{ alignSelf: 'center', cursor: 'help', color: theme.palette.text.secondary }}>
                    <Typography sx={{ p: 1 }}>How doctor is loaded by a single patient</Typography>
                </InfoButton>
            </Grid>
            <Grid item xs={9}>
                <Select name='load' value={load} variant='standard' onChange={onSelectChange} sx={{ marginLeft: '8px' }}>
                    <MenuItem value='25'>25%</MenuItem>
                    <MenuItem value='50'>50%</MenuItem>
                    <MenuItem value='75'>75%</MenuItem>
                    <MenuItem value='100'>100%</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={3} justifyContent='space-between'>
                <Typography>Female agent</Typography>
                <InfoButton sx={{ alignSelf: 'center', cursor: 'help', color: theme.palette.text.secondary }}>
                    <Typography sx={{ p: 1 }}>Check if visits involve undressing or female health stuffs</Typography>
                </InfoButton>
            </Grid>
            <Grid item xs={9}>
                <Checkbox name='female-pref' checked={femalePref} onChange={onCheckboxChange} sx={{ padding: 0 }} />
            </Grid>
            <Grid item xs={12} sx={{height: '16px'}} />
            <Grid item xs={3} justifyContent='space-between'>
                <Typography>Quality</Typography>
                <InfoButton sx={{ alignSelf: 'center', cursor: 'help', color: theme.palette.text.secondary }}>
                    <Typography sx={{ p: 1 }}>Desired time/quality of solution</Typography>
                </InfoButton>
            </Grid>
            <Grid item xs={9}>
                <Select name='quality' value={quality} variant='standard' onChange={onSelectChange} sx={{ marginLeft: '8px' }}>
                    <MenuItem value='fast' title='Produce initial solution and make some improvements'>Fast</MenuItem>
                    <MenuItem value='medium' title='Improve solution for some time'>Medium</MenuItem>
                    <MenuItem value='best' title='Improve as long as possible'>Best</MenuItem>
                </Select>
            </Grid>
        </Grid>
        <MessagesBox messages={messages} />
    </Stack>
}

export default SettingsTab;