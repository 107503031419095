import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import * as React from 'react';


interface Props {
    sheets: { name: string; columns: string[] }[];
    open: boolean;
    onClose?: () => void;
}

const Preview = ({ sheets, open, onClose }: Props): JSX.Element => {
    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <Dialog open={open} onClose={onClose} maxWidth={false} sx={{
        table: {
            margin: '8px',
            whiteSpace: 'nowrap',
            'td,th': {
                borderTop: `1px dotted gray`,
                borderLeft: `1px dotted gray`,
                padding: '0 4px'
            },
            'td:last-child, th:last-child': {
                borderRight: `1px dotted gray`,
            },
            'tr:last-child td': {
                borderBottom: `1px dotted gray`,
            }
        }
    }}>
        <DialogTitle>Preview</DialogTitle>
        <DialogContent>
            <Box sx={{ width: '560px' }}>
                {sheets.length > 0
                    ? <Box sx={{ width: '100%', overflowX: 'scroll' }}><table cellSpacing={0}>
                        <thead>
                            <tr>
                                {sheets[value].columns.map((c, i) => <th key={`cell-${i}`}>{c}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {[...new Array(5)].map((_, i) => (
                                <tr key={`row-${i}`}>
                                    {[...new Array(sheets[value].columns.length)].map((_, j) => (
                                        <td key={`cell-${j}`}>&nbsp;</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table></Box>
                    : null
                }
            </Box>
            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ sx: { top: 0, bottom: 'initial' } }}>
                {sheets.map((sheet, index) => (
                    <Tab key={`tab-${index}`} label={sheet.name} />
                ))}
            </Tabs>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Ok</Button>
        </DialogActions>
    </Dialog>
}

export default Preview;