import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { Agent, Provider, ScheduleItem, ScheduleJob } from '../../models/core';
import { API as ZiphyAPI } from '../../utils/ZiphyAPI';
import { API } from '../../utils/Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Patient as ZiphyPatient, Place, Role, Appointment } from '../../models/ziphy';
import ZiphyPatientForm from '../book/ZiphyPatientForm';
import ZiphyPlaceForm from '../book/ZiphyPlaceForm';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import ZiphyPatientInput from '../book/ZiphyPatientInput';
import ZiphyPlaceInput from '../book/ZiphyPlaceInput';
import SpinnerButton from '../SpinnerButton';
import useSnackbar from '../../hooks/useSnackbar';
import AlertSnack from '../ErrorSnack';
import { dtimeToLuxon, jsToDtime, luxonToDtime } from '../../utils/time';
import { EnumField } from '../fields';
import MaybeEditor, { } from '../book/MaybeEditor';
import { slot2luxon } from '../../utils/slots';

async function getRoles(practiceId: number, role: string): Promise<Role[]> {
    return (await ZiphyAPI.getRoles({
        and: [
            { eq: ['practice_id', practiceId] },
            { eq: ['is_active', true] },
            { eq: ['role', role] }
        ]
    })).roles.items;
}

function useWorkingRanges(
    practiceId: number,
    date: Date | null,
    office_id: number,
    roles: Role[]) {
    return useQuery({
        queryKey: ['schedule.report', practiceId, date, office_id],
        queryFn: async () => date
            ? await ZiphyAPI.getSchedulesReport(
                practiceId,
                office_id,
                jsToDtime(date),
                luxonToDtime(DateTime.fromJSDate(date).plus({ days: 1 }))
            )
            : null,
        select: (data) =>
            data?.working_ranges.map.items
                .filter(({ key }) => roles.find(role => role.id == key))
                .map(item => item.value)
                .flat()
                .map(range => ({
                    begin: dtimeToLuxon(range.begin),
                    end: dtimeToLuxon(range.end)
                }))
            || []
    });
}


async function doBookMutation(
    practice_id: number,
    office_id: number,
    place_id: number,
    service_id: number,
    agent_role_id: number | undefined,
    provider_role_id: number | undefined,
    patientId: number,
    time: DateTime,
): Promise<Appointment | null> {
    return await ZiphyAPI.bookAppointment(
        {
            practice_id,
            office_id,
            place_id,
            service_id,
            agent_role_id,
            provider_role_id,
            description: 'booking api test',
            answers: { 0: { 1: 1, 2: 1, 7: 1, 8: 1, 9: 1, 10: 1 } },
            start: luxonToDtime(time),
            patients: [{ id: patientId, use_insurance: false }],
            symptom_ids: [],
            optimistic: true
        }
    ) || null;
}

function useServicesQuery(practiceId: number) {
    return useQuery({
        queryKey: ['practice_services', practiceId],
        queryFn: async () => (await ZiphyAPI.getPracticeServices(practiceId)),
        select: ({ practice_services, expanded: { services: { items: serviceMap } } }) => practice_services.items.map(ps => ({ ...ps, service: serviceMap.find(it => it.key == ps.service_id)?.value })
        ),
    });
}

function isValidSlot(
    dt: DateTime<boolean>,
    workingRanges: { begin: DateTime<boolean>; end: DateTime<boolean>; }[],
    ): unknown {
    return workingRanges.filter(({ begin, end }) => 
        dt.diff(begin).milliseconds >= 0
        && end.diff(dt).milliseconds >= 0
    ).length == workingRanges.length
}

function itemRange(item: ScheduleItem, dayStart: DateTime, range: [number, number]): { begin: DateTime<boolean>; end: DateTime<boolean>; } {
    return {
        begin: slot2luxon(item.startTime, dayStart).plus({minutes: range[0]}),
        end: slot2luxon(item.startTime+item.duration, dayStart).plus({minutes: range[1]})
    }
}

interface Props {
    open: boolean;
    item: ScheduleItem;
    agent: Agent;
    provider: Provider;
    practiceId: number;
    date: DateTime;
    job: ScheduleJob;
    startHour: number;
    onSubmit?: () => void;
    onCancel?: () => void;
}

const BookDialog = ({ open, item, agent: agent1, provider: provider1, practiceId, date: startDate, startHour, job, onCancel }: Props): JSX.Element => {
    const [agentId, setAgentId] = useState('');
    const [providerId, setProviderId] = useState('');
    const [patient, setPatient] = useState<ZiphyPatient | null>(null);
    const [place, setPlace] = useState<Place | null>(null);
    const [date, setDate] = useState<Date | null>(startDate.set(
        { hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate());
    const [time, setTime] = useState('');
    const [serviceId, setServiceId] = useState('');

    const { showSuccess: snackSuccess, showError: snackError, ...snackProps } = useSnackbar();

    const agentsQuery = useQuery({
        queryKey: ['agents', practiceId],
        queryFn: async () => await getRoles(practiceId, 'agent'),
        staleTime: 60_000
    });
    const agent = useMemo(
        () => agentsQuery.data?.find(a => a.id + '' == agentId),
        [agentId, agentsQuery]
    );

    const providersQuery = useQuery({
        queryKey: ['provider', practiceId],
        queryFn: async () => await getRoles(practiceId, 'provider'),
        staleTime: 60_000
    });
    const provider = useMemo(
        () => providersQuery.data?.find(p => p.id + '' == providerId),
        [providerId, providersQuery]
    );

    const servicesQuery = useServicesQuery(practiceId);

    const service = useMemo(() => (
        servicesQuery.data?.find(s => s.service_id + '' == serviceId)
    ), [servicesQuery, serviceId]);

    const { data: workingRanges } = useWorkingRanges(
        practiceId,
        date,
        agent?.office_id || provider?.office_id || 0,
        [agent, provider].filter((x => !!x) as (x: Role | undefined) => x is Role)
    );

    const slotsQuery = useQuery({
        queryKey: ['slots', practiceId, place?.id, serviceId, agent?.id, provider?.id, date],
        queryFn: async () =>
            place?.id && agent?.office_id && serviceId && date && await ZiphyAPI.getSlots(
                practiceId, place.id, Number.parseInt(serviceId), agent.office_id,
                {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(), _type: 'date'
                },
            ) || null,
        select: (data) =>
            data?.windows.items
                .items?.map(item => dtimeToLuxon(item.key))
                .filter(dt => date && workingRanges && isValidSlot(
                    dt, 
                    [...workingRanges, itemRange(item, DateTime.fromJSDate(date).set({hour: startHour}), [-90, 90])], 
                )) || []
    })

    const bookMutation = useMutation({
        mutationFn: async () =>
            agent?.office_id && place?.id && serviceId && agent?.id &&
            provider?.id && patient?.id && time &&
            await doBookMutation(
                practiceId,
                agent.office_id,
                place.id,
                Number.parseInt(serviceId),
                service?.service?.agent_load ? agent.id : undefined,
                service?.service?.provider_load ? provider.id : undefined,
                patient.id,
                DateTime.fromISO(time)
            ) || null,
        onSuccess: () => {
            snackSuccess();
            onCancel && onCancel();
        },
        onError: snackError
    });

    // Initially select agent and provider from props
    useEffect(() => {
        if (agentId == '' && agentsQuery.data?.find(a => a.id + '' == agent1.id)) {
            setAgentId(agent1.id);
        }
    }, [agent1, agentId, agentsQuery]);

    useEffect(() => {
        if (providerId == '' && providersQuery.data?.find(p => p.id + '' == provider1.id)) {
            setProviderId(provider1.id);
        }
    }, [provider1, providerId, providersQuery]);

    const onEnumChange = useCallback((label: string, value: string | null) => {
        switch (label) {
            case 'agent': setAgentId(value || ''); break;
            case 'provider': setProviderId(value || ''); break;
            case 'service': setServiceId(value || ''); break;
            case 'time': setTime(value || ''); break;
        }
    }, []);

    const onDateChange = useCallback((value: string | null) => {
        setDate(value &&
            DateTime.fromISO(value)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toJSDate() || null
        );
    }, []);

    const queryClient = useQueryClient();
    const onBookClick = useCallback(async () => {
        const apt = await bookMutation.mutateAsync();
        await Promise.all([
            API.updateVisit({
                _id: item.visit._id,
                clientId: item.visit.clientId,
                patientId: item.visit.patientId,
                appointmentId: apt?.id
            }),
            job && API.updateJobResult(
                job,
                { 'result.schedule': `{ "$elemMatch": { "visit._id": "${item.visit._id}" }}` },
                { 'result.schedule.$.visit.appointmentId': apt?.id })
        ]);
        queryClient.invalidateQueries({ queryKey: ['jobs'] });
    }, [bookMutation, item, job, queryClient]);

    const fieldSpinner = useMemo(() => (
        <CircularProgress size={20} />
    ), []);

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Book Appointment</DialogTitle>
            <DialogContent sx={{ width: '480px' }}>
                <MaybeEditor name='place' onChange={setPlace} >
                    <ZiphyPlaceInput
                        value={null}
                        practiceId={practiceId}
                        initialInput={`${item.patient.address}, ${item.patient.city}, ${item.patient.zip}`}
                    />
                    <ZiphyPlaceForm patient={item.patient} coords={item.coords} practiceId={practiceId} />
                </MaybeEditor>

                {place ? <MaybeEditor name='patient' onChange={setPatient} >
                    <ZiphyPatientInput
                        value={null}
                        practiceId={practiceId}
                        initialInput={`${item.patient.firstName} ${item.patient.lastName}`} />
                    <ZiphyPatientForm patient={item.patient} practiceId={practiceId} placeId={place?.id} />
                </MaybeEditor> : null}

                <EnumField
                    name='service' label='Service' value={serviceId}
                    required onChange={onEnumChange}
                    options={(servicesQuery.data || [])
                        .filter(ps => !ps.service?.has_symptoms && ps.duration <= 15*item.duration)
                        .map<[string, string]>(ps => [
                            ps.service_id + '',
                            `${ps.service?.name} (${ps.duration}min, `
                            + [
                                ...(ps.service?.agent_load ? ['OCC'] : []),
                                ...(ps.service?.provider_load ? ['Provider'] : []),
                            ].join('+') + ')'
                        ])}
                />
                <Box sx={{ width: '1px', height: '16px' }} />

                <Stack direction='row'>
                    <EnumField name='agent' label='Agent'
                        value={service?.service?.agent_load ? agentId : ''}
                        onChange={onEnumChange} disabled={!(service?.service?.agent_load || '')}
                        options={(agentsQuery.data || []).map<[string, string]>(role => [
                            role.id + '', role.name || ''
                        ])}
                    />
                    <Box sx={{ width: '32px', height: '1px' }} />
                    <EnumField name='provider' label='Provider'
                        value={service?.service?.provider_load ? providerId : ''}
                        onChange={onEnumChange} disabled={!(service?.service?.provider_load || '')}
                        options={(providersQuery.data || []).map<[string, string]>(role => [
                            role.id + '', role.name || ''
                        ])}
                    />
                </Stack>

                <Box sx={{ width: '1px', height: '16px' }} />

                <Stack direction='row'>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            value={date}
                            onChange={onDateChange}
                            label='Date'
                            disableMaskedInput
                            renderInput={(params) =>
                                <TextField name='date' variant='standard' {...params} />
                            }
                        />
                    </LocalizationProvider>
                    <Box sx={{ width: '32px', height: '1px' }} />
                    <EnumField name='time' label='Time'
                        value={time}
                        onChange={onEnumChange} disabled={(slotsQuery.data || []).length == 0}
                        options={slotsQuery.data?.map<[string, string]>(slot => [
                            slot.toISO() || '',
                            slot.toLocaleString({ hour: '2-digit', minute: '2-digit' }),
                        ]) || []}
                        endAdornment={slotsQuery.isFetching ? fieldSpinner : null}
                    />
                </Stack>


                {/* <SlotSelector
                    availableSlots={slotsQuery.data || []}
                    value={slots}
                    loading={slotsQuery.isFetching}
                    onChange={setSlots}
                /> */}
            </DialogContent>
            <DialogActions>
                <Button type='button' onClick={onCancel}>Cancel</Button>
                <SpinnerButton
                    onClick={onBookClick}
                    disabled={!patient || !place || !time || serviceId == ''}
                    showSpinner={bookMutation.isPending}
                >Book</SpinnerButton>
            </DialogActions>
            <AlertSnack {...snackProps} />
        </Dialog>
    );
}

export default BookDialog;
