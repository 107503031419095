import * as React from 'react';
import { useState } from 'react';
import { /*redirect, ActionFunctionArgs,*/ LoaderFunctionArgs, 
    Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import AppBarPage from '../components/AppBarPage';
import { Button, CircularProgress, Grid, IconButton, List, ListItemSecondaryAction, ListItemText, Menu, 
    MenuItem, Pagination, Tooltip, ListItemIcon, Typography, Divider } from '@mui/material';
import { API } from '../utils/Api';
import { Client, ClusterJob, Job, JobStatus, Patient, Plan, ScheduleJob, Visit, PatientStatus } from '../models/core';
//import useSchedule from '../hooks/useSchedule';
import { DateTime } from 'luxon';
import useSearchParamsDict from '../hooks/useSearchParamsDict';
import useDialog from '../hooks/useDialog';
import { useAuth } from '../hooks/useAuth';
import PatientEditor from '../components/PatientEditor';
import SpinnerButton from '../components/SpinnerButton';
import { EmailOutcomes, MailOutcomes, PatientStatuses, PhoneOutcomes, 
    RefusalReasons, ScheduledFailedReasons, TextOutcomes, VisitStatuses } from '../utils/enums';
import FiltersPanel, { FilterField } from '../components/FiltersPanel';
import { analytics } from '../utils/analytics/zipAnalytics';
import { Check, PersonAdd, MoreVert, Schedule as ScheduleIcon, Workspaces as WorkspacesIcon } from '@mui/icons-material';
import ClusterDialog from '../components/ClusterDialog';
import { Accordion, AccordionDetails, AccordionSummary } from '../components/schema/Accordion';
import ScheduleDialog from '../components/ScheduleDialog';
import AssignmentDialog from '../components/AssignmentDialog'
import { PatientListItem } from '../components/PatientListItem';
import { AuthData } from '../models/ziphy';
import { useLoading } from '../hooks/useLoading';

type LoaderData = [Client, (Patient & { visit?: Visit })[], number, Plan[], string, Job[], URLSearchParams];

export const loader = async ({ params, request }: LoaderFunctionArgs): Promise<LoaderData> => {

    let clientId = params.clientId;
    if (!clientId) {
        const match = request.url.match(/clients\/(?<clientId>\w+)\//);
        clientId = match && match.groups && match.groups['clientId'] || '';

        if (!clientId) {
            throw Error('Missing clientId param');
        }
    }

    if(!API.token || API.token == '') {
        const authItem = window.localStorage.getItem('auth');
        const auth = authItem ? (JSON.parse(authItem) as AuthData) : null;
        API.token = auth?.accessToken?.value || '';
    }

    /*const sorts: { [k: string]: [string, number][] } = {
        schedule: [['scheduled', 1], ['lastName', 1], ['firstName', 1], ['dob', 1]],
        risk: [['risk', -1], ['lastName', 1], ['firstName', 1], ['dob', 1]],
        risk2: [['risk2', -1], ['lastName', 1], ['firstName', 1], ['dob', 1]],
        name: [['lastName', 1], ['firstName', 1], ['dob', 1]]
    }*/
    const searchParams = new URL(
            request.url.endsWith('/patients') ? request.url + '?status=Active' : request.url
        ).searchParams;

    const [client, { items: patients, count: numPats }, { items: plans }, { items: jobs }] = await Promise.all([
        API.getClient(clientId),
        API.getPatients(
            clientId,
            Array.from(searchParams.entries()).filter(([k]) => !['sort', 'page'].includes(k)),
            10,
            10 * (Number.parseInt(searchParams.get('page') || '1', 10) - 1),
            //sorts[searchParams.get('sort') || 'name']
            [['name', 1]]
        ),
        API.getPlans(clientId),
        API.getJobsMeta(clientId)
    ]);
    return [client, patients, numPats, plans, clientId, jobs as Job[], searchParams];
}

/*export const deletePatient = async ({ params }: ActionFunctionArgs): Promise<Response> => {
    if (!params.clientId) {
        throw Error('Missing clientId param');
    }
    if (!params.patientId) {
        throw Error('Missing patientId param');
    }
    await API.deletePatient(params.clientId, params.patientId);
    return redirect('../..');
}*/

type FilterFields = 'search' | 'rgx.firstName' | 'rgx.lastName' | 'planId' 
    | 'visit.riskCategory' | 'rgx.county' | 'rgx.city' | 'zip'
    | 'visit.status'| 'visit.refusalReason'| 'key.phoneOutcome'| 'key.emailOutcome' 
    | 'key.textOutcome' | 'key.mailOutcome'
    | 'key.scheduledReason' | 'status' | 'key.outcomeMissing' | 'key.assignments';

const PatientsPage = (): JSX.Element => {
    const [client, patients, count, plans, clientId, jobs, searchString] = useLoaderData() as LoaderData;
    const [searchParams, setSearchParams] = useSearchParamsDict<'page' | 'sort' | FilterFields>();
    const [patientToEdit, setPatientToEdit] = useState<Patient | null>(null);
    const navigate = useNavigate();
    const loading = useLoading();

    const filterValue = React.useMemo<{ [k in FilterFields]: string | string[] }>(() => ({
        search: searchParams.search || '',
        'rgx.firstName': searchParams['rgx.firstName'] || '',
        'rgx.lastName': searchParams['rgx.lastName'] || '',
        planId: searchParams.planId?.split('|') || '',
        'rgx.city': searchParams['rgx.city'] || '',
        zip: searchParams.zip || '',
        'rgx.county': searchParams['rgx.county'] || '',
        'visit.riskCategory': searchParams['visit.riskCategory']?.split('|') || ['all'],
        'visit.status': searchParams['visit.status']?.split('|') || ['all'],
        'visit.refusalReason': searchParams['visit.refusalReason']?.split('|') || ['all'],
        'key.phoneOutcome': searchParams['key.phoneOutcome']?.split('|') || ['all'],
        'key.emailOutcome': searchParams['key.emailOutcome']?.split('|') || ['all'],
        'key.textOutcome': searchParams['key.textOutcome']?.split('|') || ['all'],
        'key.mailOutcome': searchParams['key.mailOutcome']?.split('|') || ['all'],
        'key.scheduledReason': searchParams['key.scheduledReason']?.split('|') || ['all'],
        'status' : !searchParams['status'] ? [PatientStatus.ACTIVE] : (searchParams['status']?.split('|') || ['all']),
        'key.outcomeMissing': searchParams['key.outcomeMissing']?.split('|') || ['all'],
        'key.assignments': searchParams['key.assignments']?.split('|') || ['all']
    }), [searchParams])
    const page = React.useMemo(() => parseInt(searchParams.page || '1', 10), [searchParams]);
    //const [showSchedule, setShowSchedule] = useState(false);
    
    const { open: peOpen, show: showPE, hide: hidePE } = useDialog();
    const { open: clOpen, show: showCl, hide: hideCL } = useDialog();
    const { open: a1dOpen, show: showA1D, hide: hideA1D } = useDialog();
    const { open: aMdOpen, show: showAMD, hide: hideAMD } = useDialog();
    const { open: scOpen, show: showSc, hide: hideSc } = useDialog();
    
    const [filtersSyncing, setFiltersSyncing] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [syncInProgress, setSyncInProgress] = useState(false);
    const [assignablePatient, setAssignablePatient] = useState<Patient|null>(null);
    
    const auth = useAuth();

    React.useEffect(() => { setFiltersSyncing(false); }, [searchString])

    React.useEffect(() => { loading.hideLoading(); }, []);

    //const sort = React.useMemo(() => searchParams.sort || 'name', [searchParams]);
    //const { schedule } = useSchedule(clientId, !showSchedule);
    /*const onSortChange = React.useCallback((event: SelectChangeEvent<string>) => {
        setSearchParams({ sort: event.target.value, page: '1' });
    }, [setSearchParams]);*/

    const onPageChange = React.useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        setFiltersSyncing(true);
        setSearchParams({ page: value + '' })
    }, [setSearchParams]);

    const onMoreClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onClusterClick = React.useCallback(() => {
        showCl();
        setAnchorEl(null);
    }, [showCl]);

    const onScheduleClick = React.useCallback(() => {
        showSc()
        setAnchorEl(null);
    }, [showSc]);
    const onSyncClick = React.useCallback(async () => {
        setSyncInProgress(true);
        analytics.track("user_sync", { clientId: clientId }, true);
        try {
            await API.import(clientId);
        }
        catch (e) {
            analytics.error('sync_failed', {}, true);
        } finally {
            setSyncInProgress(false);
        }
    }, [clientId]);

    /*const hideSchedule = React.useCallback(() => {
        setShowSchedule(false);
    }, []);*/

    const onEdit = React.useCallback((patient: Patient) => {
        setPatientToEdit(patient);
        showPE();
    }, [showPE]);

    const onPatientSaved = React.useCallback(() => {
        navigate('.');
        hidePE();
    }, [hidePE, navigate]);

    const onAddPatient = React.useCallback(() => {
        setPatientToEdit(null);
        showPE();
    }, [showPE]);

    const onFiltersChange = React.useCallback((value: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string {
            return x == 'all' ? '' : (x instanceof Array) ? (x.includes('all') ? 'all' : x.join('|')) : x;
        }
        setFiltersSyncing(true);
        setSearchParams({
            search: maybeJoin(value.search) || '',
            'rgx.firstName': maybeJoin(value['rgx.firstName']) || '',
            'rgx.lastName': maybeJoin(value['rgx.lastName']) || '',
            planId: plans.filter(p => (value.planId as string[]).includes(p.name)).map(p => p._id).join('|'),
            'rgx.city': maybeJoin(value['rgx.city']) || '',
            zip: maybeJoin(value.zip) || '',
            'visit.riskCategory': maybeJoin(value['visit.riskCategory']) || '',
            'rgx.county': maybeJoin(value['rgx.county']) || '',
            'visit.status': maybeJoin(value['visit.status']) || '',
            'visit.refusalReason': maybeJoin(value['visit.refusalReason']) || '',
            'key.phoneOutcome': maybeJoin(value['key.phoneOutcome']) || '',
            'key.emailOutcome': maybeJoin(value['key.emailOutcome']) || '',
            'key.textOutcome': maybeJoin(value['key.textOutcome']) || '',
            'key.mailOutcome': maybeJoin(value['key.mailOutcome']) || '',
            'key.scheduledReason': maybeJoin(value['key.scheduledReason']) || '',
            'status': maybeJoin(value['status']) || '',
            'key.outcomeMissing': maybeJoin(value['key.outcomeMissing']) || '',
            'key.assignments': maybeJoin(value['key.assignments']) || '',
            page:'1'
        });
    }, [plans, setSearchParams]);

    const filterFields = React.useMemo<FilterField[]>(() => [
        { name: 'search', type: 'search', placeholder: 'Insurance/Medicare/Medicaid ID' },
        { name: 'rgx.firstName', type: 'string', placeholder: 'First Name' },
        { name: 'rgx.lastName', type: 'string', placeholder: 'Last Name' },
        { name: 'rgx.city', type: 'string', placeholder: 'City', width: 4 },
        {
            name: 'rgx.county', type: 'string', placeholder: 'County',
            disabled: !client.layout.patientFields.find(f => f.field == 'county'), width: 4
        },
        {name: 'zip', type: 'string', placeholder: 'ZIP', width: 4},
        {name: 'planId', type: 'enum', items: plans.map(p => p.name), emptyLabel: 'All Plans'},
        {name: 'visit.riskCategory', type: 'enum', items: ['Top 1%', 'Top 2-5%', 'Top 6-20%', 'Bottom 80%'], emptyLabel: 'Any Risk', 
            disabled: !client.layout.patientFields.find(f => f.field && f.field=='riskCategory')},
        {name: 'visit.status', type: 'enum', items: VisitStatuses, emptyLabel: 'Any Status'},
        {name: 'visit.refusalReason', type: 'enum', items: RefusalReasons, emptyLabel: 'Any Refusal Reason'},
        {name: 'key.phoneOutcome', type: 'enum', items: PhoneOutcomes, emptyLabel: 'Any Phone Outcome'},
        {name: 'key.emailOutcome', type: 'enum', items: EmailOutcomes, emptyLabel: 'Any Email Outcome'},
        {name: 'key.textOutcome', type: 'enum', items: TextOutcomes, emptyLabel: 'Any Text Outcome'},
        {name: 'key.mailOutcome', type: 'enum', items: MailOutcomes, emptyLabel: 'Any Mail Outcome'},
        {name: 'key.scheduledReason', type: 'enum', items: ScheduledFailedReasons, emptyLabel: 'Any Schedule Reason'},
        {name: 'status', type: 'enum', items: PatientStatuses, emptyLabel: "Any Status"},
        {name: 'key.outcomeMissing', type: 'enum', forceSingle:true, items: [
            'No Phone 1st attempt', 
            'No Phone 2nd attempt',
            'No Phone 3rd attempt',
            'No Phone 4th attempt',
            'No Phone 5th attempt',
            'No Phone 6th attempt',
            'No Email attempts',
            'No Text attempts',
            'No Mail attempts'
        ], emptyLabel: "Any Attempts"},
        {name: 'key.assignments', type: 'enum', items: client.repNames ? ['None', 'Assigned',...client.repNames] : [], emptyLabel:'Any Assignment', 
            disabled:!(client.repNames?.length != 0) },
    ], [client.layout.patientFields,client.repNames, plans]);

    const patientList = React.useMemo(() => {
            return (patients || []).map((patient) => (
                <PatientListItem
                    key={patient._id}
                    patient={patient}
                    visit={patient.visit || null}
                    onEdit={onEdit}
                    onAssign={(patient:Patient) => {
                            setAssignablePatient(patient);
                            showA1D();
                        }
                    }
                />
            ));
        },
        [patients, onEdit, setAssignablePatient, showA1D]
    );
    const clusterJobStatus = React.useMemo(() => {
        const job = jobs.find(job => job.name == 'cluster');
        return job?.status || '';
    }, [jobs]);

    const assignConfirmSingleDialog = React.useMemo<JSX.Element>(() => {
        const onHandleAssignUser = async (value:string) => {

            if(assignablePatient == null) {
                console.error('Patient not set for assignment');
                return;
            }
    
            const ts = DateTime.now().toString();
            const assigner = auth?.user?.name || 'NA';
            const prevAssignment = assignablePatient?.assignment?.assignedTo || 'None';
            analytics.track('assign', { type:'single', patient:assignablePatient._id, to:value, by: assigner, date:ts, from:prevAssignment }, true);

            await API.updatePatient({ ...assignablePatient, ...
                { assignment: { assignedDate: ts, assignedTo: value, assignedBy: assigner } }
            });
            
            setSearchParams({ page: page + '' });
            hideA1D();
        };

        return <AssignmentDialog open={a1dOpen} showIfBusy={true} client={client} 
            defaultValue={ auth?.user?.name || undefined } onAssign={onHandleAssignUser} onClose={hideA1D}/>
        
    },[a1dOpen, hideA1D, client, assignablePatient, auth?.user?.name, setSearchParams, page]);

    const assignConfirmQueryDialog = React.useMemo<JSX.Element>(() => {
        const onHandleAssignUser = async (value:string) => {
            
            const ts = DateTime.now().toString();
            const assigner = auth?.user?.name || 'NA';
            const prevAssignment = assignablePatient?.assignment?.assignedTo || 'None';
            analytics.track('assign', { type:'many', to:value, by: assigner, date:ts, from:prevAssignment }, true);

            await API.assignPatients(clientId, { assignedDate: ts, assignedTo: value, assignedBy:assigner },
                Array.from(searchString.entries()).filter(([k]) => !['sort', 'page'].includes(k))
            )

            setSearchParams({ page: page + '' });
            hideAMD();
        };

        return <AssignmentDialog open={aMdOpen} showIfBusy={true} client={client} 
            defaultValue={ auth?.user?.name || undefined } onAssign={onHandleAssignUser} onClose={hideAMD}/>

    },[aMdOpen, hideAMD, client, auth?.user?.name, clientId, searchString, setSearchParams, page,
        assignablePatient?.assignment?.assignedTo]);

    return (
        <AppBarPage title='Patients' actions={<>
            {
                client.layout.options.enableSyncInPatients ? 
                    <SpinnerButton showSpinner={syncInProgress} onClick={onSyncClick} color='inherit'>Sync</SpinnerButton>
                : <></>
            }
            <IconButton color='inherit' onClick={onAddPatient}><PersonAdd /></IconButton>
            <IconButton color='inherit' onClick={onMoreClick}><MoreVert /></IconButton>
        </>
        }>
            <FiltersPanel fields={filterFields} defaultValue={filterValue} isBusy={filtersSyncing} onChange={onFiltersChange} collapsable={true} />

            <Grid container padding='2px 32px 0 32px'>
                <Grid item xs={12} padding='2px 0px 8px 0px'>
                    <Accordion>
                        <AccordionSummary><Typography><i>Additional Options</i></Typography></AccordionSummary>
                        <AccordionDetails>
                            <Grid item xs={4} paddingRight='16px' justifyContent="center">
                                <Tooltip id="tt-assign-all" title="Assign all currently shown patients to a user.">
                                    <Button variant='contained' fullWidth onClick={ showAMD }>Assign All</Button>
                                </Tooltip>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Grid item xs={12} padding='4px 0px 8px 0px'><Divider variant="middle" /></Grid>
                </Grid>
                <Grid item xs={3} paddingRight='16px'>
                    {
                        /* Removed until fixed
                        <Select value={sort} onChange={onSortChange} variant='standard' sx={{ minWidth: '100%' }}>
                            <MenuItem value='name'>Name</MenuItem>
                            <MenuItem value='schedule'>Schedule Time</MenuItem>
                            <MenuItem value='risk'>Admission Risk</MenuItem>
                            <MenuItem value='risk2'>Admission Risk (estimated)</MenuItem>
                        </Select>
                        */
                    }
                </Grid>
                <Grid item xs={9}>
                    {
                        count > 0 ? <Pagination
                            page={page}
                            count={Math.ceil(count / 10)}
                            onChange={onPageChange}
                            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px', flexGrow: 1 }}
                            hidden={count < 10}
                        /> : <></>
                    }
                </Grid>
            </Grid>
            <List>
                {!peOpen && patientList}
            </List>
            {peOpen ? <PatientEditor
                open={true} onClose={hidePE} onSave={onPatientSaved}
                patient={patientToEdit} client={client} plans={plans}/>
                : <></>}
            <Outlet />
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={onMenuClose}
                sx={{
                    '.MuiListItemText-root': {
                        paddingRight: '16px'
                    }
                }}
            >
                <MenuItem title='Roughly cluster visits' onClick={onClusterClick} >
                    <ListItemIcon><WorkspacesIcon /></ListItemIcon>
                    <ListItemText>Clusters</ListItemText>
                    <ListItemSecondaryAction>
                        {clusterJobStatus == JobStatus.IN_PROGRESS
                            ? <CircularProgress size={16} color='inherit' />
                            : clusterJobStatus == JobStatus.SUCCESS
                                ? <Check color='inherit' />
                                : null
                        }</ListItemSecondaryAction>
                </MenuItem>
                <MenuItem title='Build a detailed schedule' onClick={onScheduleClick}>
                    <ListItemIcon><ScheduleIcon /></ListItemIcon>
                    <ListItemText>Schedule</ListItemText>
                </MenuItem>
            </Menu>
            {clOpen
                ? <ClusterDialog
                    open
                    onClose={hideCL}
                    jobs={jobs.filter(j => j.name == 'cluster') as ClusterJob[]}
                    client={client}
                    plans={plans} />
                : ''
            }
            {scOpen
                ? <ScheduleDialog
                    open
                    onClose={hideSc}
                    jobs={jobs.filter(j => j.name == 'schedule') as ScheduleJob[]}
                    client={client}
                    plans={plans} />
                : ''}
            { a1dOpen ? assignConfirmSingleDialog : <></> }
            { aMdOpen ? assignConfirmQueryDialog : <></> }
        </AppBarPage>
    );
}

export default PatientsPage;
