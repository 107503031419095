import * as React from 'react';
import { useCallback, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce';


interface FetchAutocompleteProps<T> extends Omit<
    AutocompleteProps<T, false, false, false>,
    'onChange' | 'onInputChange'
> {
    onChange?: (value: T | null) => void;
    onInputChange?: (value: string) => void;
}

const FetchAutocomplete = <T extends object>(
    { onChange, onInputChange, ...props }: FetchAutocompleteProps<T>
): JSX.Element => {
    const [inputValue, setInputValue] = useState('');
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetOptions = useCallback(debounce((inputValue: string) => {
        onInputChange && onInputChange(inputValue);
    }, 500), [onInputChange]);

    const callOnChange = useCallback((_event: unknown, newValue: T | null) => {
        onChange && onChange(newValue);
    }, [onChange]);

    const onInputChange_ = useCallback((_event: unknown, newInputValue: string) => {
        setInputValue(newInputValue);
        debouncedSetOptions(newInputValue);
    }, [debouncedSetOptions]);

    return (
        <Autocomplete
            autoComplete
            inputValue={inputValue}
            onChange={callOnChange}
            onInputChange={onInputChange_}
            {...props}
        />
    );
}
export default FetchAutocomplete;