import { AxiosError } from 'axios';
import * as React from 'react';
import { useRouteError } from 'react-router-dom';
import rg4js from 'raygun4js';

const ErrorPage = (): JSX.Element => {
    const error = useRouteError() as AxiosError;
    console.error(error);
    rg4js('send', {
        error:error
    });
    return (
        <div id="error-page" style={{ margin: '16px' }}>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.message}</i>
            </p>
            {error instanceof AxiosError
                ? (<pre>{JSON.stringify(error.response && error.response?.data, null, 2)}</pre>)
                : null
            }
        </div>
    );
}

export default ErrorPage;