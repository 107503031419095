import { useState, useCallback } from 'react';

interface ChangeTracker {
    testStringChanges: (name: string, oldValue: string, newValue: string) => string;
    hasChanged: (name: string) => boolean;
    reset: () => void;
    getChangesString: () => string;
}

export function useChangeTracker(): ChangeTracker {
    const [changes, setChanges] = useState<{ [key: string]: boolean }>({});

    const testStringChanges = useCallback(
        (name: string, oldValue: string, newValue: string) => {
            if (oldValue !== newValue) {
                setChanges(prevChanges => ({ ...prevChanges, [name]: true }));
            }
            return newValue;
        }, []);

    const hasChanged = useCallback(
        (name: string) => {
            return !!changes[name];
        },[changes]);

    const reset = useCallback(() => {
        setChanges({});
    }, []);

    const getChangesString = useCallback(() => {
        return Object.keys(changes).join(',');
    }, [changes]);

    return { testStringChanges, hasChanged, reset, getChangesString };
}