import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

type SearchParamsDict<T extends keyof any> = Partial<Record<T, string>>;

export default function useSearchParamsDict<T extends keyof any>(
): [SearchParamsDict<T>, ((sp: SearchParamsDict<T>) => void)] {
    const [searchParamsInt, setSearchParamsInt] = useSearchParams();

    const searchParams = useMemo(() => 
        Object.fromEntries(searchParamsInt.entries()) as SearchParamsDict<T>, 
    [searchParamsInt]);
    
    const setSearchParams = useCallback((sp: SearchParamsDict<T>) => {
        const oldEntries = Array.from(searchParamsInt.entries()) as [T,string][];
        const newEntries = Object.entries<string>(sp as { [k: string]: string; });
        setSearchParamsInt([
            ...oldEntries.map<[string,string]>(([k,v]) => [k as string, sp[k]!=undefined ? sp[k]! : v]),
            ...newEntries.filter(([k]) => !searchParamsInt.get(k))
        ].filter(([_,v]) => !!v))
    }, [searchParamsInt, setSearchParamsInt]);
    
    return [searchParams, setSearchParams];
}
