import * as React from 'react';
import { useCallback, useState } from 'react';
import { Box, CircularProgress, IconButton, ListItem, ListItemProps, Stack, StackProps, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

import { FixedSizeList, ListChildComponentProps } from 'react-window';

interface CheckListItemProps extends ListItemProps {
    checked?: boolean;
    index?: number;
}

export const CheckListItem = ({ checked, index, ...props }: CheckListItemProps): JSX.Element => (
    <ListItem sx={{ background: checked ? '#1976d220' : undefined, cursor: 'pointer' }} x-index={index} {...props} />
)

interface VirtualCheckListProps<T> extends Omit<StackProps, 'children'> {
    items: T[];
    itemFn: (item: T) => React.ReactElement;
    selection?: number[];
    onSelectionChange?: (newSelection: number[]) => void;
    actions?: React.ReactNode;
    loading?: boolean;
}

function VirtualCheckList<T>({ items, itemFn, selection, onSelectionChange, actions, loading, ...props }: VirtualCheckListProps<T>): JSX.Element {

    const onItemClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        console.log(event);
        const i = Number.parseInt(event.currentTarget.attributes.getNamedItem('x-index')?.value || '-1', 10);
        if (selection?.includes(i)) {
            onSelectionChange && onSelectionChange(selection.filter(x => x != i));
        }
        else {
            onSelectionChange && onSelectionChange([...(selection || []), i]);
        }
    }, [onSelectionChange, selection]);

    const Row = React.useCallback(({ index, style }: ListChildComponentProps<T>) => (
        React.cloneElement(
            itemFn(items[index]),
            {
                checked: selection?.includes(index) || false,
                index: index,
                style: style,
                onClick: onItemClick
            })
    ), [itemFn, items, selection, onItemClick]);

    return <Stack position='relative' {...props}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='body1'>{selection?.length} of {items.length} are selected</Typography>
            <Box>{actions}</Box>
        </Stack>
        <FixedSizeList
            itemSize={72}
            itemCount={items.length}
            height={260}
            width={320}
            style={{ overflowY: 'scroll' }}
        >{Row}</FixedSizeList>
        {loading
            ? <Box sx={{
                position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                background: '#ffffff80'
            }}>
                <CircularProgress />
            </Box>
            : null}
    </Stack>
}

interface TransferListProps<T> extends Omit<StackProps, 'onChange'> {
    items: T[];
    choosenItems: T[];
    loading?: boolean;
    itemFn: (item: T) => React.ReactElement;
    eqFn?: (x: T, y: T) => boolean;
    onChange?: (items: T[]) => void;
}

function TransferList<T>({ items, choosenItems, loading, itemFn, eqFn, onChange, ...props }: TransferListProps<T>): JSX.Element {
    const [leftSelection, setLeftSelection] = useState<number[]>([]);
    const [rightSelection, setRightSelection] = useState<number[]>([]);

    const onChooseItems = useCallback(() => {
        onChange && onChange([...choosenItems, ...leftSelection.map(i => items[i]).filter(item => !eqFn || !choosenItems.find(x => eqFn(x, item)))]);
        setLeftSelection([]);
    }, [choosenItems, eqFn, items, leftSelection, onChange]);

    const onChooseAllItems = useCallback(() => {
        onChange && onChange([...choosenItems, ...items.filter(item => !eqFn || !choosenItems.find(x => eqFn(x, item)))])
        setLeftSelection([]);
    }, [choosenItems, eqFn, items, onChange]);

    const onDeleteItems = useCallback(() => {
        onChange && onChange(choosenItems.filter((_, i) => !rightSelection.includes(i)));
        setRightSelection([]);
    }, [choosenItems, rightSelection, onChange]);

    const onDeleteAllItems = useCallback(() => {
        onChange && onChange([]);
        setRightSelection([]);
    }, [onChange]);

    return <Stack direction='row' justifyContent='space-between' sx={{ ul: { minWidth: '320px' } }} {...props}>
        <VirtualCheckList
            items={items}
            loading={loading}
            itemFn={itemFn}
            selection={leftSelection}
            onSelectionChange={setLeftSelection}
            actions={<>
                <IconButton onClick={onChooseItems} disabled={leftSelection.length == 0}><AddIcon /></IconButton>
                <IconButton title='Add all' onClick={onChooseAllItems}><DoneAllIcon /></IconButton>
            </>}
        />
        <Box width={16} height={1} />
        <VirtualCheckList
            items={choosenItems}
            itemFn={itemFn}
            selection={rightSelection}
            onSelectionChange={setRightSelection}
            sx={{ flexGrow: 1 }}
            actions={<>
                <IconButton onClick={onDeleteItems} disabled={rightSelection.length == 0}><DeleteIcon /></IconButton>
                <IconButton title='Remove all' onClick={onDeleteAllItems} disabled={choosenItems.length == 0}><RemoveDoneIcon /></IconButton>
            </>}
        />
    </Stack>
}

export default TransferList;