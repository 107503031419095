import * as React from 'react';
import { useCallback, useState } from 'react';
import { Client, UserAccessInfo } from '../models/core';
import { Practice } from '../models/ziphy';
import { Typography, Grid, List, IconButton, TextField } from '@mui/material';
import { GridFormItem } from './GridForm';

import EntityEditor, { EntityEditorProps } from './EntityEditor';
import { analytics } from '../utils/analytics/zipAnalytics';
import { useAuth } from '../hooks/useAuth';
import useDialog from '../hooks/useDialog'

import { AutocompleteEnumField } from './fields';

import ListItem from '@mui/material/ListItem';
import { ListItemSecondaryAction, ListItemButton, Tooltip, Box } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import SpinnerButton from './SpinnerButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BusyDialog from './BusyDialog';
import { API } from '../utils/Api';
import { API as ZiphyAPI } from '../utils/ZiphyAPI';
import { DateTime } from 'luxon';

interface UserAccessItemProps {
    userData: UserAccessListItemData;
    isActive: boolean
    //onUpdate?:(user:UserAccessListItemData) => void;
    onUpdateStatus?:(user:UserAccessListItemData, active:boolean) => void;
}

interface UserAccessListItemData {
    name:string;
    id:number;
    options:string[],
    available:boolean;
}

const UserAccessListItem = ({ userData, isActive, onUpdateStatus, ...props }: UserAccessItemProps): JSX.Element => {

    /*const onUpdateUser = React.useCallback((label:string, values:string[]|null) => {
        userData.options = values||[];
        onUpdate && onUpdate(userData);
    }, [onUpdate, userData]);*/

    const onAddClick = React.useCallback(() => {
        userData.options = ['assignable'];
        onUpdateStatus && onUpdateStatus(userData, true);
    }, [onUpdateStatus, userData]);

    const onRemoveClick = React.useCallback(() => {
        onUpdateStatus && onUpdateStatus(userData, false);
    }, [onUpdateStatus, userData]);

    const activeDisplay = React.useMemo(() => {
        return (
        <ListItemSecondaryAction>
            <Tooltip id="tt-user-add" title='Remove the user from the clients active list'>
                <IconButton color='inherit' onClick={onRemoveClick}><RemoveIcon /></IconButton>
            </Tooltip>
        </ListItemSecondaryAction>)
    }, [onRemoveClick])

    const availableDisplay = React.useMemo(() => {
        return (<ListItemSecondaryAction>
            <Tooltip id="tt-user-add" title='Add user as an active user for client'>
                <IconButton color='inherit' onClick={onAddClick}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>)
    }, [onAddClick])

    return (
        <ListItem
            {...props}
            >
            <ListItemButton disableRipple>
                <ListItemText
                    primary={
                        <Typography component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                            {(userData.available ? '' : 'Not Found: ') + userData.name}
                        </Typography>
                    }
                    secondary={
                        <span>
                            User Id: {userData.id}
                        </span>}
                />
                { isActive ? activeDisplay : availableDisplay }
            </ListItemButton>
        </ListItem>
    );
};

interface AccessEditorProps extends Omit<EntityEditorProps,'title'|'doSave'> {
    client?: Client;
    practices?:Practice[]
}

const AccessEditor = ({ client, practices, readOnly, ...props }: AccessEditorProps): JSX.Element => {

    const [activeUsers, setActiveUsers] = useState<UserAccessListItemData[]>(
        client?.userAccess?.map((u) => { return { name:u.name, id:u.id, options:u.options, available:true, } })
        || client?.repNames?.map((u) => { return { name:u, id:-1, options:['assignable'], available:true, } })
        || []
    );
    const [availableUsers, setAvailableUsers] = useState<UserAccessListItemData[]>([]);
    const [userFilterRoles, setUserFilterRoles] = useState<string[]>(['Admin', 'Practice Admin', 'Dispatcher']);

    const [practiceIdsList, setPracticeIdsList] = useState<string[]>([client?.practiceId ? client?.practiceId + '' : 'all']);
    const [syncingUsers, setSyncingUsers] = useState(false);

    const { open: busyOpen, show: showBusy, hide: hideBusy } = useDialog();

    const auth = useAuth();

    const availablePracticeIdList:string[] =React.useMemo(() =>{
        return[
            'all',
            ...(practices || []).flatMap((value)=> (value.id + '')) || []
        ]
    },[practices]);

    const outreachFilterMap: {[key: string]:string} = React.useMemo(() => {
        return {
            'Admin': 'admin',
            'Practice Admin': 'practice_admin',
            'Dispatcher': 'dispatcher',
            'Provider': 'provider',
            'Agent': 'agent',
        };
    }, []);

    
    const lastEditFooter = React.useMemo(() => {
        return { text:(!client?.userAccess || client?.userAccess.length == 0 
            ? 'WARNING: OLD REPLIST IN USE, PLEASE REGENERATE USER ACCESS LIST' : '') };
    }, [client]);

    const onSubmitClick = useCallback(async () => {
        
        showBusy();

        const users:UserAccessInfo[] = activeUsers.map<UserAccessInfo>((item) => {
            return { 
                name:item.name,
                id:item.id,
                assignable:true,
                options:['assignable']
            }
        })
        const data = {
            userAccess:users,
            repNames:[...activeUsers.map<string>((item)=>{return item.name}), 'Unknown']
        };
        if (client && users) {
            const ts = DateTime.now().toString();
            const assigner = auth?.user?.name || 'NA';
            analytics.track('user-access', { type:'assign', by: assigner, date:ts }, true);

            await API.updateClient({ ...client, ...data })
        }
        hideBusy();
        return true;
    }, [client, activeUsers, showBusy, hideBusy, auth?.user]);

    const onUpdateUserList = useCallback(async () => {
        const ids = practiceIdsList.includes('all') ? Object.values(availablePracticeIdList).flatMap((b) => +b) : practiceIdsList.flatMap((c) => +c);
        setSyncingUsers(true);
        const filterRoles = userFilterRoles.flatMap((k) => outreachFilterMap[k]) || [];

        const response = await ZiphyAPI.getRoles(
            filterRoles.includes('admin')
                ? {
                        or: [ 
                        { and: [ {in: ['practice_id', ids ]}, {eq: ['is_active', true]}, {in: ['role', filterRoles]},] },
                        { and: [ {eq: ['practice_id', null ]}, {eq: ['is_active', true]}, {eq: ['role', 'admin']}, ] },
                    ]
                }
                : { and: [ {in: ['practice_id', ids ]}, {eq: ['is_active', true]}, {in: ['role', filterRoles]},] }
            ).catch(console.error);
        
        const roles = response?.roles;

        const newUserList:UserAccessListItemData[] = [];
        const knownIds:number[] = [...activeUsers.map<number>((item)=> item.id)];

        //  Add in the the new users
        roles?.items?.forEach((item) => {
            if(item?.name != undefined && item?.user_id >= 0 && knownIds.indexOf(item?.user_id) == -1) {
                newUserList.push(
                    {
                        name:item?.name,
                        id:item?.user_id,
                        options:[],
                        available:true,
                    }
                );
                knownIds.push(item?.user_id);
            }
        });

        setAvailableUsers(newUserList);

        setSyncingUsers(false);
    }, [setAvailableUsers, setSyncingUsers, activeUsers, practiceIdsList,
        userFilterRoles, outreachFilterMap, availablePracticeIdList]);

    const onToggleUserList = React.useCallback((user:UserAccessListItemData, addUser:boolean) => {
        if(addUser) {
            //  Remove from the available array
            setAvailableUsers(availableUsers => availableUsers.filter((item) => { return item.id != user.id; }))
            //  Check that the user is not already in the active users
            if(activeUsers.findIndex((item,i) => { item.id == user.id ? i : -1 }) == -1) {
                setActiveUsers([...activeUsers, user]);
            }
        } else {
            setActiveUsers(activeUsers => activeUsers.filter((item) => { return item.id != user.id; }))
            if(availableUsers.findIndex((item,i) => { item.id == user.id ? i : -1 }) == -1) {
                setAvailableUsers([...availableUsers, user]);
            }
        }
    }, [setAvailableUsers, setActiveUsers, availableUsers, activeUsers]);

    const activeUserList = React.useMemo(() => {
            return (activeUsers || []).map((user) => (
                <UserAccessListItem
                    key={user.id}
                    userData={user}
                    isActive={true}
                    //onUpdate={onUpdateUserListItem}
                    onUpdateStatus={onToggleUserList}
                />
            ));
        },
        [activeUsers, onToggleUserList]
    );

    const availableUserList = React.useMemo(() => {
        return (availableUsers || []).map((user) => (
            <UserAccessListItem
                key={user.id}
                userData={user}
                isActive={false}
                onUpdateStatus={onToggleUserList}
            />
        ));
    }, [availableUsers, onToggleUserList]);

    const onRoleSelectChange = React.useCallback((label: string, newValue: string[] | null) => {
        setUserFilterRoles(newValue || []);
    }, []);

    const onPracticeIdSelectChange = React.useCallback((label: string, newValue: string[] | null) => {
        setPracticeIdsList(newValue || []);
    }, []);

    const practiceIdSelectComps = React.useMemo(() => {
        const hasAccess = auth?.role == 'admin'
            || auth?.roles?.find((r) => { return r.role == 'admin' && r.practice_id == client?.practiceId }) != undefined 
            || false;
        return hasAccess ? (<AutocompleteEnumField
            name='Practice Ids'
            value={practiceIdsList}
            options={availablePracticeIdList}
            hiddenLabel
            clearItem='all'
            onChange={onPracticeIdSelectChange}
        />)
        : (<TextField label={'Practice Id'} name={'pi' + client?.practiceId } 
            value={client?.practiceId || 'unknown'} variant='standard' disabled={true}/>);
    }, [auth, client, availablePracticeIdList, practiceIdsList, onPracticeIdSelectChange]);


    React.useEffect(() => {
        setActiveUsers(
            client?.userAccess?.map((u) => { return { name:u.name, id:u.id, options:u.options, available:true, } })
            || client?.repNames?.map((u) => { return { name:u, id:-1, options:['assignable'], available:true, } })
            || []
        );
        setAvailableUsers([]);
        setUserFilterRoles(['Admin', 'Practice Admin', 'Dispatcher']);
        setPracticeIdsList([client?.practiceId ? client?.practiceId + '' : 'all']);
        setSyncingUsers(false);
    }, [client])
    
    return (<EntityEditor {...props} readOnly={readOnly} title={(readOnly ? 'View' : 'Edit') + ' User Access'} 
                footer={lastEditFooter} doSave={onSubmitClick}>
            <GridFormItem xs={12}>
            <Grid container spacing={2}>
                <GridFormItem xs={12}>
                    <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                        { client?.name || client?.practiceId || 'unknown' }
                    </Typography>
                </GridFormItem>
                <GridFormItem xs={12}>
                    <Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Available User Filter</Typography>
                </GridFormItem>
                <GridFormItem xs={7}>
                    <AutocompleteEnumField
                        name='Role'
                        value={userFilterRoles}
                        options={Object.keys(outreachFilterMap)}
                        hiddenLabel
                        onChange={onRoleSelectChange}
                    />
                </GridFormItem>
                <GridFormItem xs={3}>
                    {practiceIdSelectComps}
                </GridFormItem>

                <GridFormItem xs={2}>
                    <Tooltip id="tt-user-au" title='Update the list of available users to add to active users. The current list of available users will be lost.'>
                        <div>
                        <SpinnerButton onClick={onUpdateUserList} showSpinner={syncingUsers} >Refresh</SpinnerButton>
                        </div>
                    </Tooltip>
                </GridFormItem>
                <GridFormItem xs={6}>
                    <Box component="section" sx={{ height: '110px', p: 2, border: '1px solid grey' }}>
                        <Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Available</Typography>
                        <Typography variant='subtitle2' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Users who may be added to this list.</Typography>
                    </Box>
                    <Box component="section" sx={{ p: 2, border: '1px solid grey' }}>
                        <List>
                            {availableUserList}
                        </List>
                    </Box>
                </GridFormItem>
                <GridFormItem xs={6}>
                    <Box component="section" sx={{ height: '110px', p: 2, border: '1px solid grey' }}>
                        <Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Approved</Typography>
                        <Typography variant='subtitle2' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>User currently part of the assignable list.</Typography>
                    </Box>
                    <Box component="section" sx={{ p: 2, border: '1px solid grey' }}>
                        <List>
                            {activeUserList}
                        </List>
                    </Box>
                </GridFormItem>
            </Grid>
            </GridFormItem>
            { busyOpen ? <BusyDialog open={busyOpen}/> : <></> }
        </EntityEditor >);
}

export default AccessEditor;