import { ZipAnalyticModule } from './zipAnalytics.interfaces'

export class ZipAnalytics {
  static readonly MIXPANEL_KEY: string = 'mix'
  static readonly RAYGUN_KEY: string = 'ray'
  static readonly CRASHLYTICS_KEY: string = 'crash'
  static readonly PORTAL_KEY: string = 'portal'

  private readonly NOT_SET_ID: string = 'na'

  private navTime: number
  private navPage: string
  private unique_id: string
  private modules: { [Key: string]: ZipAnalyticModule }

  constructor() {
    this.navTime = new Date().getTime()
    this.navPage = ''
    this.unique_id = this.NOT_SET_ID
    this.modules = {}
  }

  registerModule(key: string, module: ZipAnalyticModule): void {
    if (!module) {
      return
    }
    if (!this.modules[key]) {
      module.register()
      this.modules[key] = module
    }
  }

  getModule(key: string): ZipAnalyticModule {
    return this.modules[key]
  }

  startSession(id: string, profile: { [key: string]: string | number | boolean }): void {
    if (this.unique_id !== id && id !== this.NOT_SET_ID) {
      
      this.unique_id = id
      for (const key in this.modules) {
        this.modules[key]?.startSession(id, profile)
      }
    }
  }

  endSession(): void {
    if (this.unique_id !== this.NOT_SET_ID) {
      for (const key in this.modules) {
        this.modules[key]?.endSession()
      }
      this.unique_id = this.NOT_SET_ID
      this.flush()
    }
  }

  isSessionActive(): boolean {
    return this.unique_id !== this.NOT_SET_ID && this.unique_id !== null && this.unique_id !== '';
  }

  flush(): void {
    for (const key in this.modules) {
      this.modules[key]?.flush()
    }
  }

  breadcrumb(event: string, data: { [key: string]: string | number | boolean }): void {
    if (event) {
      for (const key in this.modules) {
        this.modules[key]?.breadcrumb(event, data)
      }
    }
  }

  error(
    event: string | Error,
    data: { [key: string]: string | number | boolean },
    priority = false,
  ): void {
    if (event) {
      for (const key in this.modules) {
        this.modules[key]?.error(event, data, priority)
      }
    }
  }

  nav(page: string,
    parse = true): void {
    const tm: number = new Date().getTime()
    const dt: number = tm - this.navTime
    if (page && parse) {
      const subIdx = page.lastIndexOf('/')
      if (subIdx > 0) {
        page = page.substring(subIdx)
      }
    }
    for (const key in this.modules) {
      this.modules[key]?.nav(this.navPage, page, dt)
    }
    this.navTime = tm
    this.navPage = page
  }

  track(event: string, data: { [key: string]: string | number | boolean | string[]}, priority = false): void {
    if (event) {
      for (const key in this.modules) {
        this.modules[key]?.track(event, data, priority)
      }
    }
  }
}

export const analytics: ZipAnalytics = new ZipAnalytics()
export default ZipAnalytics
