import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, 
    DialogTitle, DialogContentText} from '@mui/material';
import { Client } from '../models/core';
import { EnumField } from './fields';
import BusyDialog from './BusyDialog';


export interface AssignmentDialogProps extends DialogProps {
    client: Client;
    title?: string;
    open:boolean;
    showIfBusy?:boolean;
    bodyHeader?: string;
    bodyFooter?: string;
    defaultValue?:string;
    optionTitle?:string;
    onClose?:()=>void;
    onAssign?:(value:string)=>Promise<void>;
    onUpdate?:(value:string)=>void;
}

const AssignmentDialog = ({ client, open, showIfBusy, title, defaultValue, optionTitle, bodyHeader, bodyFooter, 
    onClose, onAssign, onUpdate, ...props }: AssignmentDialogProps): JSX.Element => {
    const [selected, setSelected] = useState<string>(defaultValue || 'None');
    const [assigning, setAssigning] = useState(false);

    const closeDialog = useCallback(()=> {
        onClose && onClose();
    },[onClose])

    const handleDialogClose = useCallback((event:object, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && reason === "backdropClick") 
            return;
        closeDialog();
    },[closeDialog]);

    const handleOnAgree = useCallback(async ()=> {
        if(showIfBusy == true) { setAssigning(true); }
        onAssign && await onAssign(selected);
        if(showIfBusy == true) { setAssigning(false); }
    },[onAssign, selected])

    const handleOnUpdate = useCallback((name: string, value: string | null)=> {
        setSelected(value||'')
        onUpdate && onUpdate(selected);
    },[selected,onUpdate])

    const assignableNames = React.useMemo(() => { return client.repNames ? [...client.repNames, 'None'] : []; }, [client.repNames]);
    const defaultInList = React.useMemo(() => {
        const uInlc = defaultValue ? assignableNames.findIndex((name, _)=>{ return name?.trim()?.toLowerCase() == defaultValue?.trim()?.toLowerCase()}) : -1;
        return uInlc != -1;
    }, [assignableNames, defaultValue])

    return (
        <>
        <Dialog maxWidth='md' open={open} onClose={handleDialogClose}>
            <DialogTitle>{ (title && `${title}`) || 'Confirm Assignment' }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                { (bodyHeader && `${bodyHeader}`) || defaultInList ? 'Confirm Assigned User' : 'Please Choose User from List'}
                <EnumField name={optionTitle || 'users'} label={optionTitle || 'Users'} value={selected} 
                    onChange={handleOnUpdate} options={assignableNames} required/>
                {bodyFooter && `${bodyFooter}`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnAgree} color="primary">Assign</Button>
                <Button onClick={closeDialog} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
        { assigning ? <BusyDialog open={assigning}/> : <></> }
        </>
    );
};

export default AssignmentDialog;