import * as React from 'react';
import { Box, IconButton } from '@mui/material';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

interface ResultTableProps {
    data: string[][];
    header: string[];
    groupColumns: number;
}

const ResultTable = ({ data, header, groupColumns }: ResultTableProps): JSX.Element => {
    const onBookClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        const agentId = event.currentTarget.getAttribute('x-agent-id')
        const day = event.currentTarget.getAttribute('x-day')
        const startTime = event.currentTarget.getAttribute('x-start-time')
        console.log(agentId, day, startTime);
    }, []);
    return (
        <Box sx={{
            maxHeight: '640px',
            overflowY: 'auto',
            table: {
                margin: '8px',
                whiteSpace: 'nowrap',
                'td,th': {
                    borderTop: `1px dotted gray`,
                    borderLeft: `1px dotted gray`,
                    padding: '0 4px',
                    maxWidth: '24em',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden'
                },
                'td:last-child, th:last-child': {
                    borderRight: `1px dotted gray`,
                },
                'tr:last-child td': {
                    borderBottom: `1px dotted gray`,
                }
            }
        }}>
            <table>
                <thead>
                    <tr>
                        {header.map((cell, i) => <th key={i}>{cell}</th>)}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.sort((x, y) => x.slice(0, groupColumns) < y.slice(0, groupColumns) ? -1
                        : x.slice(0, groupColumns) > y.slice(0, groupColumns) ? 1
                            : 0
                    ).flatMap((row, i, rows) => [
                        ...[...new Array(groupColumns)].map((_, j) => (i == 0 || rows[i - 1][j] != row[j])
                            ? <tr key={`${i}-${j}`}>
                                {[...new Array(j)].map((_, k) => <td key={k}>&nbsp;</td>)}
                                <td colSpan={50}>{row[j]}</td>
                            </tr>
                            : null
                        ),
                        <tr key={i}>
                            {[...new Array(groupColumns)].map((_, k) => <td key={k}>&nbsp;</td>)}
                            {row.slice(groupColumns).map((cell, j) => <td key={j + groupColumns}>{cell}</td>
                            )}
                            <td>
                                <IconButton
                                    title='Book'
                                    color='primary'
                                    // x-agent-id={cell.item.agentId}
                                    // x-day={cell.item.day}
                                    // x-start-time={cell.item.startTime}
                                    onClick={onBookClick}
                                    sx={{padding: 0}}>
                                    <ImportContactsIcon fontSize='small' />
                                </IconButton>
                            </td>
                        </tr>
                    ])}
                </tbody>
            </table>
        </Box>
    );
}

export default ResultTable;