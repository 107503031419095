import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Snackbar, TextField } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { GridFormItem } from './GridForm';
import { Client, Field, Layout, Options, ParentField } from '../models/core';
import { Accordion, AccordionDetails, AccordionSummary } from './schema/Accordion';
import OptionsEditor from './schema/Options';
import { PatientFieldsList, MeasureFieldsList } from './schema/FieldList';
import { useAuth } from '../hooks/useAuth';
import Preview from './schema/Preview';
import { defaultLayout, id } from './schema/default';
import ScriptEditor from './schema/ScriptEditor';
import { API } from '../utils/Api';
import { Practice } from '../models/ziphy';

import EntityEditor, { EntityEditorProps } from './EntityEditor';
import QueryButton from './QueryButton';


function addNewFields<T extends Field>(f1: T[], f2: T[]): T[] {
    return [...f1, ...f2.filter(f => f1.findIndex(ff => ff.field && ff.field == f.field || ff.defaultLabel == f.defaultLabel) < 0)];
}

function insertField<T extends Field>(fields: T[], newField: T, after: string, offset = 1) {
    if (fields.filter(f => f.field == newField.field).length == 0) {
        fields.splice(
            fields.findIndex(f => f.field == after) + offset, 0, newField
        );
    }
    return fields;
}

interface Props extends Omit<EntityEditorProps, 'title' | 'doSave'> {
    practices: Practice[];
    client?: Client;
    duplicate?: boolean;
}

const ClientEditor = ({ client, practices, duplicate, ...props }: Props): JSX.Element => {
    const auth = useAuth();
    const [practice, setPractice] = useState<Practice | null>(practices?.find(r => r.id == client?.practiceId) || null);
    const [sheetId, setSheetId] = useState(client?.sheetId || '');
    const [importSheetId, setImportSheetId] = useState(client?.importSheetId || '');
    const [code, setCode] = useState(client?.importFunctionCode || '');
    const [exportSheetId, setExportSheetId] = useState(client?.exportSheetId || '');
    const [exportCode, setExportCode] = useState(client?.exportFunctionCode || '');
    const [codeMode, setCodeMode] = useState<'import'|'export'|null>(null);
    const [msrFields, setMsrFields] = useState(addNewFields(client?.layout?.measureFields || [], defaultLayout.measureFields));
    const [patFields, setPatFields] = useState(addNewFields(client?.layout?.patientFields || [], defaultLayout.patientFields));
    const [chkFields, setChkFields] = useState(addNewFields(client?.layout?.checkFields || [], defaultLayout.checkFields));
    const [vstFields, setVstFields] = useState(addNewFields(client?.layout?.visitFields || [], defaultLayout.visitFields));
    const [options, setOptions] = useState((client && client.layout || defaultLayout).options);
    const [sheets, setSheets] = useState<{ name: string; columns: string[] }[] | null>(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [errors, setErrors] = useState({ name: false, sheetId: false });

    useEffect(() => {
        if (client) {
            setSheetId(client?.sheetId || '');
            setImportSheetId(client?.importSheetId || '');
            setCode(client?.importFunctionCode || '');
            setExportSheetId(client?.exportSheetId || '');
            setExportCode(client?.exportFunctionCode || '');
            setCodeMode(null);
            setMsrFields(addNewFields(client?.layout?.measureFields || [], defaultLayout.measureFields));
            setPatFields(addNewFields(client?.layout?.patientFields || [], defaultLayout.patientFields));
            setChkFields(addNewFields(client?.layout?.checkFields || [], defaultLayout.checkFields));
            setVstFields(addNewFields(client?.layout?.visitFields || [], defaultLayout.visitFields));
            setOptions((client && client.layout || defaultLayout).options);
            setPractice(practices?.find(r => r.id == client.practiceId) || null);
        }
    }, [client, practices]);

    const layout = React.useMemo<Layout>(() => (
        { options, patientFields: patFields, measureFields: msrFields, checkFields: chkFields, visitFields: vstFields }
    ), [chkFields, msrFields, options, patFields, vstFields]);

    const layoutJson = React.useMemo(() => (
        JSON.stringify(layout)
    ), [layout]);

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            // case 'name': setName(event.target.value); break;
            case 'sheetId': setSheetId(event.target.value); break;
            case 'importSheetId': setImportSheetId(event.target.value); break;
            case 'exportSheetId': setExportSheetId(event.target.value); break;
            default:
                break;
        }
    }, []);

    const onCreateSheetClick = useCallback(() => {
        const fetch = async () => {
            const response = await API.createSheet(
                layout,
                auth && auth.account.value || ''
            );
            setSheetId(response.sheetId);
            setSnackOpen(true);
        };
        fetch().catch(console.error);
    }, [auth, layout]);

    const onSnackClose = useCallback((event: React.SyntheticEvent | Event, reason: string) => {
        if (reason != 'clickaway') {
            setSnackOpen(false);
        }
    }, []);

    const onPreviewClick = useCallback(() => {
        const fetch = async () => {
            const response = await API.previewSheet(layout);
            setSheets(response);
        };
        fetch().catch(console.error);
    }, [layout]);

    const closePreview = useCallback(() => {
        setSheets(null);
    }, []);

    const onEditImportClick = useCallback(() => {
        setCodeMode('import');
    }, []);

    const onEditExportClick = useCallback(() => {
        setCodeMode('export');
    }, []);

    const onExportClick = useCallback(async () => {
        client && await API.export(exportCode, exportSheetId, client._id);
    }, [client, exportCode, exportSheetId]);

    const testCode = useCallback(async (code: string) => {
        if (codeMode == 'export' && client) {
            return await API.testExportScript(code, client._id);
        }
        if (codeMode == 'import') {
            return JSON.stringify(await API.testImportScript(code, importSheetId), undefined, 2)
        }
        return '';
    }, [client, codeMode, importSheetId]);

    const saveScript = useCallback((code: string) => {
        if (codeMode == 'export') {
            setExportCode(code);
        }
        if (codeMode == 'import') {
            setCode(code);
        }
        setCodeMode(null);
    }, [codeMode]);


    const onPracticeChange = useCallback((_event: unknown, newValue: Practice | null) => {
        setPractice(newValue);
    }, []);

    const onOptionsChange = useCallback((newOptions: Options) => {
        setOptions(newOptions);
        if (newOptions.isMultiPlan) {
            setPatFields([...insertField(patFields, {
                label: 'Plan ID',
                defaultLabel: 'Plan ID',
                field: 'planId',
                id: id(),
                state: 'required',
                forChild: false
            }, 'refId')
            ]);
        }
        else {
            setPatFields(patFields.filter(f => f.field != 'planId'));
        }

        if (newOptions.checksSheet == newOptions.measuresSheet) {
            setChkFields([...insertField(msrFields, {
                label: 'Type',
                defaultLabel: 'Type',
                field: 'type',
                id: id(),
                state: 'required'
            }, 'name', 0)]);
            setMsrFields([...insertField(msrFields, {
                label: 'Type',
                defaultLabel: 'Type',
                field: 'type',
                id: id(),
                state: 'required'
            }, 'name', 0)]);
        }
        else {
            setChkFields(msrFields.filter(f => f.field != 'type'));
            setMsrFields(msrFields.filter(f => f.field != 'type'));
        }

        if (newOptions.visitsSheet) {
            const t = insertField(msrFields, {
                label: 'Visit ID',
                defaultLabel: 'Visit ID',
                field: 'visitId',
                id: id(),
                state: 'required'
            }, 'status');
            setMsrFields([...insertField(t, {
                label: 'Visit Date',
                defaultLabel: 'Visit Date',
                field: 'visitDate',
                id: id(),
                state: 'required'
            }, 'visitId')]);
        }
        else {
            setMsrFields(msrFields.filter(f => !['visitId', 'visitDate'].includes(f.field || '')));
        }
    }, [msrFields, patFields]);

    const patFieldFactory = useCallback(() => {
        const numCustomFields = patFields.filter(f => !f.field).length;
        return { id: id(), label: `Custom Field ${numCustomFields + 1}`, state: 'enabled', type: 'string', forChild: false } as ParentField;
    }, [patFields]);

    const msrFieldFactory = useCallback(() => {
        const numCustomFields = msrFields.filter(f => !f.field).length;
        return { id: id(), label: `Custom Field ${numCustomFields + 1}`, state: 'enabled', type: 'string' } as Field;
    }, [msrFields]);

    const onSubmit = useCallback(async () => {
        const errors = {
            name: !(practice?.name),
            sheetId: !sheetId
        };
        setErrors(errors);
        if (Object.values(errors).reduce((acc, val) => acc || val)) {
            return false;
        }
        const data: Omit<Client, '_id'> = {
            name: practice?.name || '',
            sheetId,
            isMultiPlan: false, // TODO remove
            practiceId: practice?.id || 0,
            layout,
            importSheetId,
            importFunctionCode: code,
            exportSheetId,
            exportFunctionCode: exportCode
        };
        if (client && !duplicate) {
            await API.updateClient({ ...client, ...data })
        }
        else {
            await API.createClient(data);
        }
        return true;
    }, [client, code, duplicate, exportCode, exportSheetId, importSheetId, layout, practice, sheetId]);

    return <>
        <EntityEditor {...props} title={client ? 'Edit Client' : 'New Client'} doSave={onSubmit}>
            <GridFormItem xs={12}>
                <Autocomplete
                    options={practices}
                    value={practice}
                    onChange={onPracticeChange}
                    autoHighlight
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            name='name'
                            label='Name'
                            // value={name || ''}
                            // onChange={onTextFieldChange}
                            variant='standard'
                            fullWidth
                            required
                            error={errors.name}
                            helperText={errors.name && 'Field is required'}
                        />
                    }
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    getOptionLabel={(option) => option.name || 'unlabeled'}
                />
            </GridFormItem>
            <GridFormItem xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    name='sheetId'
                    label='SheetId'
                    value={sheetId || ''}
                    onChange={onTextFieldChange}
                    variant='standard'
                    fullWidth
                    required
                    error={errors.sheetId}
                    helperText={errors.sheetId && 'Field is required'}
                />
                <a
                    href={`https://docs.google.com/spreadsheets/d/${sheetId}`}
                    target='_blank'
                    rel="noreferrer" >
                    <LaunchIcon sx={{
                        fontSize: 'large',
                        verticalAlign: 'text-bottom',
                        color: 'text.secondary',
                        visibility: sheetId ? 'visible' : 'hidden'
                    }} />
                </a>
            </GridFormItem>
            <GridFormItem xs={12} sx={{ display: 'flex', alignItems: 'baseline' }}>
                <TextField
                    name='importSheetId'
                    label='Import Sheet Id'
                    value={importSheetId}
                    onChange={onTextFieldChange}
                    variant='standard'
                    fullWidth
                />
                <a
                    href={`https://docs.google.com/spreadsheets/d/${importSheetId}`}
                    target='_blank'
                    rel="noreferrer" >
                    <LaunchIcon sx={{
                        fontSize: 'large',
                        verticalAlign: 'text-bottom',
                        color: 'text.secondary',
                        visibility: importSheetId ? 'visible' : 'hidden'
                    }} />
                </a>
                <Button onClick={onEditImportClick} disabled={!importSheetId} sx={{ whiteSpace: 'nowrap', margin: '0 8px' }}>Edit Script</Button>
            </GridFormItem>
            <GridFormItem xs={12} sx={{ display: 'flex', alignItems: 'baseline' }}>
                <TextField
                    name='exportSheetId'
                    label='Export File Id'
                    value={exportSheetId}
                    onChange={onTextFieldChange}
                    variant='standard'
                    fullWidth
                />
                <a
                    href={`https://docs.google.com/spreadsheets/d/${exportSheetId}`}
                    target='_blank'
                    rel="noreferrer" >
                    <LaunchIcon sx={{
                        fontSize: 'large',
                        verticalAlign: 'text-bottom',
                        color: 'text.secondary',
                        visibility: exportSheetId ? 'visible' : 'hidden'
                    }} />
                </a>
                <Button onClick={onEditExportClick} disabled={!exportSheetId} sx={{ whiteSpace: 'nowrap', margin: '0 8px' }}>Edit Script</Button>
                <QueryButton doFetch={onExportClick} disabled={!exportSheetId} sx={{ whiteSpace: 'nowrap', margin: '0 8px' }}>Export</QueryButton>
            </GridFormItem>
            <input type='hidden' name='practiceId' value={practice?.id || ''} />
            <input type='hidden' name='layout' value={layoutJson} />
            <input type='hidden' name='importFunctionCode' value={code} />
            <input type='hidden' name='importSheetId' value={importSheetId} />
            <GridFormItem xs={12}>
                <Button sx={{
                    position: 'absolute',
                    bottom: '8px',
                    left: '24px'
                }} onClick={onCreateSheetClick}>Create Spreadsheet</Button>
                <Button sx={{
                    position: 'absolute',
                    bottom: '8px',
                    left: '208px'
                }} onClick={onPreviewClick}>Preview</Button>
                <Preview sheets={sheets || []} open={!!sheets} onClose={closePreview} />
                <Box sx={{ paddingRight: '16px' }}>
                    <Accordion>
                        <AccordionSummary>Sheets</AccordionSummary>
                        <AccordionDetails sx={{ padding: '16px' }}>
                            <OptionsEditor value={options} onChange={onOptionsChange} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>Patient Fields</AccordionSummary>
                        <AccordionDetails>
                            <PatientFieldsList fields={patFields} factory={patFieldFactory} onChange={setPatFields} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>Visit Fields</AccordionSummary>
                        <AccordionDetails>
                            <PatientFieldsList fields={vstFields} factory={patFieldFactory} onChange={setVstFields} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>Measure Fields</AccordionSummary>
                        <AccordionDetails>
                            <MeasureFieldsList fields={msrFields} factory={msrFieldFactory} onChange={setMsrFields} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>Check Fields</AccordionSummary>
                        <AccordionDetails>
                            <MeasureFieldsList fields={chkFields} factory={msrFieldFactory} onChange={setChkFields} />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </GridFormItem>
        </EntityEditor >
        { codeMode ? <ScriptEditor
            open={true}
            onTest={testCode}
            initCode={codeMode=='export' ? exportCode : code}
            onSave={saveScript}
            
        /> : <></>}
        <Snackbar
            open={snackOpen}
            autoHideDuration={6000}
            onClose={onSnackClose}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            message='Spreadsheed was created'
            action={
                <a
                    href={`https://docs.google.com/spreadsheets/d/${sheetId}`}
                    target='_blank'
                    rel="noreferrer" >
                    <Button size='small'>Open</Button>
                </a>

            }
        />
    </>
}

export default ClientEditor;
