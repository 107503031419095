import { useCallback, useState } from "react";

export default function useDialog(): {
    open: boolean;
    show: () => void;
    hide: () => void;
} {
    const [open, setOpen] = useState(false);

    const show = useCallback(() => {
        setOpen(true);
    }, [])

    const hide = useCallback(() => {
        setOpen(false);
    }, []);

    return { open, show, hide };
}