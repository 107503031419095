import * as React from 'react';
import { useCallback, useState } from 'react';
import GridForm from './GridForm';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, 
    DialogTitle, DialogContentText, Tooltip, PaperProps} from '@mui/material';
import SpinnerButton from './SpinnerButton';

export interface EntityEditorDialogText {
    tooltip?: string;
    text: string;
}

export interface EntityEditorProps extends DialogProps {
    open: boolean;
    title: string;
    footer?: EntityEditorDialogText;
    doSave: () => Promise<boolean>;
    children?: string | JSX.Element | JSX.Element[];
    readOnly?: boolean;
    submitText?: string;
    backdropClose?: boolean;
    dialogPaperProps?:PaperProps;
    onClose?: () => void;
    onSave?: () => void;
}

const EntityEditor = ({ open, title, footer, readOnly, children, doSave, onClose, onSave, submitText = 'Save', backdropClose = true,
    dialogPaperProps, ...props }: EntityEditorProps): JSX.Element => {
    const [saving, setSaving] = useState(false);

    const onSubmitClick = useCallback(async () => {
        try {
            setSaving(true);
            if (await doSave()) {
                onSave && onSave();
            }
        }
        finally {
            setSaving(false);
        }
    }, [doSave, onSave]);

    const handleDialogClose = useCallback((event:object, reason: "backdropClick" | "escapeKeyDown") => {
        if (!backdropClose && (reason && reason === "backdropClick")) 
            return;
        onClose && onClose();
    },[onClose, backdropClose]);

    return (
        <Dialog {...props} open={open} onClose={handleDialogClose} PaperProps={dialogPaperProps||{sx:{maxWidth:'800px'}}}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <GridForm>
                    {children || <></>}
                </GridForm>
            </DialogContent>
            <DialogActions>
                { footer ?
                    <>
                    <Tooltip title={footer.tooltip}>
                    <DialogContentText>{footer.text}</DialogContentText>
                    </Tooltip>
                    <div style={{flex: '1 0 0'}} />
                    </>
                    : <></>
                }
                {readOnly
                    ? <Button onClick={onClose}>OK</Button>
                    : <>
                        <Button onClick={onClose}>Cancel</Button>
                        <SpinnerButton onClick={onSubmitClick} showSpinner={saving}>{submitText}</SpinnerButton>
                    </>
                }
            </DialogActions>
        </Dialog >
    )
}

export default EntityEditor;